<template>
    <div id="global-nav" class="bg-dark text-white">
        <ul class="nav">
            <li class="nav-item">
                <router-link class="nav-link text-reset" to="/">YouKan</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link text-reset" to="/solo">もんだいリスト</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import Reward from './Reward.js'
import firebase from 'firebase'

export default {
    name: 'global-nav',
    props: ['quizzes'],
    data: function() {
        return {
            rewardType: 'star',
            user: null,
            displayName: null,
            accessToken: null,
            signInStatus: null,
            signInText: 'Sign in',
            error: null,
            disabled: false,
        };
    },
    created: function() {
        Reward.setSimpleSymbols(this.rewardType);
/* 
        firebase.initializeApp({
            "projectId": "hanpuku-gakushu",
            "databaseURL": "https://hanpuku-gakushu.firebaseio.com",
            "storageBucket": "hanpuku-gakushu.appspot.com",
            "locationId": "asia-east2",
            "apiKey": "AIzaSyBh-_XELYDmEFSncIqXqeeK5XjwI5u0asg",
            "authDomain": "hanpuku-gakushu.firebaseapp.com",
            "messagingSenderId": "352173877989"
        });
 */
        this.initApp();
    },
    methods: {
        switchReward: function() {
            this.rewardType = this.rewardType === 'star' ? 'heart' : 'star';
            Reward.setSimpleSymbols(this.rewardType);
        },
        handleSignedIn: function(user) {
            this.signInStatus = 'Signed in';
            this.signInText = 'Sign out';
            this.user = user;
            this.displayName = user.displayName;
            this.disabled = false;
        },
        initApp: function() {
            // Listening for auth state changes.
            firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    // User is signed in.
/* 
                    var displayName = user.displayName;
                    var email = user.email;
                    var emailVerified = user.emailVerified;
                    var photoURL = user.photoURL;
                    var isAnonymous = user.isAnonymous;
                    var uid = user.uid;
                    var providerData = user.providerData;
 */
                    this.handleSignedIn(user);
                } else {
                    // User is signed out.
                    this.signInStatus = 'Signed out';
                    this.signInText = 'Sign in';
                    this.accessToken = 'null';
                    this.disabled = false;
                }
            });
        },
        signIn: function() {
            if (!firebase.auth().currentUser) {
                var provider = new firebase.auth.GoogleAuthProvider();
                provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
                firebase.auth().signInWithPopup(provider).then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    this.accessToken = result.credential.accessToken;
                    // The signed-in user info.
                    this.handleSignedIn(result.user);
                }).catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
/* 
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
 */
                    if (errorCode === 'auth/account-exists-with-different-credential') {
                        alert('You have already signed up with a different auth provider for that email.');
                        // If you are using multiple auth providers on your app you should handle linking
                        // the user's accounts here.
                    } else {
                        this.error = error;
                    }
                });
            } else {
                firebase.auth().signOut();
            }
            this.disabled = true;
        }
    }
}
</script>

<style scoped>
#menu-icon {
    font-size: 32px;
}
.icon {
  width: 32px;
  height: 32px;
}
.icon-menu {
  fill: #fff;
  width: 100%;
  height: 100%;
}
</style>