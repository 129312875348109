<template>
    <div>
        <h6>{{title}}</h6>
        <div>
            <audio ref="player" controls autoplay>
                <source v-bind:src="src" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </div>
        <MultipleChoiceQuestion v-bind:tick="tick" v-bind:optionValues="options" v-bind:correctIndex="correctIndex" v-on:judge="mark($event)"/>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal>
            <h6>はつおんのしゅるい</h6>
            <div
                v-for="tag in tags" v-bind:key="tag.feature"
                class="form-check form-check-inline"
            >
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="tags" id="targetTags" v-bind:value="tag.feature" v-model="targetTags"> {{tag.feature}}
                </label>
            </div>
        </QuizConfigurationModal>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion.vue";
import TryAgainButton from "./TryAgainButton.vue";
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Achievement from "./Achievement.vue";

const title = 'よまれた えいたんごは どちらかな？';
const name = 'Phoenix';
export default {
    name: name,
    title: title,
    components: {
        MultipleChoiceQuestion,
        TryAgainButton,
        Achievement,
        QuizConfigurationModal
    },
    data: function() {
        return {
            title: title,
            debug: null,
            publicPath: process.env.BASE_URL,
            correct: false,
            total: 0,
            tick: false,
            options: [],
            correctIndex: -1,
            src: '',
            targetTags: ['L,R', 'A,U,O'],
            tags: [
                {feature: 'S,SH,TH', indices: []},
                {feature: 'AR,IR,UR', indices: []},
                {feature: 'A,U,O', indices: []},
                {feature: 'L,R', indices: []},
                {feature: 'B,V', indices: []},
            ],
            seeds: [
                {words: ['sheet', 'seat'], tags: ['S,SH,TH']},
                {words: ['ship', 'sip'], tags: ['S,SH,TH']},
                {words: ['shit', 'sit'], tags: ['S,SH,TH']},
                {words: ['sink', 'think'], tags: ['S,SH,TH']},
                {words: ['sick', 'thick'], tags: ['S,SH,TH']},
                {words: ['sing', 'thing'], tags: ['S,SH,TH']},
                {words: ['sin', 'thin'], tags: ['S,SH,TH']},
                {words: ['seep', 'sheep'], tags: ['S,SH,TH']},
                {words: ['sea', 'she', 'thee'], tags: ['S,SH,TH']},
                {words: ['sealed', 'shield'], tags: ['S,SH,TH']},
                {words: ['face', 'faith'], tags: ['S,SH,TH']},
                {words: ['mass', 'math'], tags: ['S,SH,TH']},
                {words: ['miss', 'myth'], tags: ['S,SH,TH']},
                {words: ['lease', 'wreath'], tags: ['S,SH,TH']},
                {words: ['fifties', 'fiftieth'], tags: ['S,SH,TH']},
                {words: ['seventies', 'seventieth'], tags: ['S,SH,TH']},
                {words: ['tense', 'tenth'], tags: ['S,SH,TH']},
                {words: ['wells', 'wealth'], tags: ['S,SH,TH']},
                {words: ['wish', 'with'], tags: ['S,SH,TH']},
                {words: ['vase', 'base'], tags: ['B,V']},
                {words: ['vest', 'best'], tags: ['B,V']},
                {words: ['vend', 'bend'], tags: ['B,V']},
                {words: ['curb', 'curve'], tags: ['B,V']},
                {words: ['berry', 'very'], tags: ['B,V']},
                {words: ['boat', 'vote'], tags: ['B,V']},
                {words: ['B', 'V'], tags: ['B,V']},
                {words: ['rate', 'late'], tags: ['L,R']},
                {words: ['river', 'liver'], tags: ['L,R']},
                {words: ['list', 'wrist'], tags: ['L,R']},
                {words: ['rot', 'lot'], tags: ['L,R']},
                {words: ['read', 'lead'], tags: ['L,R']},
                {words: ['red', 'led'], tags: ['L,R']},
                {words: ['pray', 'play'], tags: ['L,R']},
                {words: ['crew', 'clue'], tags: ['L,R']},
                {words: ['grew', 'glue'], tags: ['L,R']},
                {words: ['grass', 'glass'], tags: ['L,R']},
                {words: ['razor', 'laser'], tags: ['L,R']},
                {words: ['wrap', 'lap'], tags: ['L,R']},
                {words: ['raw', 'law'], tags: ['L,R']},
                {words: ['roan', 'loan'], tags: ['L,R']},
                {words: ['last', 'lust'], tags: ['A,U,O']},
                {words: ['last', 'lost'], tags: ['A,U,O']},
                {words: ['lust', 'lost'], tags: ['A,U,O']},
                {words: ['cap', 'cup'], tags: ['A,U,O']},
                {words: ['cap', 'cop'], tags: ['A,U,O']},
                {words: ['cup', 'cop'], tags: ['A,U,O']},
                {words: ['cab', 'cub'], tags: ['A,U,O']},
                {words: ['cab', 'cob'], tags: ['A,U,O']},
                {words: ['cub', 'cob'], tags: ['A,U,O']},
                {words: ['cast', 'cost'], tags: ['A,U,O']},
                {words: ['cat', 'cut'], tags: ['A,U,O']},
                {words: ['back', 'buck'], tags: ['A,U,O']},
                {words: ['ham', 'hum'], tags: ['A,U,O']},
                {words: ['hat', 'hut'], tags: ['A,U,O']},
                {words: ['hat', 'hot'], tags: ['A,U,O']},
                {words: ['hut', 'hot'], tags: ['A,U,O']},
                {words: ['jab', 'job'], tags: ['A,U,O']},
                {words: ['slam', 'slum'], tags: ['A,U,O']},
                {words: ['rat', 'rot'], tags: ['A,U,O']},
                {words: ['mad', 'mud'], tags: ['A,U,O']},
                {words: ['map', 'mop'], tags: ['A,U,O']},
                {words: ['mast', 'must'], tags: ['A,U,O']},
                {words: ['nut', 'not'], tags: ['A,U,O']},
                {words: ['pat', 'pot'], tags: ['A,U,O']},
                {words: ['slat', 'slot'], tags: ['A,U,O']},
                {words: ['tap', 'top'], tags: ['A,U,O']},
                {words: ['heart', 'hurt'], tags: ['AR,IR,UR']},
                {words: ['far', 'fur'], tags: ['AR,IR,UR']},
                {words: ['farm', 'firm'], tags: ['AR,IR,UR']},
                {words: ['heard', 'hard'], tags: ['AR,IR,UR']},
                {words: ['parse', 'purse'], tags: ['AR,IR,UR']},
                {words: ['star', 'stir'], tags: ['AR,IR,UR']},
                {words: ['heart', 'hurt'], tags: ['AR,IR,UR']},
            ]
        };
    },
    created: function() {
        this.renew();
    },
    mounted: function () {
        this.$watch('src', () => {
            this.$refs.player.load()
        })
    },
    tick: {
        src: function() {
            this.$refs.player.load();
            this.debug = "src change watched";
        }
    },
    methods: {
        intersect: function(a0, a1) {
            return a0.filter(x => a1.includes(x)).length != 0
        },
        filterByTags: function(seeds, tags) {
            return seeds.filter(s => this.intersect(s.tags, tags));
        },
        renew: function() {
            const seeds = this.filterByTags(this.seeds, this.targetTags);
            const si = Random.randomInteger(seeds.length);
            const seed = seeds[si].words;
            this.options = seed;
            this.correctIndex = Random.randomInteger(seed.length);
            // const voice_dir = 'en-GB-Standard-A';
            // const voice_dir = 'en-US-Wavenet-B';
            const voice_dir = 'en-US-Wavenet-D';
            // const suffix = this.options.join('_');
            const suffix = this.options[this.correctIndex];
            this.src = `${this.publicPath}${voice_dir}/synthesized-audio_${suffix}.mp3`;
            this.tick = ! this.tick;
        },
        mark: function (correct) {
            this.correct = correct;
            this.total += 1;
        }
    }
}
</script>