<template>
    <div>
        <h2 class="py-3">たすと {{target}}になるのは どっちかな？</h2>
        <div class="deck">
            <span
                v-for="(addition, index) in additions"
                v-bind:key="addition.value"
                v-bind:class="['badge', addition.badgeClass, 'mx-2']"
                v-on:click="mark(addition.value, index)"
            >{{addition.n0}} + {{addition.n1}}</span>
        </div>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal>
            <h6>たして いくつの けいさんを あてる？</h6>
            <div
                v-for="n in targetOptions"
                v-bind:key="n"
                class="form-check form-check-inline">
                <label class="form-check-label">
                    <input
                        v-model="target"
                        class="form-check-input" type="radio" name="target" id="target" v-bind:value="n">{{ n }}
                </label>
            </div>
        </QuizConfigurationModal>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import TryAgainButton from "./TryAgainButton.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Achievement from "./Achievement.vue"
import Random from "./Random.js"

const title = "たすと これに なるのは どっちかな？"
const name = 'AdditionSimple'
export default {
    name: name,
    title: title,
    components: {
        TryAgainButton,
        QuizConfigurationModal,
        Achievement
    },
    data: function () {
        return {
            target: 5,
            targetOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            seeds: [],
            correctAdditon: {},
            incorrectAdditon: {},
            additions: [],
            correct: false,
            total: 0
        };
    },
    created: function() {
        this.renew();
    },
    methods: {
        split: function split(number, except) {
            const n0 = Random.randomIntegerExcept(number - 1, [except - 1]) + 1;
            return {n0: n0, n1: number - n0}
        },
        renew: function() {
            let split0 = this.split(this.target, this.correctAdditon.n0);
            this.correctAdditon = {
                value: this.target,
                n0: split0.n0,
                n1: split0.n1,
                badgeClass: 'badge-secondary'
            };
            let delta = Math.random() < 0.5 ? -1 : 1;
            let incorrectSum = this.target + delta;
            let except = incorrectSum === this.incorrectAdditon.value ? this.incorrectAdditon.n0 : 0;
            let split1 = this.split(incorrectSum, except);
            this.incorrectAdditon = {
                value: incorrectSum,
                n0: split1.n0,
                n1: split1.n1,
                badgeClass: 'badge-secondary'
            };
            let additions = [this.correctAdditon, this.incorrectAdditon];
            if (Math.random() < 0.5) {
                additions.reverse()
            }
            this.additions = additions;
        },
        mark: function(value, index) {
            this.additions[index].badgeClass = this.additions[index].value === this.target ? 'badge-success' : 'badge-danger';
            this.correct =  value === this.target;
            this.total += 1;
        }
    }
}
</script>

<style scoped>
.deck {
    font-size: 48px;
}
img {
    height: 2rem;
    width: 2rem;
}
</style>
