<template>
    <div>
        <h3>{{ title }}</h3>
        <ArithmeticEquation v-bind:n0="n0" v-bind:n1="n1" v-bind:operation="operation" v-bind:input="input" v-bind:expected="expected"/>
        <NumberPad v-bind:clear="clearNumPad" v-on:press="judge"/>
        <TryAgainButton v-bind:renew="renew"/>
    </div>
</template>

<script>
import Arithmetic from "./Arithmetic.vue"
import ArithmeticEquation from "./ArithmeticEquation.vue"
import NumberPad from "./NumberPad.vue"
import TryAgainButton from "./TryAgainButton.vue"
import Random from "./Random.js"

const title = 'この わりざんの けっかは？';
export default {
    title: title,
    name: 'Division',
    mixins: [Arithmetic],
    components: {
        ArithmeticEquation,
        NumberPad,
        TryAgainButton,
    },
    data: function() {
        return {
            title: title,
            operation: '÷',
        };
    },
    computed: {
        expected: function() {
            return this.n0 / this.n1;
        }
    },
    methods: {
        pickNumbers: function() {
            this.n1 = Random.randomInteger(10) + 1;
            this.n0 = this.n1 * (1 +Random.randomInteger(11));
        },
    },
}
</script>
