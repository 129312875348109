export default {
    randomInteger: function(max) {
        return Math.floor((Math.random() * Math.floor(max)));
    },
    randomIntegerExcept: function(max, exception) {
        let i = Math.floor((Math.random() * Math.floor(max)));
        while (exception.includes(i)) {
            i = Math.floor((Math.random() * Math.floor(max)));
        }
        return i;
    },
    randomIntegerWeightedExcept: function(weights, exception) {
        const ints = [];
        const excepts = [];
        for (let wi = 0; wi < weights.length; wi++) {
            for (let xi = 0; xi < weights[wi]; xi++) {
                if (exception.includes(wi)) {
                    excepts.push(ints.length);
                }
                ints.push(wi);
            }
        }
        const ii = this.randomIntegerExcept(ints.length, excepts);
        return ints[ii];
    },
    sample: function(max, n) {
        let samples = [];
        while (samples.length < n) {
            let value = this.randomInteger(max);
            if (! samples.includes(value)) {
                samples.push(value);
            }
        }
        return samples;
    },
    sampleExcept: function(max, n, exception) {
        let samples = [];
        while (samples.length < n) {
            let value = this.randomInteger(max);
            if (! samples.includes(value) && ! exception.includes(value)) {
                samples.push(value);
            }
        }
        return samples;
    },
    shuffle: function(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }
}
