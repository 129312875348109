<template>
    <div>
        {{ round }}周目 {{ position }}問目 / 全{{ total }}問
        <div class="progress">
            <div class="progress-bar" role="progressbar" v-bind:style="{width: percent}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                {{ percent }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props: ['total', 'current'],
    computed: {
        round: function() {
             return Math.ceil(this.current / this.total);
        },
        position: function() {
            return (this.current - 1) % this.total + 1;
        },
        percent: function () {
            const value = Math.floor(this.position / this.total * 100);
            return `${value}%`;
        },

    },
})
</script>
