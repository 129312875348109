<template>
    <div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-light float-right my-3" data-toggle="modal" data-target="#modelId">
                <img
                class=""
                src="./../../node_modules/open-iconic/svg/cog.svg"
                alt="もんだいを せっていする"
            >
        </button>
        
        <!-- Modal -->
        <div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">せってい</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <slot></slot>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">とじる</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuizConfigurationModal'
}
</script>

<style scoped>
img {
    height: 2rem;
    width: 2rem;
}
</style>