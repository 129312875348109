<template>
    <div>
        <h6>{{title}}</h6>
        <div class="container">
            <div class="row">
                <div class="col-12 sentence">
                    <span v-for="(word, wi) in quiz.words" v-bind:key="word" v-bind:class="{border: wi === quiz.questionPartIndex, 'bg-success': wi === quiz.questionPartIndex && correct, 'text-white': wi === quiz.questionPartIndex, 'px-2': wi === quiz.questionPartIndex && word.length < 3, 'm-1': true}">{{word}}</span>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="button"
                            v-for="(option, oi) in quiz.options" v-bind:key="option"
                            v-on:click="mark(oi)"
                            class="btn btn-secondary mx-1"
                        >
                            {{option}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";
import VerbConjugation from "./VerbConjugation.js";

const title = 'しゅご/どうし/ときの くみあわせが ただしくなるのは？';
const name = 'EnglishTense';
export default {
    name: name,
    title: title,
    data: function() {
        return {
            title: title,
            correct: false,
            total: 0,
            quiz: {},
            sentences: [
                "I brush my teeth everyday.",
                "I play tennis everyday.",
                "I go to library everyday.",
                "I drink milk everyday.",
                "I eat it everyday.",
                "I give you a present every month.",
                "My granpa comes to our home everyday.",
                "I read some newspapers everyday.",
                "We shut the gate everyday.",
                "He breaks a promise everyday.",
                "I wait her there everyday",
                "I have big lunch everyday",
                "I skip lunch everyday",
                "I watch TV everyday",
                " I make breakfast everyday.",
                "I speak to the dog everyday.",
                "I see his mother everyday",
                "I study English everyday.",
                "I get up early everday.",
                "I take a shower everyday.",
                "I use buses everyday.",
                "It opens at 7 AM everyday.",
                "I listen to music everyday.",
                "I sit there everyday.",
                "I clean this room everyday.",
                "It rains everyday.",
            ],
        };
    },
    components: {
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            this.correct = false;
            Random.randomInteger(1);
            this.quiz = VerbConjugation.generate();
        },
        mark: function(optionIndex) {
            this.correct = this.quiz.options[optionIndex] == this.quiz.words[this.quiz.questionPartIndex];
            this.total += 1;
        }
    }
}
</script>

<style scoped>
.sentence {
    font-size: 2em;
}
</style>