<template>
    <div>
        <h4>この かんじの よみかたは？</h4>
        <ul>
        </ul>
        
        
        <table class="table-borderless float-left mx-2 my-2">
            <tr>
                <td v-show="word.head !== ''">
                    <table style="visibility: hidden">
                        <tr>
                            <td>{{ word. head }}</td>
                        </tr>
                    </table>
                    {{ word. head }}
                </td>
                <td v-for="(character, ci) in word.body" v-bind:key="'ch' + ci">
                    <table class="table-bordered">
                        <tr>
                            <td
                                v-for="(kana, ki) in character.reading"
                                v-bind:key="'kana' + ci + ki"
                                v-bind:class="kana.class"
                            >
                                <span class="yomi" v-bind:style="{visibility: kana.visibility}">
                                    {{ kana.symbol }}
                                </span>
                            </td>
                        </tr>
                    </table>
                    <span class="kanji">{{ character.kanji }}</span>
                </td>
                <td v-show="word.tail !== ''">
                    <table style="visibility: hidden">
                        <tr>
                            <td>{{ word. tail }}</td>
                        </tr>
                    </table>
                    {{ word. tail }}
                </td>
            </tr>
        </table>
        <table class="table-borderless">
            <tr v-for="(keyBoardRow, ri) in keyBoardCharacters" v-bind:key="'kbr' + ri">
                <td
                    v-for="(character, ci) in keyBoardRow"
                    v-bind:key="'kbc' + ci"
                    class="py-0"
                >
                    <span
                        v-bind:class="character.class"
                        v-on:click="judge(character.symbol, ri, ci)"
                    >
                        {{ character.symbol }}
                    </span>
                </td>
            </tr>
        </table>
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import TryAgainButton from "./TryAgainButton.vue"
import Achievement from "./Achievement.vue"
import Random from "./Random.js"
import Kanji from "./Kanji.js"

export default {
    name: 'KanjiReading',
    title: 'この かんじの よみかたは？',
    components: {
        TryAgainButton,
        Achievement
    },
    data: function () {
        return {
            word: {},
            targetWordIndex: -1,
            targetCharacterIndex: 0,
            targetKanaIndex: 0,
            keyBoardCharacters: [
                [
                    {symbol: 'あ', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'せ', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'ざ', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'き', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                ],
                [
                    {symbol: 'く', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'け', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'こ', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'か', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                ],
                [
                    {symbol: 'ず', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'お', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'じ', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                    {symbol: 'ぞ', class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']},
                ],
            ],
            words_raw: [
                'なかに/入,は:い/る',
                '/一,い:ち/ばん',
                '/一,い:ち/ねんせい',
                '/一,い:ち|月,が:つ/',
                '/右,み:ぎ/がわ',
                '/左,さ|右,ゆ:う/',
                '/大,お:お|雨,あ:め/',
                '/雨,あ:ま/やどり',
                '/円,え:ん/けい',
                '/王,お:う/さま',
                '/王,お:う/こく',
                '/王,お:う|子,じ/',
                '/王,お:う|女,じ:ょ/',
                '/女,じ:ょ|王,お:う/',
                '/音,お:ん/がく',
                '/大,お:お/きな',
                '/音,お:と/が きこえる',
                'つくえの/下,し:た/',
                'くつ/下,し:た/',
                '/上,じ:ょ:う|下,げ/',
                '/下,さ/げる',
                '/下,さ/がる',
                '/下,げ|校,こ:う/',
                '/川,か:わ|下,し:も/',
                '/火,ひ/を けす',
                '/火,か/じ',
                '/火,か/ようび',
                '/花,は:な|火,び/',
                '/草,く:さ|花,ば:な/',
                '/花,か/だん',
                '/貝,か:い/がら',
                '/生,う/まれる',
                '/学,が:く|生,せ:い/',
                '/小,し:ょ:う|学,が:く/',
                '/大,だ:い|学,が:く/',
                'げん/気,き/',
                '/人,に:ん|気,き/',
                '/気,き/もち',
                '/気,き/あい',
                '/九,こ:こ:の/つ',
                '/休,や:す/める',
                '/休,や:す/み',
                '/休,き:ゅ:う/けい',
                'みず/玉,た:ま/もよう',
                'しゃぼん/玉,だ:ま/',
                '/金,き:ん/ようび',
                '/金,き:ん/せい',
                'あおい/空,そ:ら/',
                '/空,く:う|気,き/',
                '/月,げ:つ/ようび',
                '/今,こ:ん|月,げ:つ/',
                '/先,せ:ん|月,げ:つ/',
                '/犬,い:ぬ/が ほえる',
                '/名,め:い|犬,け:ん/',
                '/見,け:ん|学,が:く/',
                '/見,み/る',
                '/見,み/せる',
                '/見,み/える',
                '/五,ご/まい',
                '/五,ご/にん',
                '/五,い:つ|日,か/',
                '/入,い:り|口,ぐ:ち/',
                '/出,で|口,ぐ:ち/',
                '/人,じ:ん|口,こ:う/',
                '/火,か|口,こ:う/',
                '/学,が:っ|校,こ:う/',
                '/校,こ:う/てい',
                '/左,さ|右,ゆ:う/',
                '/三,さ:ん/かっけい',
                '/三,み:っ/つ',
                '/山,さ:ん/みゃく',
                '/山,や:ま/のぼり',
                '/子,こ/ども',
                'おや/子,こ/',
                '/男,だ:ん|子,し/',
                '/女,じ:ょ|子,し/',
                '/四,し/かっけい',
                '/四,よ/つ',
                '/毛,け|糸,い:と/',
                '/文,も|字,じ/',
                'かん/字,じ/',
                '/耳,み:み/を すます',
                '/七,し:ち|五,ご|三,さ:ん/',
                '/七,し:ち/ならべ',
                '/車,く:る:ま/に のる',
                'じどう/車,し:ゃ/',
                'じてん/車,し:ゃ/',
                '/先,せ:ん|手,て/',
                '/手,て|足,あ:し/',
                'あく/手,し:ゅ/',
                '/十,と:お|日,か/',
                '/出,で|口,ぐ:ち/',
                '/出,で/る',
                '/出,し:ゅ:っ/ぱつ',
                '/女,じ:ょ|子,し/トイレ',
                '/女,お:ん:な/のこ',
                '/男,だ:ん|女,じ:ょ/',
                '/小,こ|石,い:し/',
                '/小,こ/とり',
                '/小,し:ょ:う|学,が:く/',
                '/小,し:ょ:う|学,が:っ|校,こ:う/',
                '/大,だ:い|小,し:ょ:う/',
                '/小,ち:い/さい',
                '/小,お|川,が:わ/',
                '/上,じ:ょ:う|下,げ/',
                '/上,あ/げる',
                '/上,あ/がる',
                '/上,う:わ/ばき',
                '/森,し:ん|林,り:ん/',
                '/日,に|本,ほ:ん|人,じ:ん/',
                '/人,じ:ん|口,こ:う/',
                'アメリカ/人,じ:ん/',
                '/水,み:ず/を のむ',
                '/水,す:い/ようび',
                '/水,す:い/えい',
                '/正,し:ょ:う|月,が:つ/',
                '/正,た:だ/す',
                '/生,い/きもの',
                'せん/生,せ:い/',
                '/学,が:く|生,せ:い/',
                '/生,い/きる',
                '/生,せ:い/かつ',
                '/生,い/かす',
                '/生,い/ける',
                '/青,あ:お/ぞら',
                '/青,あ:お/い',
                '/青,あ:お/しんごう',
                '/夕,ゆ:う/がた',
                '/夕,ゆ:う/はん',
                '/夕,ゆ:う/やけ',
                '/夕,ゆ:う|日,ひ/',
                '/石,い:し/ころ',
                'じ/石,し:ゃ:く/',
                'いん/石,せ:き/',
                '/赤,あ:か/えんぴつ',
                '/赤,あ:か/ぐみ',
                '/赤,あ:か/しんごう',
                '/赤,あ:か/ちゃん',
                '/千,せ:ん|円,え:ん/',
                '/土,ど/ようび',
                '/日,に:ち/ようび',
                '/木,も:く/ようび',
                '/川,か:わ|上,か:み/',
                '/川,か:わ|下,し:も/',
                '/先,せ:ん|生,せ:い/',
                '/先,せ:ん|月,げ:つ/',
                '/村,む:ら|人,び:と/',
                '/大,だ:い|学,が:く/',
                '/入,い:り|口,ぐ:ち/',
                '/日,に:っ|本,ぽ:ん/',
                '/名,め:い|人,じ:ん/',
                '/名,め:い|犬,け:ん/',
                '/名,め:い|作,さ:く/',
                '/森,し:ん|林,り:ん/',
                '/先,さ:き/っぽ',
                '/早,は:や/おき',
                '/大,だ:い/すき',
                '/竹,た:け/やぶ',
                '/竹,た:け/うま',
                '/町,ま:ち/かど',
                '/村,そ:ん|長,ち:ょ:う/',
                '/大,だ:い|小,し:ょ:う/',
                '/長,ち:ょ:う|男,な:ん/',
                '/中,ち:ゅ:う|学,が:く/',
                '/入,に:ゅ:う|学,が:く/',
                '/百,ひ:ゃ:く|円,え:ん/',
                '/町,ち:ょ:う|立,り:つ/',
                '/文,も|字,じ/',
                '/土,と|地,ち/',
                '/名,な/まえ',
                '/名,な/ふだ',
                '/田,た/んぼ',
                '/手,て|足,あ:し/',
                '/木,こ|立,だ:ち/',
                '/目,め|玉,だ:ま/',
                '/市,し|立,り:つ/',
                '/男,だ:ん|子,し/',
                '/天,て:ん|気,き/',
                '/目,も:く|次,じ/',
                '/大,お:と|人,な/',
                '/力,ち:か:ら/もち',
                '/中,ち:ゅ:う/ごく',
                '/百,ひ:ゃ:く/まい',
                '/男,お:と:こ/のこ',
                'まい/年,と:し/',
                'らい/年,ね:ん/',
                'きょ/年,ね:ん/',
                '/土,つ:ち/を ほる',
                '/本,ほ:ん/を よむ',
                '/目,め/を とじる',
                '/日,ひ/が のぼる',
                '/足,た/す',
                '/立,た/つ',
                'かぶと/虫,む:し/',
                'てんとう/虫,む:し/',
                'なかに/入,は:い/る',
                'こ/年,と:し/',
                'こん/虫,ち:ゅ:う/',
                'お/年,と:し/より',
                'おおきな/町,ま:ち/',
                '/草,く:さ/むら',
                '/町,ち:ょ:う/ないかい',
                '/天,て:ん|文,も:ん/だい',
                '/早,は:や/ね',
                '/目,も:く/ひょう',
                '/日,ひ/なた',
                '/立,た/てる',
                '/六,ろ:く/かっけい',
                '/市,し|町,ち:ょ:う|村,そ:ん/',
                '/二,に|年,ね:ん|生,せ:い/',
                '/一,い:ち|年,ね:ん|生,せ:い/',
                '/白,し:ろ/い',
                '/名,み:ょ:う|字,じ/'
            ],
            missed: false,
            correct: false,
            total: 0
        };
    },
    created: function() {
        this.renew();
    },
    methods: {
        changeKeyboardKeyStatus: function(ri, ci, from, to) {
            let currentClass = this.keyBoardCharacters[ri][ci].class;
            let newClass = currentClass.filter(c => c !== from);
            newClass.push(to);
            this.keyBoardCharacters[ri][ci].class = newClass;
        },
        forwardCursor: function() {
            this.word.body[this.targetCharacterIndex].reading[this.targetKanaIndex].visibility = 'visible';
            this.word.body[this.targetCharacterIndex].reading[this.targetKanaIndex].class = [];
            this.targetKanaIndex += 1;

            if (this.targetKanaIndex === this.word.body[this.targetCharacterIndex].reading.length) {
                this.targetCharacterIndex += 1;
                this.targetKanaIndex = 0;
            }
        },
        displayCorrect: function(bgColor) {
            for (const character of this.word.body) {
                for (const kana of character.reading) {
                    kana.class = [bgColor, 'text-white'];
                }
            }
        },
        judge: function(character, ri, ci) {
        if (character === this.word.body[this.targetCharacterIndex].reading[this.targetKanaIndex].symbol) {
                this.forwardCursor();
                if (this.targetCharacterIndex === this.word.body.length) {
                    if (this.missed) {
                        this.displayCorrect('bg-warning');
                    } else {
                        this.correct = true;
                        this.total += 1;
                        this.displayCorrect('bg-success');
                    }
                } else {
                    this.word.body[this.targetCharacterIndex].reading[this.targetKanaIndex].class = ['border', 'border-dark'];
                }
            } else {
                this.missed = true;
                this.changeKeyboardKeyStatus(ri, ci, 'badge-secondary', 'badge-danger');
                setTimeout(() => this.changeKeyboardKeyStatus(ri, ci, 'badge-danger', 'badge-secondary'), 500);
            }
        },
        renewWord: function() {
            this.targetWordIndex = Random.randomIntegerExcept(this.words_raw.length, [this.targetWordIndex]);
            let nextWord = Kanji.parseWord(this.words_raw[this.targetWordIndex]);
            for (const ch of nextWord.body) {
                for (const kana of ch.reading) {
                    kana.visibility = 'hidden';
                    kana.class = [];
                }
            }
            nextWord.body[0].reading[0].class = ['border', 'border-dark'];
            this.word = nextWord;            
        },
        renewKeyboard: function() {
            const allKana = "あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんがぎぐげござじずぜぞだぢづでどばびぶべぼゃゅょ";
            let wordKana = this.word.body.reduce(
                (kana, ch) => kana.concat(ch.reading.map(k => k.symbol)),
                []
            );
            let samples = Random.sampleExcept(allKana.length, 12 - wordKana.length, wordKana.map(k => allKana.indexOf(k)));
            let seeds = samples.map(s => allKana[s]);
            let keys = Random.shuffle(wordKana.concat(seeds)).map(symbol => {
                return {
                    symbol: symbol,
                    class: ['kbd-key', 'px-2', 'mx-1', 'badge', 'badge-pill', 'badge-secondary']
                }
            });
            this.keyBoardCharacters = [0, 1, 2].map(ri => keys.slice(ri * 4, (ri + 1) * 4));
        },
        renew: function() {
            this.renewWord();
            this.renewKeyboard();
            this.targetCharacterIndex = 0;
            this.targetKanaIndex = 0;
            this.missed = false;
        }
    }
}
</script>

<style scoped>
.kanji {
    font-size: 54px;
}
.yomi {
    font-size: 30px;
    margin: 0px;
}
.kbd-key {
    font-size: 30px;
}
</style>
