<template>
    <div>
        <h2>{{ title }}</h2>
        <table>
            <tr>
                <td
                    v-for="(number, index) in numbers"
                    v-bind:key="index"
                    v-bind:class="number.class"
                    v-bind:style="number.style"
                    v-on:click="toggleVisibility(index)"
                >
                    <span v-show="number.visibility">{{ number.value }}</span>
                </td>
            </tr>
        </table>
        <TryAgainButton v-bind:renew="renew"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue"

const title = "つぎの かずは なにかな？"
export default {
    name: 'WhatsNextNumber',
    title: title,
    components: {
        TryAgainButton
    },
    data: function() {
        return {
            title: title,
            numbers: []
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        toggleVisibility: function (index) {
            let number = {
                value: this.numbers[index].value,
                class: this.numbers[index].class,
                visibility: true
            }
            this.numbers.splice(index, 1, number);
        },
        renew: function() {
            // this.target = Random.randomInteger(10) * 10 + 9; // 9, 19, 29, ..., 99
            this.target = Random.randomIntegerExcept(100, [this.target]);
            this.numbers = [this.target, this.target + 1].map(n => {
                return {
                    value: n,
                    class: n % 2 === 0 ? 'even' : 'odd',
                    visibility: n === this.target
                }
            })
        }
    }
}
</script>

<style>
td {
    font-size: 36px;
    min-height: 40px;
    min-width: 40px;
    text-align: center;
    border: solid black;
}
.odd {
    background-color: yellow;
}
.even {
    background-color: gray;
}
</style>