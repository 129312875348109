<template>
    <div class="question">
        <span>{{ n0 }} <span v-html="operation"></span> {{ n1 }} = </span>
        <span v-bind:class="rhsClass">{{input}}</span>
    </div>
</template>

<script>
export default {
    props: ['n0', 'n1', 'operation', 'input', 'expected'],
    data: function() {
        return {
            rhsClass: null,
        };
    },
    watch: {
        input: function() {
            this.judge();
        },
        n0: function() {
            this.reset();
        },
        n1: function() {
            this.reset();
        },
    },
    created: function() {
        this.reset();
    },
    methods: {
        reset: function() {
            this.rhsClass = null; //['border', 'border-dark', 'rounded-lg', 'px-1'];
        },
        judge: function() {
            if (this.input === this.expected.toString()) {
                this.rhsClass = ['bg-success', 'text-white', 'rounded-lg', 'px-1'];
            }
        },
    }
}
</script>

<style scoped>
.question {
    font-size: 3em;
}
</style>
