<template>
  <div id="app">
    <div v-if="$route.path === '/solo' || $route.path === '/solo/'" >
      <GlobalNav v-bind:quizzes="quizzes" v-on:update:current-quiz="currentQuiz = $event" v-on:show:quizzes="showQuizzes = true"/>
      <Quizzes v-bind:quizzes="quizzes" v-on:update:current-quiz="currentQuiz = $event; showQuizzes = false;" />
    </div>
    <div v-else>
      <router-link to="/solo" class="text-reset sticky-top float-right">
        <svg width="1.5rem" height="1.5rem" viewBox="0 0 16 16" class="bi bi-card-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
          <path fill-rule="evenodd" d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
          <circle cx="3.5" cy="5.5" r=".5"/>
          <circle cx="3.5" cy="8" r=".5"/>
          <circle cx="3.5" cy="10.5" r=".5"/>
        </svg>
      </router-link>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import camelToKebab from './components/camelToKebab.js'

import Addition from './components/Addition.vue'
import AdditionSimple from './components/AdditionSimple.vue'
import AuxiliaryVerb from './components/AuxiliaryVerb.vue'
import CharactersInBlankShuffled from './components/CharactersInBlankShuffled.vue'
import CountryLocation from './components/CountryLocation.vue'
import CountAllNumbersInBetween from './components/CountAllNumbersInBetween.vue'
import CountRest from './components/CountRest.vue'
import Division from './components/Division.vue'
import EnglishNoun from './components/EnglishNoun.vue'
import EnglishSequence from './components/EnglishSequence.vue'
import EnglishTense from './components/EnglishTense.vue'
import GlobalNav from "./components/GlobalNav.vue"
import HistoricalSequence from './components/HistoricalSequence.vue'
import InterrogativeSentence from './components/InterrogativeSentence.vue'
import InterrogativeWord from './components/InterrogativeWord.vue'
import KanjiReading from './components/KanjiReading.vue'
import KatakanaWordInEnglish from './components/KatakanaWordInEnglish.vue'
import Multiplication from './components/Multiplication.vue'
import NationalFlag from './components/NationalFlag.vue'
import Phoenix from './components/Phoenix.vue'
import PrefectureInJapanLocation from './components/PrefectureInJapanLocation.vue'
import ProperNounWithHepburn from './components/ProperNounWithHepburn.vue'
import Quizzes from "./components/Quizzes.vue"
import Rewards from "./components/Rewards.vue"
import ScientificSequence from "./components/ScientificSequence.vue"
import Sequence from './components/Sequence.vue'
import Subtraction from './components/Subtraction.vue'
import TransitiveVerb from './components/TransitiveVerb.vue'
import UploadMaterial from './components/UploadMaterial.vue'
import WhatsNextNumber from './components/WhatsNextNumber.vue'

import OneToOne from './components/OneToOne'

let quizzes = [
  {id: 'math', category: '🎲 かず', quizzes: [
    WhatsNextNumber,
    CountAllNumbersInBetween,
    CountRest,
    AdditionSimple,
    Addition,
    Subtraction,
    Multiplication,
    Division,
  ]},
  {id: 'charactor', category: '🖋 もじ', quizzes: [
  ]},
  {id: 'english', category: '🇺🇸 えいご', quizzes: [
    KatakanaWordInEnglish,
    Phoenix,
    EnglishNoun,
    EnglishSequence,
    EnglishTense,
    InterrogativeSentence,
    AuxiliaryVerb,
    InterrogativeWord,
    TransitiveVerb,
    CharactersInBlankShuffled,
  ]},
  {id: 'japanese', category: '🇯🇵 こくご', quizzes: [
  ]},
  {id: 'world', category: '🌏 せかい', quizzes: [
    PrefectureInJapanLocation,
    CountryLocation,
    NationalFlag,
    HistoricalSequence,
  ]},
  {id: 'science', category: '⚙ ものの しくみ', quizzes: [
    ScientificSequence,
  ]},
];
const components = {
    Addition,
    AdditionSimple,
    AuxiliaryVerb,
    CharactersInBlankShuffled,
    CountryLocation,
    CountAllNumbersInBetween,
    CountRest,
    Division,
    EnglishNoun,
    EnglishSequence,
    EnglishTense,
    GlobalNav,
    HistoricalSequence,
    InterrogativeSentence,
    InterrogativeWord,
    KanjiReading,
    KatakanaWordInEnglish,
    Multiplication,
    NationalFlag,
    Phoenix,
    PrefectureInJapanLocation,
    ProperNounWithHepburn,
    Quizzes,
    Rewards,
    ScientificSequence,
    Sequence,
    Subtraction,
    TransitiveVerb,
    UploadMaterial,
    WhatsNextNumber,
};

for (const quizName in OneToOne) {
  let x = quizzes.find(category => category.id === OneToOne[quizName].category);
  x.quizzes.push(OneToOne[quizName]);
}

const soloChildren = [];
for (const category of quizzes) {
  for (const quiz of category.quizzes) {
    const child = { path: camelToKebab(quiz.name), component: quiz };
    soloChildren.push(child);
  }
}
export {soloChildren};

export default {
  name: 'QuizList',
  components: {...components, ...OneToOne},
  // components: {...components},
  data: function() {
    return {
      quizzes: quizzes,
      visibilities: {},
      showQuizzes: true,
      showRewards: false,
      currentQuiz: 'Division'
    }
  },
  created: function() {
    this.components
  },
  methods: {
    setCurrentQuiz: function(quizName) {
      this.currentQuizName = quizName;
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.map {
  height: 20rem;
}
</style>
