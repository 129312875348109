<template>
    <div>
        <div>
            <span
                v-for="si in filledGauge"
                v-bind:key="'gauge-fill-' + si"
                class="gauge-filled animated flipInY"
                style="display: inline-block"
            >{{ reward.simple().gaugeSymbol.fill }}</span>
            <span
                v-for="si in emptyGauge"
                v-bind:key="'gauge-empty-' + si"
            >{{ reward.simple().gaugeSymbol.empty }}</span>
        </div>
        <div>
            <span
                v-for="badge in badges"
                v-bind:key="badge.color"
                v-bind:style="{color: badge.color}"
            >
                <span
                    v-for="bi in badge.count" v-bind:key="badge.color + bi"
                    style="display: inline-block"
                    class="badge px-0 animated flipInY slow">{{ reward.simple().gaugeSymbol.colorBase }}</span>
            </span>
        </div>
        <div>れんぞく せいかい: {{ consecutive }} / るいけい せいかい: {{ correct }} / かいすう: {{ total }}</div>
    </div>
</template>

<script>
import Reward from "./Reward.js"

export default {
    props: ['rightness', 'total'],
    watch: {
        total: function() {
            this.update(this.rightness);
        }
    },
    data: function() {
        return {
            correct: 0,
            consecutive: 0,
            criteria: [],
            guage: 3,
            filledGauge: 0,
            emptyGauge: 3,
            badgeIndex: {
                2: 0,
                3: 1,
                5: 2,
                8: 3,
                13: 4,
                21: 5,
                34: 6,
                55: 6,
                89: 7,
                144: 8
            },
            badges: {
                // Fibonacci number
                3: {count: 0, color: 'gray'},
                5: {count: 0, color: 'yellow'},
                8: {count: 0, color: 'orange'},
                13: {count: 0, color: 'green'},
                21: {count: 0, color: 'blue'},
                34: {count: 0, color: 'purple'},
                55: {count: 0, color: 'brown'},
                89: {count: 0, color: 'black'},
                144: {count: 0, color: 'pink'},
                233: {count: 0, color: 'red'}
            },
            reward: Reward
        }
    },
    methods: {
        update: function(correct) {
            if (correct) {
                this.correct += 1;
                this.consecutive += 1;
                this.filledGauge += 1;
                this.emptyGauge -= 1;
            } else {
                this.consecutive = 0;
                this.resetGuage(this.consecutive);
            }
            if (this.consecutive in this.badges) {
                this.badges[this.consecutive].count += 1;
                this.resetGuage(this.consecutive);
            }
        },
        resetGuage: function(c) {
            this.guage = {
                0: 3,
                2: 1,
                3: 2,
                5: 3,
                8: 5,
                13: 8,
                21: 13,
                34: 21,
                55: 34,
                89: 55,
                144: 89
            }[c];
            this.filledGauge = 0;
            this.emptyGauge = this.guage;
        }
    }
}
</script>

<style scoped>
.gauge-filled {
    animation-iteration-count: 3;
}
.badge {
    font-size: 36px;
    text-shadow: 2px 2px 4px;
    animation-iteration-count: 3;
}
</style>
