<template>
    <span v-if="number !== 0">{{number}}{{unit}}</span>
</template>

<script>
import Random from "./Random.js"

export default {
    props: ['min', 'max', 'unit', 'renewal', 'default'],
    data: function () {
        return {
            number: 0
        }
    },
    computed: {
        range: function () {
            return this.max - this.min;
        }
    },
    watch: {
        renewal: function () {
            this.renew();
        },
        min: function () {
            this.renew();
        },
        max: function () {
            this.renew();
        }
    },
    created: function () {
        this.renew();
    },
    methods: {
        renew: function () {
            this.number = Random.randomInteger(this.range) + Number(this.min);
            this.$emit('reflect', this.number);
        }
    }
}
</script>
