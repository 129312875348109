<template>
    <ImageToText v-bind:title="title" v-bind:material="material" />    
</template>

<script>
import ImageToText from './ImageToText.vue'

const title = "この ばしょに ある くには？";
export default {
    name: 'CountryLocation',
    title: title,
    components: {
        ImageToText
    },
    data: function() {
        return {
            title: title,
            material: [
                {name: "Venezuela", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Venezuela_on_the_globe_%28Venezuela_centered%29.svg"},
                {name: "Uruguay", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Uruguay_on_the_globe_%28Chile_centered%29.svg"},
                {name: "Trinidad and Tobago", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/78/Trinidad_and_Tobago_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Suriname", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/54/Suriname_on_the_globe_%28South_America_centered%29.svg"},
                {name: "Peru", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/31/Peru_on_the_globe_%28Peru_centered%29.svg"},
                {name: "French Guiana", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c6/French_Guiana_on_the_globe_%28South_America_centered%29.svg"},
                {name: "Argentina", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/10/Argentina_on_the_globe_%28Argentina_centered%29.svg"},
                {name: "Bolivia", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/be/Bolivia_on_the_globe_%28Bolivia_centered%29.svg"},
                {name: "Brazil", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Brazil_on_the_globe_%28Brazil_centered%29.svg"},
                {name: "Chile", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/35/Chile_on_the_globe_%28Chile_centered%29.svg"},
                {name: "Colombia", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Colombia_on_the_globe_%28Colombia_centered%29.svg"},
                {name: "Ecuador", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1d/Ecuador_on_the_globe_%28Ecuador_centered%29.svg"},
                {name: "Guyana", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/55/Guyana_on_the_globe_%28South_America_centered%29.svg"},
                {name: "Panama", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Panama_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Paraguay", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Paraguay_on_the_globe_%28Paraguay_centered%29.svg"},

                {name: "Abkhazia", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Abkhazia_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Afghanistan", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Afghanistan_on_the_globe_%28Afghanistan_centered%29.svg"},
                {name: "Armenia", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/84/Armenia_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Azerbaijan", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Azerbaijan_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Bahrain", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/95/Bahrain_on_the_globe_%28Bahrain_centered%29.svg"},
                {name: "Bangladesh", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/86/Bangladesh_on_the_globe_%28Bangladesh_centered%29.svg"},
                {name: "Bhutan", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/84/Bhutan_on_the_globe_%28Bhutan_centered%29.svg"},
                {name: "Brunei", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Brunei_on_the_globe_%28Brunei_centered%29.svg"},
                {name: "Cambodia", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Cambodia_on_the_globe_%28Cambodia_centered%29.svg"},
                {name: "China", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/ea/China_on_the_globe_%28China_centered%29.svg"},
                {name: "Taiwan", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/14/Taiwan_on_the_globe_%28Asia_centered%29.svg"},
                {name: "Cyprus", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Cyprus_on_the_globe_%28de-facto%29_%28Europe_centered%29.svg"},
                {name: "Northern Cyprus", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Northern_Cyprus_on_the_globe_%28Europe_centered%29.svg"},
                {name: "East Timor", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/cc/East_Timor_on_the_globe_%28Southeast_Asia_centered%29.svg"},
                {name: "Egypt", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Egypt_on_the_globe_%28de-facto%29_%28North_Africa_centered%29.svg"},
                {name: "Georgia", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/45/Georgia_on_the_globe_%28claimed%29_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "India", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/72/India_on_the_globe_%28India_centered%29.svg"},
                {name: "Indonesia", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Indonesia_on_the_globe_%28Indonesia_centered%29.svg"},
                {name: "Iran", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1e/Iran_on_the_globe_%28Iran_centered%29.svg"},
                {name: "Iraq", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/40/Iraq_on_the_globe_%28Iraq_centered%29.svg"},
                {name: "Israel", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b0/Israel_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Japan", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Japan_on_the_globe_%28Japan_centered%29.svg"},
                {name: "Jordan", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Jordan_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Kazakhstan", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/37/Kazakhstan_on_the_globe_%28Kazakhstan_centered%29.svg"},
                {name: "Kuwait", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Kuwait_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Kyrgyzstan", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Kyrgyzstan_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Laos", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Laos_on_the_globe_%28Asia_centered%29.svg"},
                {name: "Lebanon", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Lebanon_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Malaysia", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Malaysia_on_the_globe_%28Southeast_Asia_centered%29.svg"},
                {name: "Maldives", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Maldives_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Mongolia", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/97/Mongolia_on_the_globe_%28Mongolia_centered%29.svg"},
                {name: "Myanmar", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Myanmar_on_the_globe_%28Myanmar_centered%29.svg"},
                {name: "Nepal", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/44/Nepal_on_the_globe_%28Asia_centered%29.svg"},
                {name: "North Korea", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/58/North_Korea_on_the_globe_%28North_Korea_centered%29.svg"},
                {name: "Oman", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/05/Oman_on_the_globe_%28Oman_centered%29.svg"},
                {name: "Pakistan", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Pakistan_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Palestine", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/35/Palestine_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Papua New Guinea", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/95/Papua_New_Guinea_on_the_globe_%28Southeast_Asia_centered%29.svg"},
                {name: "Philippines", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/56/Philippines_on_the_globe_%28Philippines_centered%29.svg"},
                {name: "Qatar", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Qatar_on_the_globe_%28Afro-Eurasia_centered%29.svg"},
                {name: "Russia", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Russia_on_the_globe_%28Russia_centered%29.svg"},
                {name: "Saudi Arabia", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/57/Saudi_Arabia_on_the_globe_%28Saudi_Arabia_centered%29.svg"},
                {name: "Singapore", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/35/Singapore_on_the_globe_%28Southeast_Asia_centered%29.svg"},
                {name: "South Korea", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/89/South_Korea_on_the_globe_%28South_Korea_centered%29.svg"},
                {name: "South Ossetia", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/79/South_Ossetia_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Sri Lanka", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/17/Sri_Lanka_on_the_globe_%28Asia_centered%29.svg"},
                {name: "Syria", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/76/Syria_on_the_globe_%28Syria_centered%29.svg"},
                {name: "Tajikistan", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/03/Tajikistan_on_the_globe_%28Eurasia_centered%29.svg"},
                {name: "Thailand", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/55/Thailand_on_the_globe_%28Asia_centered%29.svg"},
                {name: "Turkey", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Turkey_on_the_globe_%28Turkey_centered%29.svg"},
                {name: "Turkmenistan", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Turkmenistan_on_the_globe_%28Turkmenistan_centered%29.svg"},
                {name: "United Arab Emirates", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1e/United_Arab_Emirates_on_the_globe_%28United_Arab_Emirates_centered%29.svg"},
                {name: "Uzbekistan", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Uzbekistan_on_the_globe_%28Uzbekistan_centered%29.svg"},
                {name: "Vietnam", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Vietnam_on_the_globe_%28Vietnam_centered%29.svg"},
                {name: "Yemen", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Yemen_on_the_globe_%28Yemen_centered%29.svg"},

                {name: "Antigua and Barbuda", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Antigua_and_Barbuda_on_the_globe_%28Americas_centered%29.svg"},
                {name: "The Bahamas", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/de/The_Bahamas_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Barbados", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Barbados_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Belize", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Belize_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Canada", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/45/Canada_on_the_globe_%28Canada_centered%29.svg"},
                {name: "Costa Rica", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/30/Costa_Rica_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Cuba", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/25/Cuba_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Dominica", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/78/Dominica_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Dominican Republic", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Dominican_Republic_on_the_globe_%28Americas_centered%29.svg"},
                {name: "El Salvador", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a8/El_Salvador_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Grenada", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Grenada_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Guatemala", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Guatemala_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Haiti", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Haiti_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Honduras", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Honduras_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Jamaica", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Jamaica_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Mexico", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/98/Mexico_on_the_globe_%28Mexico_centered%29.svg"},
                {name: "Nicaragua", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Nicaragua_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Panama", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Panama_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Saint Kitts and Nevis", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Saint_Kitts_and_Nevis_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Saint Lucia", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/97/Saint_Lucia_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Saint Vincent and the Grenadines", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Saint_Vincent_and_the_Grenadines_on_the_globe_%28Americas_centered%29.svg"},
                {name: "Trinidad and Tobago", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/78/Trinidad_and_Tobago_on_the_globe_%28Americas_centered%29.svg"},
                {name: "United States", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/df/United_States_on_the_globe_%28United_States_centered%29.svg"},

                {name: "Abkhazia", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Abkhazia_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Albania", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/51/Albania_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Andorra", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/83/Andorra_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Armenia", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/84/Armenia_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Azerbaijan", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Azerbaijan_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Belarus", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/68/Belarus_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Bosnia and Herzegovina", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Bosnia_and_Herzegovina_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Guernsey", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/05/Guernsey_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Iceland", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/81/Iceland_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Jersey", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Jersey_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Kosovo", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Kosovo_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Liechtenstein", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/87/Liechtenstein_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Isle of Man", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Isle_of_Man_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Moldova", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Moldova_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Monaco", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Monaco_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Montenegro", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Montenegro_on_the_globe_%28Europe_centered%29.svg"},
                {name: "North Macedonia", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/28/North_Macedonia_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Norway", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/19/Norway_on_the_globe_%28Europe_centered%29.svg"},
                {name: "San Marino", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/49/San_Marino_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Serbia", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/cd/Serbia_on_the_globe_%28undisputed%29_%28Europe_centered%29.svg"},
                {name: "South Ossetia", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/79/South_Ossetia_on_the_globe_%28Afro-Eurasia_centered%29_%28zoomed%29.svg"},
                {name: "Switzerland", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Switzerland_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Ukraine", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/18/Ukraine_%28-claims%29_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Vatican", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Vatican_on_the_globe_%28Europe_centered%29.svg"},

                {name: "Algeria", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Algeria_on_the_globe_%28North_Africa_centered%29.svg"},
                {name: "Angola", img_url: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Angola_on_the_globe_%28Angola_centered%29.svg"},
                {name: "Benin", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Benin_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Botswana", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Botswana_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Burkina Faso", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Burkina_Faso_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Burundi", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Burundi_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Cameroon", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/38/Cameroon_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Cape Verde", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Cape_Verde_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Central African Republic", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/df/Central_African_Republic_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Chad", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Chad_on_the_globe_%28North_Africa_centered%29.svg"},
                {name: "Comoros", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/df/Comoros_on_the_globe_%28Madagascar_centered%29.svg"},
                {name: "Democratic Republic of the Congo", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Democratic_Republic_of_the_Congo_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Republic of the Congo", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Republic_of_the_Congo_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Djibouti", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/86/Djibouti_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Egypt", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d6/Egypt_on_the_globe_%28de-facto%29_%28North_Africa_centered%29.svg"},
                {name: "Equatorial Guinea", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Equatorial_Guinea_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Eritrea", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Eritrea_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Swaziland", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Swaziland_on_the_globe_%28Madagascar_centered%29.svg"},
                {name: "Ethiopia", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Ethiopia_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Gabon", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/02/Gabon_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Gambia", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/40/Gambia_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Ghana", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Ghana_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Guinea", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/84/Guinea_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Guinea-Bissau", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Guinea-Bissau_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Cote dIvoire", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/37/Cote_dIvoire_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Kenya", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Kenya_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Lesotho", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/95/Lesotho_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Liberia", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/73/Liberia_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Libya", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Libya_on_the_globe_%28North_Africa_centered%29.svg"},
                {name: "Madagascar", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/78/Madagascar_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Malawi", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Malawi_on_the_globe_%28Zambia_centered%29.svg"},
                {name: "Mali", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/82/Mali_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Mauritania", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a3/Mauritania_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Mauritius", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Mauritius_on_the_globe_%28Madagascar_centered%29.svg"},
                {name: "Morocco", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a7/Morocco_on_the_globe_%28de-facto%29_%28Cape_Verde_centered%29.svg"},
                {name: "Mozambique", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Mozambique_on_the_globe_%28Zambia_centered%29.svg"},
                {name: "Namibia", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/dc/Namibia_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Niger", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Niger_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Nigeria", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/78/Nigeria_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Rwanda", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/06/Rwanda_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Sahrawi Arab Democratic Republic", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/74/Sahrawi_Arab_Democratic_Republic_on_the_globe_%28de-facto%29_%28Cape_Verde_centered%29.svg"},
                {name: "Sao Tome and Principe", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Sao_Tome_and_Principe_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Senegal", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/3d/Senegal_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Seychelles", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Seychelles_on_the_globe_%28Zambia_centered%29.svg"},
                {name: "Sierra Leone", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Sierra_Leone_on_the_globe_%28Cape_Verde_centered%29.svg"},
                {name: "Somalia", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Somalia_on_the_globe_%28Africa_centered%29.svg"},
                {name: "South Africa", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9a/South_Africa_on_the_globe_%28Africa_centered%29.svg"},
                {name: "South Sudan", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a7/South_Sudan_on_the_globe_%28South_Sudan_centered%29.svg"},
                {name: "Sudan", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Sudan_on_the_globe_%28de-facto%29_%28North_Africa_centered%29.svg"},
                {name: "Tanzania", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/30/Tanzania_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Togo", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Togo_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Tunisia", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Tunisia_on_the_globe_%28North_Africa_centered%29.svg"},
                {name: "Uganda", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/07/Uganda_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Zambia", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Zambia_on_the_globe_%28Africa_centered%29.svg"},
                {name: "Zimbabwe", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Zimbabwe_on_the_globe_%28Zambia_centered%29.svg"},

                {name: "Australia", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/56/Australia_on_the_globe_%28Australia_centered%29.svg"},
                {name: "Cook Islands", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/71/Cook_Islands_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Fiji", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Fiji_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Indonesia", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Indonesia_on_the_globe_%28Indonesia_centered%29.svg"},
                {name: "Kiribati", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Kiribati_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Marshall Islands", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Marshall_Islands_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Micronesia", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Micronesia_on_the_globe_%28Southeast_Asia_centered%29_%28small_islands_magnified%29.svg"},
                {name: "Nauru", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/25/Nauru_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "New Zealand", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/46/New_Zealand_on_the_globe_%28New_Zealand_centered%29.svg"},
                {name: "Niue", img_url: "https://upload.wikimedia.org/wikipedia/commons/a/af/Niue_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Palau", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/57/Palau_on_the_globe_%28Southeast_Asia_centered%29_%28small_islands_magnified%29.svg"},
                {name: "Papua New Guinea", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/69/Papua_New_Guinea_on_the_globe_%28Oceania_centered%29.svg"},
                {name: "Samoa", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/74/Samoa_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Solomon Islands", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/8a/Solomon_Islands_on_the_globe_%28Oceania_centered%29.svg"},
                {name: "Brunei", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d8/Brunei_on_the_globe_%28Brunei_centered%29.svg"},
                {name: "Tonga", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Tonga_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Tuvalu", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2b/Tuvalu_on_the_globe_%28Polynesia_centered%29.svg"},
                {name: "Vanuatu", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Vanuatu_on_the_globe_%28Polynesia_centered%29.svg"},

                {name: "Aland", img_url: "https://upload.wikimedia.org/wikipedia/commons/8/81/Aland_in_the_European_Union_%28Europe_centered_globe%29.svg"},
                {name: "Austria", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/da/Austria_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Belgium", img_url: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Belgium_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Bulgaria", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Bulgaria_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Cyprus", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f5/Cyprus_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Czech Republic", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Czech_Republic_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Denmark", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Denmark_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Estonia", img_url: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Estonia_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Finland", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Finland_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "France", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/05/France_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Germany", img_url: "https://upload.wikimedia.org/wikipedia/commons/5/56/Germany_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Gibraltar", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Gibraltar_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Greece", img_url: "https://upload.wikimedia.org/wikipedia/commons/9/99/Greece_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Hungary", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Hungary_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Ireland", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Ireland_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Italy", img_url: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Italy_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Latvia", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Latvia_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Lithuania", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b2/Lithuania_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Luxembourg", img_url: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Luxembourg_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Malta", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Malta_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Netherlands", img_url: "https://upload.wikimedia.org/wikipedia/commons/6/64/Netherlands_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Poland", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Poland_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Portugal", img_url: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Portugal_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Romania", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Romania_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Slovakia", img_url: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Slovakia_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Slovenia", img_url: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Slovenia_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Spain", img_url: "https://upload.wikimedia.org/wikipedia/commons/7/77/Spain_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "Sweden", img_url: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Sweden_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},
                {name: "United Kingdom", img_url: "https://upload.wikimedia.org/wikipedia/commons/2/2e/United_Kingdom_in_the_European_Union_on_the_globe_%28Europe_centered%29.svg"},

            ],
        };
    }
}
</script>