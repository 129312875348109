<template>
    <RiverFrame v-bind:config="{correctSymbol: '🐨', configModal: true, configSaveFeature: true}" v-bind:saved="configSaved" v-on:renew="refresh" v-on:save="save" v-on:correct="handleCorrect">
        <template v-slot:config>
            <div class="form-group">
              <label for="sentenceInput"></label>
              <textarea class="form-control" name="sentenceInput" id="sentenceInput" rows="20" v-model="sentenceInput"></textarea>
            </div>
        </template>
        <div class="tate py-3 mx-auto">
            <span
                v-for="(word, wi) in sentence" v-bind:key="wi"
            >
                <br v-if="word.delimiter"/>
                <span v-else-if="word.particle">
                    <span
                        v-for="(char, ci) in word.expression" v-bind:key="ci"
                    >
                        <span class="border border-dark">
                            <span 
                                v-bind:style="{visibility: particleVisible ? 'visible': 'hidden'}"
                            >
                                {{ char }}
                            </span>
                        </span>
                    </span>
                </span>

                <span v-else>
                    {{ word.expression }}
                </span>
            </span>
        </div>
    </RiverFrame>
</template>

<script>
import RandomIndexPicker from "./RandomIndexPicker.js"
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },
    data: function() {
        return {
            sentence: [],
            sentenceInput: "",
            sentenceInputDefault:
                "710年\n" +
                "794年\n" +
                "1185年\n" +
                "1185年　1192年\n" +
                "1333年\n" +
                "1334年\n" +
                "1338年\n" +
                "1392年\n" +
                "1573年\n" +
                "1575年\n" +
                "1600年\n" +
                "1603年\n" +
                "1867年\n" +
                "1868年\n",
            sentenceUsed: "",
            sentenceSaved: "",
            particleVisible: false,
            randomIndexPicker: null,
        };
    },
    computed: {
        sentences: function() {
            return this.sentenceUsed.trim().split("\n");
        },
        configSaved: function() {
            return this.sentenceInput === this.sentenceSaved;
        }
    },
    created: function() {
        this.sentenceInput = this.sentenceInputDefault;
        const stored = localStorage.getItem('koala-config');
        if (stored) {
            const storedConfig = JSON.parse(stored);
            if (storedConfig && storedConfig.sentenceInput) {
                this.sentenceSaved = storedConfig.sentenceInput;
                this.sentenceInput = storedConfig.sentenceInput;
            }
        }
        this.refresh();
    },
    methods: {
        save: function() {
            const config = {sentenceInput: this.sentenceInput};
            localStorage.setItem('koala-config', JSON.stringify(config));
            this.sentenceUsed = this.sentenceInput;
            this.sentenceSaved = this.sentenceInput;
        },
        refresh: function() {
            this.sentenceUsed = this.sentenceInput;
            this.randomIndexPicker = new RandomIndexPicker(this.sentences);
            this.renew();
        },
        parseX: function(x) {
            let p0 = new RegExp(/([^[]+)/);
            const w0 = x.match(p0)[1];
            const ret = [{ expression: w0, particle: false }];
            let p1 = new RegExp(/\[(.+)\]/);
            const matched = x.match(p1);
            if (matched !== null) {
                ret.push({ expression: matched[1], particle: true });
            }
            ret.push({ delimiter: true })
            return ret;
        },
        parseSentence: function(sentence) {
            return sentence.split('/').reduce((a, x) => a.concat(this.parseX(x)), []);
        },
        renew: function() {
            this.particleVisible = false;
            const sentence = this.sentences[this.randomIndexPicker.next()];
            this.sentence = this.parseSentence(sentence);
        },
        handleCorrect: function() {
            this.particleVisible = true;
        }
    }
}
</script>
<style scoped>
.tate {
    font-size: 3rem;
}
</style>