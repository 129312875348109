import Random from "./Random.js";

export default {
    random: function () {
        const images = [
            "animal_buta_tereru",
            "animal_buta_tereru",
            "animal_character_hamster_happy",
            "animal_lesser_panda_stand",
            "animal_music_band_bass",
            "animal_smile_inu",
            "character_takoyaki_tako",
            "eto_ushi_fukubukuro",
            "fantasy_unicorn_rainbow",
            "ninja_makimono_kuwaeru",
            "ninja_musasabi",
            "ninja_tako",
            "norimono_character3_hikouki",
            "norimono_character7_helicopter",
            "onepiece01_luffy",
            "onepiece02_zoro_bandana",
            "robot_heart_kokoro",
            "war_character_sensya",
            "yumekawa_angel_tenshi",
            "yumekawa_animal_neko",
            "yumekawa_animal_penguin",
        ]
        const index = Random.randomInteger(images.length);
        return images[index];
    }
}