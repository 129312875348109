<template>
    <RiverFrame v-bind:config="{correctSymbol: '🍩', configModal: true, specialRewardThreshold: 10}" v-on:renew="renew" v-on:correct="reflectResult('correct')" v-on:miss="reflectResult('miss')">
        <template v-slot:config>
            <h5>計算の種類</h5>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="operation" id="operation-plus" value="+" v-model="configOperation['+']"> 足し算
                    <input class="form-check-input" type="checkbox" name="operation" id="operation-minus" value="-" v-model="configOperation['-']"> 引き算
                    <input class="form-check-input" type="checkbox" name="operation" id="operation-times" value="×" v-model="configOperation['×']"> 掛け算
                </label>
            </div>
        </template>
        <div class="equation mx-n1">
            <span v-if="blankPosition === 0">{{n0}}</span>
            <div v-else class="border border-dark my-3 px-1 blank"><span :style="blankContentStyle">{{n0}}</span></div>
            {{operation}}
            <span v-if="blankPosition === 1">{{n1}}</span>
            <div v-else class="border border-dark my-3 px-1 blank"><span :style="blankContentStyle">{{n1}}</span></div>
            =
            <span>{{n2}}</span>
        </div>
    </RiverFrame>
</template>

<script>
import Random from "./Random.js";
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },
    data: function () {
        return {
            n0: 0,
            n1: 0,
            n2: 0,
            configOperation: {
                '+': true,
                '-': true,
                '×': true,
            },
            operation: '+',
            blankPosition: 0,
            status: null,
            history: [],
            historyFilter: '',
        };
    },
    computed: {
        blankContentStyle: function() {
            return { visibility: this.status === 'correct' ? 'visible' : 'hidden' };
        },
        operationCandidates: function() {
            const candidates = [];
            for (const [operation, selected] of Object.entries(this.configOperation)) {
                if (selected) {
                    candidates.push(operation);
                }
            }
            return candidates;
        }
    },
    created: function() {
        document.title = "穴埋め計算の練習をするためのアプリ";
        this.renew();
    },
    methods: {
        renew: function() {
            this.status = null;
            const index = Random.randomInteger(this.operationCandidates.length);
            this.operation = this.operationCandidates[index];
            if (this.operation === '+') {
                let n0max = 9;
                this.n0 = Random.randomInteger(n0max) + 1;
                let n1max = 10 - this.n0;
                this.n1 = Random.randomInteger(n1max) + 1;
                this.n2 = this.n0 + this.n1;
            }
            if (this.operation === '-') {
                let n1max = 9;
                this.n1 = Random.randomInteger(n1max) + 1;
                let n2max = 9 - this.n1;
                this.n2 = Random.randomInteger(n2max) + 1;
                this.n0 = this.n1 + this.n2;
            }
            if (this.operation === '×') {
                this.n0 = Random.randomInteger(9) + 1;
                this.n1 = Random.randomInteger(9) + 1;
                this.n2 = this.n0 * this.n1;
            }
            this.blankPosition = Random.randomInteger(2);
        },
        reflectResult: function(result) {
            this.status = result;
        }
    }
}
</script>



<style scoped>
.equation {
    font-size: 4rem;
}
.blank {
    display: inline-block;
    text-align: center;
    width: 6rem;
}
</style>