<template>
    <RiverFrame v-bind:config="{correctSymbol: '🍑', configModal: true, configSaveFeature: true}" v-bind:saved="configSaved" v-on:renew="refresh" v-on:save="save" v-on:correct="handleCorrect">
        <template v-slot:config>
            <div class="form-group">
              <label for="sentenceInput"></label>
              <textarea class="form-control" name="sentenceInput" id="sentenceInput" rows="20" v-model="sentenceInput"></textarea>
            </div>
        </template>
        <div class="tate py-3 mx-auto">
            <span
                v-for="(word, wi) in sentence" v-bind:key="wi"
            >
                <br v-if="word.delimiter"/>
                <span v-else-if="word.particle">
                    <span
                        v-for="(char, ci) in word.expression" v-bind:key="ci"
                    >
                        <span class="border border-dark">
                            <span 
                                v-bind:style="{visibility: particleVisible ? 'visible': 'hidden'}"
                            >
                                {{ char }}
                            </span>
                        </span>
                    </span>
                </span>

                <span v-else>
                    {{ word.expression }}
                </span>
            </span>
        </div>
    </RiverFrame>
</template>

<script>
import RandomIndexPicker from "./RandomIndexPicker.js"
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },
    data: function() {
        return {
            sentence: [],
            sentenceInput: "",
            sentenceInputDefault:
                "でんしゃ[に]/のる\n" +
                "そら[を]/とぶ\n" +
                "こうえん[から]/かえる\n" +
                "いえ[に]/かえる\n" +
                "おなか[が]/すいた[ので]/ごはん[を]/たべる\n" +
                "クレヨン[で]/え[を]/かく\n" +
                "マイク[で]/うたう\n" +
                "ダンボール[から]/ロボット[を]/つくる\n" +
                "おしろ[に]/すむ\n" +
                "しょうがっこう[に]/いく\n" +
                "はな[に]/みずをやる\n" +
                "いえ[に]/かえる\n" +
                "しょうがっこう[から]/かえる\n" +
                "そら[から]/あめがふる\n" +
                "レストラン[で]/たべる\n" +
                "おふとん[で]/ねむる\n" +
                "れいぞうこ[を]/あける\n" +
                "キャベツ[を]/きる\n" +
                "おかわり[を]/たべる\n" +
                "いぬ[と]/あそぶ\n" +
                "ともだち[と]/あそぶ\n",
            sentenceUsed: "",
            sentenceSaved: "",
            particleVisible: false,
            randomIndexPicker: null,
        };
    },
    computed: {
        sentences: function() {
            return this.sentenceUsed.trim().split("\n");
        },
        configSaved: function() {
            return this.sentenceInput === this.sentenceSaved;
        }
    },
    created: function() {
        this.sentenceInput = this.sentenceInputDefault;
        const stored = localStorage.getItem('peach-config');
        if (stored) {
            const storedConfig = JSON.parse(stored);
            if (storedConfig && storedConfig.sentenceInput) {
                this.sentenceSaved = storedConfig.sentenceInput;
                this.sentenceInput = storedConfig.sentenceInput;
            }
        }
        this.refresh();
    },
    methods: {
        save: function() {
            const config = {sentenceInput: this.sentenceInput};
            localStorage.setItem('peach-config', JSON.stringify(config));
            this.sentenceUsed = this.sentenceInput;
            this.sentenceSaved = this.sentenceInput;
        },
        refresh: function() {
            this.sentenceUsed = this.sentenceInput;
            this.randomIndexPicker = new RandomIndexPicker(this.sentences);
            this.renew();
        },
        parseX: function(x) {
            let p0 = new RegExp(/([^[]+)/);
            const w0 = x.match(p0)[1];
            const ret = [{ expression: w0, particle: false }];
            let p1 = new RegExp(/\[(.+)\]/);
            const matched = x.match(p1);
            if (matched !== null) {
                ret.push({ expression: matched[1], particle: true });
            }
            ret.push({ delimiter: true })
            return ret;
        },
        parseSentence: function(sentence) {
            return sentence.split('/').reduce((a, x) => a.concat(this.parseX(x)), []);
        },
        renew: function() {
            this.particleVisible = false;
            const sentence = this.sentences[this.randomIndexPicker.next()];
            this.sentence = this.parseSentence(sentence);
        },
        handleCorrect: function() {
            this.particleVisible = true;
        }
    }
}
</script>
<style scoped>
.tate {
    font-size: 3rem;
    writing-mode: vertical-rl;
}
</style>