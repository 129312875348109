import firebase from 'firebase'

// Set the configuration for your app
// TODO: Replace with your project's config object
firebase.initializeApp({
  "projectId": "hanpuku-gakushu",
  "databaseURL": "https://hanpuku-gakushu.firebaseio.com",
  "storageBucket": "hanpuku-gakushu.appspot.com",
  "locationId": "asia-east2",
  "apiKey": "AIzaSyBh-_XELYDmEFSncIqXqeeK5XjwI5u0asg",
  "authDomain": "hanpuku-gakushu.firebaseapp.com",
  "messagingSenderId": "352173877989"
});
/*
fetch('/__/firebase/init.json').then(async response => {
    firebase.initializeApp(await response.json());
});
*/



export default {
    pushFirebase: function(material) {
        let db = firebase.firestore();
        db.collection("material").add(material)
        .then(function() {
            // console.log("Document successfully written!");
        })
    },
    fetchFirebase: function(id, fn) {
        let db = firebase.firestore();
        let docRef = db.collection("material").doc(id);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                fn(doc);
            } else {
                fn(undefined);
            }
        }).catch(function(error) {
            fn(undefined, error);
        });
    },
    fetch: function(id) {
        return {
            id: id,
            title: "もやすと ほのおは なにいろ?",
            questions: [
                {question: "リチウム", answer: "赤"},
                {question: "ナトリウム", answer: "黄"},
                {question: "カリウム", answer: "紫"},
                {question: "カルシウム", answer: "橙"},
                {question: "ストロンチウム", answer: "紅"},
                {question: "銅", answer: "緑青"},
                {question: "バリウム", answer: "黄緑"},
                {question: "ルビジウム", answer: "薄赤"},
                {question: "セシウム", answer: "青紫"}
            ]
        }
    }
}