import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './Home.vue'
import Solo, {soloChildren} from './Solo.vue'
import Duo, {duoChildren} from './components/Duo.vue'
import UploadMaterial from './components/UploadMaterial.vue'

Vue.config.productionTip = false;

const MyPlugin = {};
MyPlugin.install = function (Vue) {
  Vue.mixin({
    methods: {
      camelToKebab: function (camel) {
        return camel.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
      }
    }
  });
};
Vue.use(MyPlugin);

const NotFound = { template: '<p>Page not found</p>' };

const routes = [
  { path: '/', component: Home },
  { path: '/upload-material', component: UploadMaterial },
  { path: '/solo', component: Solo, children: soloChildren },
  { path: '/duo', component: Duo, children: duoChildren },
  { path: '/cherry', redirect: '/duo' },
  { path: '/bamboo', redirect: '/duo' },
  { path: '/pelmanism', redirect: '/duo' },
  { path: '/random-digits', redirect: '/duo' },
  { path: '/stone', redirect: '/duo' },
  { path: '/rabbit', redirect: '/duo' },
  { path: '/peach', redirect: '/duo' },
  { path: '/chimpanzee', redirect: '/duo' },
  { path: '*', component: NotFound },
];

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  template: '<router-view></router-view>',
  router
}).$mount('#app')
