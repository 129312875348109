<template>
    <Sequence v-bind:sequences="sequences" />
</template>

<script>
import Sequence from "./Sequence.vue"

export default {
    name: 'HistoricalSequence',
    title: "この ひと/くに/じだいを じゅんばんに ならべると？",
    components: {
        Sequence
    },
    data: function() {
        return {
            sequences: [
                {
                    subject: 'にほんのじだい',
                    elements: ['じょうもんじだい', 'やよいじだい', 'こふんじだい', 'あすかじだい', 'ならじだい', 'へいあんじだい', 'かまくらじだい', 'むろまちじだい', 'あづちももやまじだい', 'えどじだい', 'めいじじだい', 'たいしょうじだい', 'しょうわじだい', 'へいせいじだい', 'れいわじだい']
                },
                {
                    subject: '日本の時代',
                    elements: ['縄文時代', '弥生時代', '古墳時代', '飛鳥時代', '奈良時代', '平安時代', '鎌倉時代', '室町時代', '安土桃山時代', '江戸時代', '明治時代', '大正時代', '昭和時代', '平成時代']
                },
                {
                    subject: '中国の王朝',
                    elements: ['殷', '周', '秦', '漢', '三国時代', '晋', '南北朝', '隋', '唐', '五代・十国', '宋', '元', '明', '清', '中華民国', '中華人民共和国']
                },
                {
                    subject: 'アメリカの第1〜10代大統領',
                    elements: ['ジョージ・ワシントン', 'ジョン・アダムズ', 'トーマス・ジェファーソン', 'ジェームズ・マディスン', 'ジェームズ・モンロー', 'ジョン・クィンシー・アダムズ', 'アンドリュー・ジャクソン', 'マーティン・ビューレン', 'ウィリアム・ハリソン', 'ジョン・タイラー']
                },
                {
                    subject: 'アメリカの第11〜20代大統領',
                    elements: ['ジェームズ・ポーク', 'ザカリー・テイラー', 'ミラード・フィルモア', 'フランクリン・ピアース', 'ジェームズ・ブキャナン', 'エイブラハム・リンカーン', 'アンドリュー・ジョンソン', 'ユリシーズ・グラント', 'ラザフォード・ヘイズ', 'ジェームズ・ガーフィールド']
                },
                {
                    subject: 'アメリカの第21〜30代大統領',
                    elements: ['チェスター・アーサー', 'グロバー・クリーブランド', 'ベンジャミン・ハリソン', 'グロバー・クリーブランド', 'ウィリアム・マッキンリー', 'セオドア・ルーズベルト', 'ウィリアム・タフト', 'ウッドロウ・ウィルソン', 'ウォレン・ハーディング', 'カルビン・クーリッジ']
                },
                {
                    subject: 'アメリカの第31〜40代大統領',
                    elements: ['ハーバート・フーヴァー', 'フランクリン・ルーズベルト', 'ハリー・トルーマン', 'ドワイト・アイゼンハワー', 'ジョン・F・ケネディ', 'リンドン・ジョンソン', 'リチャード・ニクソン', 'ジェラルド・フォード', 'ジミー・カーター', 'ロナルド・レーガン']
                },
                {
                    subject: 'アメリカの第41〜50代大統領',
                    elements: ['ジョージ・H・W・ブッシュ', 'ビル・クリントン', 'ジョージ・W・ブッシュ', 'バラク・オバマ', 'ドナルド・トランプ']
                },
                {
                    subject: '鎌倉幕府の将軍',
                    elements: ['源　頼朝', '源　頼家', '源　実朝', '藤原　頼経', '藤原　頼嗣', '宗尊親王', '惟康親王', '久明親王', '守邦親王']
                },
                {
                    subject: '室町幕府の将軍',
                    elements: ['足利　尊氏', '足利　義詮', '足利　義満', '足利　義持', '足利　義量', '足利　義教', '足利　義勝', '足利　義政', '足利　義尚', '足利　義稙', '足利　義澄', '足利　義稙', '足利　義晴', '足利　義輝', '足利　義栄', '足利　義昭']
                },
                {
                    subject: '江戸幕府の将軍',
                    elements: ['家康', '秀忠', '家光', '家綱', '綱吉', '家宣', '家継', '吉宗', '家重', '家治', '家斉', '家慶', '家定', '家茂', '慶喜']
                },
                {
                    subject: '日本の第1〜10代内閣総理大臣',
                    elements: ['伊藤 博文', '黑田 清隆', '山縣 有朋', '松方 正義', '伊藤 博文', '松方 正義', '伊藤 博文', '大隈 重信', '山縣 有朋', '伊藤 博文']
                },
                {
                    subject: '日本の第11〜20代内閣総理大臣',
                    elements: ['桂 太郎', '西園寺 公望', '桂 太郎', '西園寺 公望', '桂 太郎', '山本 權兵衞', '大隈 重信', '寺内 正毅', '原 敬', '高橋 是清']
                },
                {
                    subject: '日本の第21〜30代内閣総理大臣',
                    elements: ['加藤 友三郎', '山本 權兵衞', '清浦 奎吾', '加藤 高明', '若槻 禮次郎', '田中 義一', '濱口 雄幸', '若槻 禮次郎', '犬養 毅', '齋藤 實']
                },
                {
                    subject: '日本の第31〜40代内閣総理大臣',
                    elements: ['岡田 啓介', '廣田 弘毅', '林 銑十郎', '近衞 文麿', '平沼 騏一郎', '阿部 信行', '米内 光政', '近衞 文麿', '近衞 文麿', '東條 英機']
                },
                {
                    subject: '日本の第41〜50代内閣総理大臣',
                    elements: ['小磯 國昭', '鈴木 貫太郎', '東久邇宮 稔彦王', '幣原 喜重郎', '吉田 茂', '片山 哲', '芦田 均', '吉田 茂', '吉田 茂', '吉田 茂']
                },
                {
                    subject: '日本の第51〜60代内閣総理大臣',
                    elements: ['吉田 茂', '鳩山 一郎', '鳩山 一郎', '鳩山 一郎', '石橋 湛山', '岸 信介', '岸 信介', '池田 勇人', '池田 勇人', '池田 勇人']
                },
                {
                    subject: '日本の第61〜70代内閣総理大臣',
                    elements: ['佐藤 榮作', '佐藤 榮作', '佐藤 榮作', '田中 角榮', '田中 角榮', '三木 武夫', '福田 赳夫', '大平 正芳', '大平 正芳', '鈴木 善幸']
                },
                {
                    subject: '日本の第71〜80代内閣総理大臣',
                    elements: ['中曽根 康弘', '中曽根 康弘', '中曽根 康弘', '竹下 登', '宇野 宗佑', '海部 俊樹', '海部 俊樹', '宮澤 喜一', '細川 護煕', '羽田 孜']
                },
                {
                    subject: '日本の第81〜90代内閣総理大臣',
                    elements: ['村山 富市', '橋本 龍太郎', '橋本 龍太郎', '小渕 恵三', '森 喜朗', '森 喜朗', '小泉 純一郎', '小泉 純一郎', '小泉 純一郎', '安倍 晋三']
                },
                {
                    subject: '日本の第91〜100代内閣総理大臣',
                    elements: ['福田 康夫', '麻生 太郎', '鳩山 由紀夫', '菅 直人', '野田 佳彦', '安倍 晋三', '安倍 晋三']
                },
            ],
        }
    }
}
</script>