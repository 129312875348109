<template>
    <div>
        <button type="button"
            v-for="(option, oi) in options" v-bind:key="`${option.value}${tick}`"
            v-bind:class="['btn', option.badgeClass, 'm-1']"
            v-on:click="judge(oi)"
            v-bind:disabled="option.disabled"
        >
            {{option.value}}
        </button>
    </div>
</template>

<script>
export default {
    props: ["tick", "optionValues", "correctIndex"],
    data: function() {
        return {
            debug: null,
            options: [],
        };
    },
    created: function() {
        this.setOptions(this.optionValues);
    },
    watch: {
        optionValues: function() {
            this.setOptions(this.optionValues);
        },
        tick: function() {
            this.setOptions(this.optionValues);
        }
    },
    methods: {
        setOptions: function(contents) {
            this.options = contents.map(value => {
                return {
                    value: value,
                    badgeClass: 'badge-secondary',
                    disabled: false
                };
            });
        },
        judge: function(index) {
            const correct = index === this.correctIndex;
            this.options[index].disabled = true;
            this.options[index].badgeClass = correct ? 'btn-success' : 'btn-danger';
            this.$emit('judge', correct);
        }
    }
}
</script>

<style scoped>
.btn {
    font-size: 2rem;
}
</style>