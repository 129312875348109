<template>
	<div>
		<GlobalNav />
		<div class="jumbotron">
			<h1 class="display-3">YouKan</h1>
			<p class="lead">YouKan（ようかん）は、問題を繰り返し解くことで基本的な知識を徹底的に身につけるための学習サイトです。</p>
			<hr class="my-2">
			<p>
					まず「もんだいリスト」の中から、身に付けたい知識の問題を選んで下さい。
			</p>
			<p>
					選択問題は、クリック（タップ）して緑になれば正解です。
			</p>
			<p>
					数字を入力する問題は、入力した数字が緑になれば正解です。
			</p>
			<p>
					黄色と灰色の四角が並んでいる問題は、四角をクリック（タップ）すると、正解が表示されます（四角をタップすると正解そのものである形式の問題には、正解判定はありません）。
			</p>
			<p class="lead">
				<router-link class="btn btn-primary btn-lg" to="/solo" role="button">やってみる</router-link>
			</p>
		</div>
	</div>
</template>

<script>
import GlobalNav from "./components/GlobalNav.vue"
export default {
    components: {
        GlobalNav,
    }    
}
</script>

<style scoped>
.jumbotron {
	background-color: chocolate;
}
.jumbotron h1 {
	color: white;
}
.jumbotron p {
	color: white;
}
</style>