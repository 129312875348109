<template>
    <div>
        <h3>{{title}}</h3>
        <table class="table table-borderless">
            <tr>
                <td class="py-0">
                    <span class="question my-0 py-0 text-nowrap" v-html="this.options[this.correctIndex].question"></span>
                    
                </td>
                <td class="py-0">
                    <div class="deck">
                        <button
                            v-for="(option, index) in options"
                            v-bind:key="option.answer"
                            v-on:click="mark(index)"
                            v-bind:class="['badge', option.badgeClass, 'mx-1', 'mt-0 mb-1']"
                            :disabled="option.disabled"
                        >
                            {{ option.answer }}
                        </button>
                    </div>
                </td>
            </tr>
        </table>
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js"
import TryAgainButton from "./TryAgainButton.vue"
import Achievement from "./Achievement.vue"

const title = "これを ローマじで かくと？"
const name = 'ProperNounWithHepburn'
export default {
    name: name,
    title: title,
    components: {
        TryAgainButton,
        Achievement
    },
    data: function() {
        return {
            title: title,
            debug: undefined,
            correct: false,
            total: 0,
            correctIndex: 0,
            targetIndex: -1,
            options: [],
            properNouns: [
                {kana: 'トヨタ', hepburn: 'TOYOTA'},
                {kana: 'スズキ', hepburn: 'SUZUKI'},
                {kana: 'みずほ', hepburn: 'MIZUHO'},
                {kana: 'ホンダ', hepburn: 'HONDA'},
                {kana: 'ダイキン', hepburn: 'DAIKIN'},
                {kana: 'みつびし', hepburn: 'MITSUBISHI'},
                {kana: 'ふじ', hepburn: 'FUJI'},
                {kana: 'キリン', hepburn: 'KIRIN'},
                {kana: 'スバル', hepburn: 'SUBARU'},
                {kana: 'クボタ', hepburn: 'kubota'},
                {kana: 'ニトリ', hepburn: 'nitori'},
                {kana: 'せきすい', hepburn: 'SEKISUI'},
                {kana: 'シマノ', hepburn: 'SHIMANO'},
                {kana: 'バンダイ', hepburn: 'BANDAI'},
                {kana: 'らくてん', hepburn: 'RAKUTEN'},
                {kana: 'ヤマハ', hepburn: 'YAMAHA'},
                {kana: 'めいじ', hepburn: 'meiji'},
                {kana: 'キッコーマン', hepburn: 'KIKKOMAN'},
                {kana: 'マキタ', hepburn: 'makita'},
                {kana: 'ひたち', hepburn: 'HITACHI'},
                {kana: 'ダイワ', hepburn: 'DAIWA'},
                {kana: 'いでみつ', hepburn: 'IDEMITSU'},
                {kana: 'さんてん', hepburn: 'santen'},
                {kana: 'かじま', hepburn: 'KAJIMA'},
                {kana: 'むじ', hepburn: 'MUJI'},
                {kana: 'コナミ', hepburn: 'KONAMI'},
                {kana: 'あじのもと', hepburn: 'AJINOMOTO'},
                {kana: 'めいてつ', hepburn: 'MEITETSU'},
                {kana: 'にっさん', hepburn: 'NISSAN'},
                {kana: 'せいぶ', hepburn: 'SEIBU'},
                {kana: 'ひの', hepburn: 'HINO'},
                {kana: 'ヤマダ', hepburn: 'YAMADA'},
                {kana: 'のむら', hepburn: 'NOMURA'},
                {kana: 'ひさみつ', hepburn: 'Hisamitsu'},
                {kana: 'ヤマザキ', hepburn: 'Yamazaki'},
                {kana: 'リンナイ', hepburn: 'Rinnai'},
                {kana: 'セガ', hepburn: 'SEGA'},
                {kana: 'ていじん', hepburn: 'TEIJIN'},
                {kana: 'もりなが', hepburn: 'MORINAGA'},
                {kana: 'マブチ', hepburn: 'MABUCHI'},
                {kana: 'アンリツ', hepburn: 'Anritsu'},
                {kana: 'カゴメ', hepburn: 'KAGOME'},
                {kana: 'カネカ', hepburn: 'KANEKA'},
                {kana: 'コクヨ', hepburn: 'KOKUYO'},
                {kana: 'サッポロ', hepburn: 'SAPPORO'},
                {kana: 'アサヒ', hepburn: 'Asahi'},
                {kana: 'サンリオ', hepburn: 'Sanrio'},
                {kana: 'いちばんや', hepburn: 'ICHIBANYA'},
                {kana: 'マルハ', hepburn: 'MARUHA'},
                {kana: 'ニチロ', hepburn: 'NICHIRO'},
                {kana: 'きんてつ', hepburn: 'KINTETSU'},
                {kana: 'タカラ', hepburn: 'Takara'},
                {kana: 'サイゼリヤ', hepburn: 'Saizeriya'},
                {kana: 'カドカワ', hepburn: 'KADOKAWA'},
                {kana: 'ノジマ', hepburn: 'Nojima'},
                {kana: 'ゼンリン', hepburn: 'Zenrin'},
                {kana: 'アオキ', hepburn: 'AOKI'},
                {kana: 'おおとや', hepburn: 'OOTOYA'},
            ]
        };
    },
    created: function() {
        this.renew();
    },
    methods: {
        x: function(src) {
            const vowlUpper = ['A', 'I', 'U', 'E', 'O'];
            const vowlLower = ['a', 'i', 'u', 'e', 'o'];
            const consonantUpper = ['K', 'S', 'T', 'C', 'N', 'H', 'F', 'M', 'Y', 'R', 'W', 'G', 'Z', 'J', 'D', 'P', 'B'];
            const consonantLower = ['k', 's', 't', 'c', 'n', 'h', 'f', 'm', 'y', 'r', 'w', 'g', 'z', 'j', 'd', 'p', 'b'];
            const characterCategories = [vowlUpper, vowlLower, consonantUpper, consonantLower];
            for (let cci = 0; cci < characterCategories.length; cci++) {
                const characterCategory = characterCategories[cci];
                const srcIndex = characterCategory.indexOf(src);
                if (srcIndex !== -1) {
                    const dstIndex = Random.randomIntegerExcept(characterCategory.length, [srcIndex]);
                    return characterCategory[dstIndex];
                }
            }
            return 'Q';
        },
        renew: function() {
            this.targetIndex = Random.randomIntegerExcept(this.properNouns.length, [this.targetIndex]);
            let targetWord = this.properNouns[this.targetIndex];
            let editPoints = Random.sample(targetWord.hepburn.length, 2);
            let options = editPoints.map(ep => {
                let substitution = this.x(targetWord.hepburn[ep]);
                let hepburn = targetWord.hepburn.split('');
                hepburn.splice(ep, 1, substitution);
                return hepburn.join('');
            });
            this.correctIndex = Random.randomInteger(3);
            options.splice(this.correctIndex, 0, targetWord.hepburn);
            this.options = options.map((option, oi) => {
                return {
                    question: oi === this.correctIndex ? this.properNouns[this.targetIndex].kana : '',
                    answer: option,
                    disabled: false,
                    badgeClass: 'badge-secondary'
                }
            });
        },
        mark: function(index) {
            this.options[index].disabled = true;
            this.correct = index === this.correctIndex;
            this.options[index].badgeClass = this.correct ? 'badge-success' : 'badge-danger';
            this.total += 1;
        }
    }
}
</script>