<template>
    <div class="container">
        <div class="row">
            <h6>{{title}}</h6>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-4">
                <img v-bind:style="{display: frontIsA ? 'block': 'none'}" class="quiz-img" v-bind:src="sideA.img_url" alt="image of quiz">
                <img v-bind:style="{display: frontIsA ? 'none': 'block'}" class="quiz-img" v-bind:src="sideB.img_url" alt="image of quiz">
            </div>
            <div class="col-xs-12 col-sm-8">
                <div
                    v-for="(option, index) in (frontIsA ? sideA : sideB).options"
                    v-bind:key="option.name"
                    class="mx-1 my-1"
                >
                    <button
                        type="button"
                        v-bind:class="['btn', option.badgeClass]"
                        v-on:click="mark(index)"
                    >
                        {{ option.name }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <TryAgainButton v-bind:renew="renew"/>
        </div>
        <div class="row">
            <div class="col-12">
                <Achievement v-bind:rightness="correct" v-bind:total="total"/>
            </div>
        </div>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";

export default {
    props: ['title', 'material'],
    data: function() {
        return {
            correct: false,
            total: 0,
            correctIndex: 0,
            // there are two side in order to load image of next quiz in advance
            sideA: {},
            sideB: {},
            optionIndices: [0],
            options: [1,2,3],
            filtered: [],
            frontIsA: false,
        };
    },
    components: {
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.setSide(this.sideA);
        this.renew();
    },
    methods: {
        renew: function() {
            this.frontIsA = ! this.frontIsA;
            if (this.frontIsA) {
                this.setSide(this.sideB);
            } else {
                this.setSide(this.sideA);
            }
        },
        setSide: function(side) {
            this.filtered = this.material;
            side.correctIndex = Random.randomInteger(3);
            const optionIndices = Random.sample(this.filtered.length, 3);

            side.options = optionIndices.map(n => this.filtered[n]).map(m => {
                return {
                    name: m.name,
                    img_url: m.img_url,
                    badgeClass: 'badge-secondary'
                }
            });
            side.img_url = side.options[side.correctIndex].img_url;
        },
        mark: function(index) {
            const side = this.frontIsA ? this.sideA : this.sideB;
            this.correct = index === side.correctIndex;
            side.options[index].badgeClass = this.correct ? 'btn-success' : 'btn-danger';
            this.total += 1;
        }
    }
}
</script>

<style scoped>
.quiz-img {
    width: 100%;
}
</style>