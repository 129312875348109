<template>
    <div>
        <h6>{{title}}</h6>
        <div>I washed ___.</div>
        my hands for 
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";

const title = 'どうしの つづきは？';
const name = 'TransitiveVerb';
export default {
    name: name,
    title: title,
    data: function() {
        return {
            title: title,
            correct: false,
            total: 0,
            prepositions: [
                'to',
                'for',
                'from',
                'in',
                'on',
                'at',
                'into',
                'about',
                'across',
                'through',
            ]
        };
    },
    components: {
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            Random.randomInteger(1);
            
        }
    }
}
</script>