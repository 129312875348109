<template>
    <ImageToText v-bind:title="title" v-bind:material="material" />    
</template>

<script>
import ImageToText from './ImageToText.vue'

const title = "この ばしょに ある けんは？";
export default {
    name: 'PrefectureInJapanLocation',
    title: title,
    components: {
        ImageToText
    },
    data: function() {
        return {
            title: title,
            material: [
                {name: "愛知", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Map_of_Japan_with_highlight_on_23_Aichi_prefecture.svg/375px-Map_of_Japan_with_highlight_on_23_Aichi_prefecture.svg.png Fukui|https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Map_of_Japan_with_highlight_on_18_Fukui_prefecture.svg/375px-Map_of_Japan_with_highlight_on_18_Fukui_prefecture.svg.png"},
                {name: "秋田", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Map_of_Japan_with_highlight_on_05_Akita_prefecture.svg/375px-Map_of_Japan_with_highlight_on_05_Akita_prefecture.svg.png"},
                {name: "青森", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Map_of_Japan_with_highlight_on_02_Aomori_prefecture.svg/375px-Map_of_Japan_with_highlight_on_02_Aomori_prefecture.svg.png"},
                {name: "千葉", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Map_of_Japan_with_highlight_on_12_Chiba_prefecture.svg/375px-Map_of_Japan_with_highlight_on_12_Chiba_prefecture.svg.png"},
                {name: "愛媛", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Map_of_Japan_with_highlight_on_38_Ehime_prefecture.svg/375px-Map_of_Japan_with_highlight_on_38_Ehime_prefecture.svg.png"},
                {name: "福井", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/Map_of_Japan_with_highlight_on_18_Fukui_prefecture.svg/375px-Map_of_Japan_with_highlight_on_18_Fukui_prefecture.svg.png"},
                {name: "福岡", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Map_of_Japan_with_highlight_on_40_Fukuoka_prefecture.svg/375px-Map_of_Japan_with_highlight_on_40_Fukuoka_prefecture.svg.png"},
                {name: "福島", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Map_of_Japan_with_highlight_on_07_Fukushima_prefecture.svg/375px-Map_of_Japan_with_highlight_on_07_Fukushima_prefecture.svg.png"},
                {name: "岐阜", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Map_of_Japan_with_highlight_on_21_Gifu_prefecture.svg/375px-Map_of_Japan_with_highlight_on_21_Gifu_prefecture.svg.png"},
                {name: "群馬", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Map_of_Japan_with_highlight_on_10_Gunma_prefecture.svg/375px-Map_of_Japan_with_highlight_on_10_Gunma_prefecture.svg.png"},
                {name: "広島", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Map_of_Japan_with_highlight_on_34_Hiroshima_prefecture.svg/375px-Map_of_Japan_with_highlight_on_34_Hiroshima_prefecture.svg.png"},
                {name: "北海道", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Map_of_Japan_with_highlight_on_02edit_Hokkaido_prefecture.svg/375px-Map_of_Japan_with_highlight_on_02edit_Hokkaido_prefecture.svg.png"},
                {name: "兵庫", img_url: " https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Map_of_Japan_with_highlight_on_28_Hy%C5%8Dgo_prefecture.svg/375px-Map_of_Japan_with_highlight_on_28_Hy%C5%8Dgo_prefecture.svg.png"},
                {name: "茨城", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Map_of_Japan_with_highlight_on_08_Ibaraki_prefecture.svg/375px-Map_of_Japan_with_highlight_on_08_Ibaraki_prefecture.svg.png"},
                {name: "石川", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Map_of_Japan_with_highlight_on_17_Ishikawa_prefecture.svg/375px-Map_of_Japan_with_highlight_on_17_Ishikawa_prefecture.svg.png"},
                {name: "岩手", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Map_of_Japan_with_highlight_on_03_Iwate_prefecture.svg/375px-Map_of_Japan_with_highlight_on_03_Iwate_prefecture.svg.png"},
                {name: "香川", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Map_of_Japan_with_highlight_on_37_Kagawa_prefecture.svg/375px-Map_of_Japan_with_highlight_on_37_Kagawa_prefecture.svg.png"},
                {name: "鹿児島", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Map_of_Japan_with_highlight_on_46_Kagoshima_prefecture.svg/375px-Map_of_Japan_with_highlight_on_46_Kagoshima_prefecture.svg.png"},
                {name: "神奈川", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Map_of_Japan_with_highlight_on_14_Kanagawa_prefecture.svg/375px-Map_of_Japan_with_highlight_on_14_Kanagawa_prefecture.svg.png"},
                {name: "高知", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Map_of_Japan_with_highlight_on_39_Kochi_prefecture.svg/375px-Map_of_Japan_with_highlight_on_39_Kochi_prefecture.svg.png"},
                {name: "熊本", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Map_of_Japan_with_highlight_on_43_Kumamoto_prefecture.svg/375px-Map_of_Japan_with_highlight_on_43_Kumamoto_prefecture.svg.png"},
                {name: "京都", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Map_of_Japan_with_highlight_on_26_Kyoto_prefecture.svg/375px-Map_of_Japan_with_highlight_on_26_Kyoto_prefecture.svg.png"},
                {name: "三重", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Map_of_Japan_with_highlight_on_24_Mie_prefecture.svg/375px-Map_of_Japan_with_highlight_on_24_Mie_prefecture.svg.png"},
                {name: "宮城", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Map_of_Japan_with_highlight_on_04_Miyagi_prefecture.svg/375px-Map_of_Japan_with_highlight_on_04_Miyagi_prefecture.svg.png"},
                {name: "宮崎", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Map_of_Japan_with_highlight_on_45_Miyazaki_prefecture.svg/375px-Map_of_Japan_with_highlight_on_45_Miyazaki_prefecture.svg.png"},
                {name: "長野", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Map_of_Japan_with_highlight_on_20_Nagano_prefecture.svg/375px-Map_of_Japan_with_highlight_on_20_Nagano_prefecture.svg.png"},
                {name: "長崎", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Map_of_Japan_with_highlight_on_42_Nagasaki_prefecture.svg/375px-Map_of_Japan_with_highlight_on_42_Nagasaki_prefecture.svg.png"},
                {name: "奈良", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Map_of_Japan_with_highlight_on_29_Nara_prefecture.svg/375px-Map_of_Japan_with_highlight_on_29_Nara_prefecture.svg.png"},
                {name: "新潟", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Map_of_Japan_with_highlight_on_15_Niigata_prefecture.svg/375px-Map_of_Japan_with_highlight_on_15_Niigata_prefecture.svg.png"},
                {name: "大分", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Map_of_Japan_with_highlight_on_44_Oita_prefecture.svg/375px-Map_of_Japan_with_highlight_on_44_Oita_prefecture.svg.png"},
                {name: "岡山", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Map_of_Japan_with_highlight_on_33_Okayama_prefecture.svg/375px-Map_of_Japan_with_highlight_on_33_Okayama_prefecture.svg.png"},
                {name: "大阪", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Map_of_Japan_with_highlight_on_27_Osaka_prefecture.svg/375px-Map_of_Japan_with_highlight_on_27_Osaka_prefecture.svg.png"},
                {name: "佐賀", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Map_of_Japan_with_highlight_on_41_Saga_prefecture.svg/375px-Map_of_Japan_with_highlight_on_41_Saga_prefecture.svg.png"},
                {name: "埼玉", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Map_of_Japan_with_highlight_on_11_Saitama_prefecture.svg/375px-Map_of_Japan_with_highlight_on_11_Saitama_prefecture.svg.png"},
                {name: "滋賀", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Map_of_Japan_with_highlight_on_25_Shiga_prefecture.svg/375px-Map_of_Japan_with_highlight_on_25_Shiga_prefecture.svg.png"},
                {name: "島根", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Map_of_Japan_with_highlight_on_32_Shimane_prefecture.svg/375px-Map_of_Japan_with_highlight_on_32_Shimane_prefecture.svg.png"},
                {name: "静岡", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Map_of_Japan_with_highlight_on_22_Shizuoka_prefecture.svg/375px-Map_of_Japan_with_highlight_on_22_Shizuoka_prefecture.svg.png"},
                {name: "栃木", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Map_of_Japan_with_highlight_on_09_Tochigi_prefecture.svg/375px-Map_of_Japan_with_highlight_on_09_Tochigi_prefecture.svg.png"},
                {name: "徳島", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Map_of_Japan_with_highlight_on_36_Tokushima_prefecture.svg/375px-Map_of_Japan_with_highlight_on_36_Tokushima_prefecture.svg.png"},
                {name: "鳥取", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Map_of_Japan_with_highlight_on_31_Tottori_prefecture.svg/375px-Map_of_Japan_with_highlight_on_31_Tottori_prefecture.svg.png"},
                {name: "富山", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Map_of_Japan_with_highlight_on_16_Toyama_prefecture.svg/375px-Map_of_Japan_with_highlight_on_16_Toyama_prefecture.svg.png"},
                {name: "和歌山", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Map_of_Japan_with_highlight_on_30_Wakayama_prefecture.svg/375px-Map_of_Japan_with_highlight_on_30_Wakayama_prefecture.svg.png"},
                {name: "山形", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Map_of_Japan_with_highlight_on_06_Yamagata_prefecture.svg/375px-Map_of_Japan_with_highlight_on_06_Yamagata_prefecture.svg.png"},
                {name: "山口", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Map_of_Japan_with_highlight_on_35_Yamaguchi_prefecture.svg/375px-Map_of_Japan_with_highlight_on_35_Yamaguchi_prefecture.svg.png"},
                {name: "山梨", img_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Map_of_Japan_with_highlight_on_19_Yamanashi_prefecture.svg/375px-Map_of_Japan_with_highlight_on_19_Yamanashi_prefecture.svg.png"},
            ],
        };
    }
}
</script>