import Random from "./Random.js";

export default {
    pi: -1,
    patterns: [
        {
            sentence: "{subject} {verb} beautiful .",
            options: {
                subject: ['You', 'He', 'She', 'They'],
                verb: ['am', 'are', 'is', 'was', 'were'],
            },
            constraints: [
                {
                    subject: ['You', 'They'],
                    verb: ['are'],
                    _questionables: ['subject']
                },
                {
                    subject: ['You'],
                    verb: ['are', 'were'],
                    _questionables: ['verb']
                },
                {
                    subject: ['They'],
                    verb: ['are', 'were'],
                    _questionables: ['verb']
                },
                {
                    subject: ['He', 'She'],
                    verb: ['is'],
                    _questionables: ['subject']
                },
                {
                    subject: ['He'],
                    verb: ['is', 'was'],
                    _questionables: ['verb']
                },
                {
                    subject: ['You', 'They'],
                    verb: ['were'],
                    _questionables: ['subject']
                },
                {
                    subject: ['He', 'She'],
                    verb: ['was'],
                    _questionables: ['subject']
                },
            ],
        },
        {
            sentence: "{subject} {verb} from Japan .",
            options: {
                subject: ['I', 'You', 'He', 'She', 'We', 'They'],
                verb: ['am', 'are', 'is'],
            },
            constraints: [
                {
                    subject: ['I'],
                    verb: ['am'],
                    _questionables: ['subject', 'verb']
                },
                {
                    subject: ['We', 'You', 'They'],
                    verb: ['are'],
                    _questionables: ['subject', 'verb']
                },
                {
                    subject: ['He', 'She'],
                    verb: ['is'],
                    _questionables: ['subject', 'verb']
                },
            ],
        },
        {
            sentence: "{subject} {verb} my {complement} .",
            options: {
                subject: ['I', 'You', 'He', 'She', 'We', 'They'],
                verb: ['am', 'are', 'is', 'was', 'were'],
                complement: ['friend', 'friends'],
            },
            constraints: [
                {
                    subject: ['He', 'She'],
                    verb: ['is', 'was'],
                    complement: ['friend'],
                    _questionables: ['subject', 'verb']
                },
                {
                    subject: ['You', 'They'],
                    verb: ['are', 'were'],
                    complement: ['friends'],
                    _questionables: ['subject', 'verb']
                },
            ],
        },
        {
            sentence: "{subject} {verb} the gate {time} .",
            options: {
                subject: ['I', 'You', 'He', 'She', 'We', 'They'],
                verb: ['shut', 'shuts'],
                time: ['yesterday', 'everyday'],
            },
            constraints: [
                {
                    subject: ['He', 'She'],
                    verb: ['shuts'],
                    time: ['everyday'],
                    _questionables: ['subject', 'verb', 'time']
                },
                {
                    subject: ['I', 'You', 'He', 'She', 'We', 'They'],
                    verb: ['shut'],
                    time: ['yesterday'],
                    _questionables: ['verb']
                },
                {
                    subject: ['I', 'You', 'We', 'They'],
                    verb: ['shut'],
                    time: ['everyday'],
                    _questionables: ['subject', 'verb']
                },
            ],
        },
        {
            sentence: "{subject} {verb} my teeth {time} .",
            options: {
            },
            subject: ['I', 'You', 'He', 'She'],
            verb: ['brush', 'brushed', 'brushes'],
            time: ['yesterday', 'everyday'],
            constraints: [
                {
                    subject: ['I', 'You'],
                    verb: ['brush'],
                    time: ['everyday'],
                    _questionables: ['subject', 'verb', 'time']
                },
                {
                    subject: ['He', 'She'],
                    verb: ['brushes'],
                    time: ['everyday'],
                    _questionables: ['subject', 'verb', 'time']
                },
                {
                    subject: ['I', 'You', 'He', 'She'],
                    verb: ['brushed'],
                    time: ['yesterday'],
                    _questionables: ['verb', 'time']                    
                }
            ],
        }
    ],
    generate: function() {
        this.pi = Random.randomIntegerExcept(this.patterns.length, [this.pi]);
        const pattern = this.patterns[this.pi];
        const ci = Random.randomInteger(pattern.constraints.length);
        const constraint = pattern.constraints[ci];
        let template_var = {};
        for (const placeholder in pattern.options) {
            if (constraint.hasOwnProperty(placeholder)) {
                const options = constraint[placeholder];
                template_var[placeholder] = options[Random.randomInteger(options.length)];
            }
        }
        const questionPart = constraint._questionables[Random.randomInteger(constraint._questionables.length)];
        const options = pattern.options[questionPart].reduce((a, p) => {
            if (! constraint[questionPart].includes(p)) {
                a.push(p);
            }
            return a;
        },
        [template_var[questionPart]]);
        var words = pattern.sentence.split(' ');
        var questionPartIndex = 0;
        for (let wi = 0; wi < words.length; wi++) {
            const word = words[wi];
            if (word[0] === '{') {
                if (word[word.length - 1] === '}') {
                    let placeholder = word.slice(1, -1);
                    words[wi] = template_var[placeholder];
                    if (placeholder === questionPart) {
                        questionPartIndex = wi;                        
                    }
                } /* else {
                    // error
                } */
            }
        }
        return {words: words, options: Random.shuffle(options), questionPartIndex: questionPartIndex};
    }
}
