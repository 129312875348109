<script>
export default {
    data: function() {
        return {
            n0: 0,
            n1: 1,
            input: '',
            background: '',
            clearNumPad: 0
        };
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            this.pickNumbers();
            this.background = '';
            this.clearNumPad += 1;
        },
        judge: function(actual) {
            this.input = actual;
            if (this.input === this.expected.toString()) {
                this.background = 'bg-success';
            }
        },
    }
}
</script>