<template>
    <div>
        <h6>{{title}}</h6>

        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";

const title = 'かせんの ぶぶんを ぎもんに すると？';
const name = 'InterrogativeWord';
export default {
    name: name,
    title: title,
    data: function() {
        return {
            title: title,
            correct: false,
            total: 0,
            interrogativePronounse: [
                {
                    pronoun: "Who", patterns: [
                        "[Someone] wants to be a millionaire",
                        "[Someone] ate my apple pie",

                ]},
                {
                    pronoun: "Who", patterns: [
                        "This is [my sister]",
                        "I met [Mr. Brown] at the station",
                        "She gave [Tom] an advice",
                ]},
                {
                    pronoun: "When", patterns: [
                        "I can play video game [after dinner]",

                ]},
                {
                    pronoun: "Where", patterns: [
                        "The key is [on the talbe]",

                ]},
                {
                    pronoun: "What", patterns: [
                        "You are thinking [something]",
                        "You want to be [something]",


                ]},
                {
                    pronoun: "Whose", patterns: [
                        "This toy is [hers]",

                ]},
                {
                    pronoun: "Why", patterns: [
                        "I came to Japan [for some reason]",
                        "I went to the shop [to buy some eggs]",

                ]},
                {
                    pronoun: "How", patterns: [
                        "You are [fine]",
                        "I feel [terrible]",
                        "You can go there [on foot]",

                ]},
            ],
            decraratives: [
            ]
        };
    },
    components: {
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            // const di = 
            Random.randomInteger(1);
            // const di = 0;
            // const decrarative = this.decraratives[di];

        }
    }
}
</script>