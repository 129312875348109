<template>
    <div>
        <h6>これを じゅんばんに ならべると？</h6>
        <div>
            <span
                class="badge badge-success mx-2 my-2"
                v-for="(g, gi) in gone" v-bind:key="'gone' + gi"
            >
            {{g}}
            </span>
        </div>
        <div>
            <button
                type="button"
                v-for="(op, oi) in options" v-bind:key="'option' + oi"
                v-bind:class="['btn', {unused: 'btn-secondary', used: 'btn-light', wrong: 'btn-danger'}[op.status], 'mx-2', 'my-2']"
                v-on:click="judge(oi)"
                v-bind:disabled="op.status === 'used'"
            >
                {{op.value}}
            </button>
        </div>
        <TryAgainButton v-bind:renew="renew"/>
    </div>
</template>

<script>
import Random from "./Random.js"
import TryAgainButton from "./TryAgainButton.vue"

export default {
    props: ["sequences"],
    components: {
        TryAgainButton
    },
    data: function() {
        return {
            options: [],
            gone: [],
            sequenceIndex: -1,
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            this.gone = [];
            this.sequenceIndex += 1;
            if (this.sequenceIndex === this.sequences.length) {
                this.sequenceIndex = 0;
            }
            let options = this.sequences[this.sequenceIndex].elements.slice();
            this.options = Random.shuffle(options).map(op =>{
                return {
                    value: op,
                    status: 'unused'
                }
            });
        },
        judge: function(optionIndex) {
            let elements = this.sequences[this.sequenceIndex].elements;
            if (elements[this.gone.length] == this.options[optionIndex].value) {
                this.options[optionIndex].status = 'used';
                this.gone.push(elements[this.gone.length]);
            } else {
                this.options[optionIndex].status = 'wrong';
                setTimeout(() => {
                    this.options[optionIndex].status = 'unused';
                }, 500);
            }
        }
    }
}
</script>
