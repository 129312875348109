<template>
    <div>
        <h6>{{title}}</h6>
        <div class="decrarative">{{decrarative.join(" ")}}</div>
        <SequenceQuiz v-bind:correctSequence="correctPhrase" v-bind:optionValues="options" />
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import EnglishSentencePattern from "./EnglishSentencePattern.js";
import SequenceQuiz from "./SequenceQuiz.vue";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";

const title = 'これを ぎもんぶんに すると？';
const name = 'InterrogativeSentence';
export default {
    name: name,
    title: title,
    data: function() {
        return {
            debug: null,
            title: title,
            correct: false,
            total: 0,
            interrogative: [],
            decrarative: [],
            correctPhrase: [],
            options: [],
            patterns: [
                {x2: "I am", x0: "Am I", x1: ["a member", "wrong", "in the correct room"]},
                {x2: "You are", x0: "Are you", x1: ["alright","OK", "sleepy", "a doctor", "talking to me"]},
                {x2: "He is", x0: "Is he", x1: ["Japanese", "her brother", "your son", "watching TV"]},
                {x2: "She is", x0: "Is she", x1: ["alone", "ten-year old", "angry", "in her room", "his wife"]},
                {x2: "It is", x0: "Is it", x1: ["true", "raining", "OK", "easy", "yours", "mine", "a joke"]},
                {x2: "We are", x0: "Are we", x1: ["friends", "rich", "poor"]},
                {x2: "They are", x0: "Are they", x1: ["his friends"]},
                {x2: "This is", x0: "Is this", x1: ["your first time"]},
                {x2: "These are", x0: "Are these", x1: ["correct", "yours", "his", "hers", "mine", "the members of my family"]},
                {x2: "That is", x0: "Is that", x1: ["OK"]},
                {x2: "Those are", x0: "Are those", x1: ["yours"]},
            ],
            patterns1: [
/* 
                {x2: "I", x0: "Do I", x1: ["need this"]},
                {x2: "You", x0: "Do you", x1: ["speak English", "understand this", "play football", "drink milk"]},
                {x2: "He", x0: "Does he", x1: ["speaks English", "plays football", "plays piano", ]},
                {x2: "She", x0: "Does she", x1: ["speaks English"]},
 */
                {s: ["You", "He", "She", "They"], v: ["speak", "speaks"], o: ["English", "French", "Japanese"]},
                {s: ["I", "He", "She", "We", "They"], v: ["need", "needs"], o: ["this", "your help"]},
                {s: ["You", "He", "She"], v: ["listen", "listens"], o: ["to jazz", "to music everyday"]},
                {s: ["You", "He", "She"], v: ["study", "studies"], o: ["English", "history"]},
                {s: ["I", "You", "He", "She"], v: ["have", "has"], o: ["to go there", "to do this", "a real friend"]},
                {s: ["You", "He", "She"], v: ["like", "likes"], o: ["Italian food", "dogs", "cats", "playing basketball", "to travel by air"]},
                {s: ["You", "He", "She"], v: ["drink", "drinks"], o: ["coffee", "milk"]},
                {s: ["You", "He", "She"], v: ["have", "has"], o: ["a sister", "a brother", "a car", "some money"]},
                {s: ["You", "He", "She"], v: ["believe", "believes"], o: ["me"]},
                {s: ["You"], v: ["hear"], o: ["me"]},
            ]
        };
    },
    components: {
        SequenceQuiz,
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
/* 
            if (Random.randomInteger(2) < 1) {
                this.renewBe();
            } else {
                this.renewDo();
            }
 */
        this.renewDo();
        },
        renewBe: function() {
            const pi = Random.randomInteger(this.patterns.length);
            const pattern = this.patterns[pi];
            const x1i = Random.randomInteger(pattern.x1.length);
            const complement = pattern.x1[x1i].split(" ");

            this.interrogative = pattern.x0.split(" ").concat(complement).concat(["?"]);
            this.decrarative = pattern.x2.split(" ").concat(complement).concat(["."]);

            const pdi = Random.randomIntegerExcept(this.patterns.length, [pi]);
            const patternDummy = this.patterns[pdi];
            const x1di = Random.randomInteger(patternDummy.x1.length);
            const complementDummy = patternDummy.x1[x1di].split(" ");

            var options = this.aggregate(this.interrogative, patternDummy.x0.split(" "), complementDummy);
            Random.shuffle(options);
            this.options = options;
            this.correctPhrase = this.interrogative;            
        },
        renewDo: function() {
            this.debug = 'renewDo';
            const pi = Random.randomInteger(this.patterns1.length);
            const pattern = new EnglishSentencePattern(this.patterns1[pi]);
            this.decrarative = pattern.randomDecrarative();
            this.interrogative = pattern.randomInterrogative();
            var options = this.aggregate(this.interrogative);
            Random.shuffle(options);
            this.options = options;
            this.correctPhrase = this.interrogative;
        },
        aggregate: function (...args) {
            var a0 = args.shift().slice();
            this.debug = a0;
            for (const a of args) {
                for (const element of a) {
                    if (! a0.includes(element)) {
                        a0.push(element);
                    }
                }
            }
            return a0;
        }
    }
}
</script>

<style scoped>
.decrarative {
    font-size: 2rem;
}
</style>