<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <span
                    class="digit"
                    v-for="(digit, index) in digits" v-bind:key="index"
                >
                    {{digit}}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button type="button" class="btn btn-outline-danger btn-lg btn-block" v-on:click="historyPush('NG')">NG</button>
            </div>
            <div class="col">
                <button type="button" class="btn btn-outline-success btn-lg btn-block" v-on:click="historyPush('OK')">OK</button>
            </div>
        </div>
        <div class="row my-3">
            <div class="col">
                <button type="button"
                    class="
                    renew-button
                    btn btn-primary btn-lg btn-block
                    "
                    v-on:click="renew"
                    >
                    次の数字
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <ul class="list-group">
                    <li
                        class="list-group-item d-flex justify-content-between align-items-center"
                        v-for="(item, idx) in history" v-bind:key="idx"
                    >
                        {{ item.digits.join('') }}
                        <span
                            v-if="item.judge === 'OK'"
                            class="badge badge-pill badge-success">OK
                        </span>
                        <span
                            v-else-if="item.judge === 'NG'"
                            class="badge badge-pill badge-danger">NG
                        </span>
                    </li>
                </ul>
                全{{history.length}}問 <span class="badge badge-pill badge-success">OK</span>{{countOK}}問 <span class="badge badge-pill badge-danger">NG</span> {{countNG}}問
            </div>
        </div>
    </div>
</template>

<script>
import Random from "./Random.js";

export default {
    data: function() {
        return {
            digits: [1, 2, 3, 4],
            history: [],
        };
    },
    computed: {
        countOK: function() {
            return this.countByJudege('OK');
        },
        countNG: function() {
            return this.countByJudege('NG');
        },
    },
    created: function() {
        this.renew();
    },
    methods: {
        countByJudege: function(judge) {
            return this.history.filter(item => item.judge === judge).length;            
        },
        renew: function() {
            const digits = [];
            for (let di = 0; di < 4; di++) {
                const number = Random.randomInteger(10);
                digits.push(number);
            }
            this.digits = digits;
        },
        historyPush: function(judge) {
            this.history.push({
                digits: this.digits,
                judge,
            });
        }
    },
}
</script>

<style scoped>
.digit {
    font-size: 6rem;
}
</style>