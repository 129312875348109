<template>
    <RiverFrame v-bind:config="{correctSymbol: '⏰', configModal: false, extraLeftAction: true, specialRewardThreshold: 10}" v-on:renew="renew">
        <div class="py-3 text-center">
            <div id="timepicker"></div>
        </div>
        <template v-slot:extra-left-action>
            <div>
                <button type="button" class="btn btn-light btn-lg mx-2" v-on:click="decrease">-</button>
                <button type="button" class="btn btn-light btn-lg mx-2" v-on:click="increase">+</button>
            </div>
        </template>
    </RiverFrame>
</template>

<script>
import Random from "./Random.js";
import Timepicker from "./Timepicker.js";
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },
    data: function() {
        return {
            hour: 0,
            minute: 0,
            clockNode: null,
        };
    },
    mounted: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            this.hour = Random.randomIntegerExcept(11, [this.minute]) + 1;
            this.minute = Random.randomIntegerExcept(60, [this.hour]);
            this.updateClock();
        },
        updateClock: function() {
            if (this.clockNode) {
                document.getElementById('timepicker').removeChild(this.clockNode);
            }
            let timepicker = new Timepicker(false, false, true, this.hour, this.minute);
            this.clockNode = timepicker.getElement();
            document.getElementById('timepicker').appendChild(timepicker.getElement());
        },
        decrease: function() {
            this.minute -= 1;
            if (this.minute < 0) {
                this.minute += 60;
                this.hour -= 1;
            }
            if (this.hour < 0) {
                this.hour += 12;
            }
            this.updateClock();
        },
        increase: function() {
            this.minute += 1;
            if (60 <= this.minute) {
                this.minute -= 60;
                this.hour += 1;
            }
            if (12 <= this.hour) {
                this.hour -= 12;
            }
            this.updateClock();
        }

    },
}
</script>
