<template>
    <div class="container">
        <div class="row">
            <h6>{{ material.title }}</h6>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-4">
                <span class="question" v-html="this.options[this.correctIndex].question"></span>
            </div>
            <div class="col-xs-12 col-sm-8">
                <button
                    v-for="(option, index) in options"
                    v-bind:key="option.answer"
                    v-on:click="mark(index)"
                    v-bind:class="['badge', option.badgeClass, 'mx-1', 'mt-0 mb-1']"
                    :disabled="option.disabled"
                >
                    {{ option.answer }}
                </button>
            </div>
        </div>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal v-if="tags.length !== 0">
            <h6>もんだいに ふくめる はたの とくちょうは？</h6>
            <div
                v-for="(tag, ti) in tags"
                v-bind:key="'tag' + ti"
                class="form-check form-check-inline"
                >
                <label class="form-check-label">
                    <input
                        class="form-check-input" type="checkbox" name="flag-features" v-bind:id="`flag-features-${ti}`"
                        v-model="filter" v-bind:value="tag.feature"> {{ tag.feature }}
                </label>
            </div>
        </QuizConfigurationModal>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import TryAgainButton from "./TryAgainButton.vue"
import Achievement from "./Achievement.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Random from "./Random.js"
import Material from "./Material.js"

export default {
    props: ['materialId'],
    components: {
        TryAgainButton,
        Achievement,
        QuizConfigurationModal
    },
    data: function() {
        return {
            correct: false,
            total: 0,
            correctIndex: 0,
            tags: [],
            options: [],
            filter: [],
            filteredIndecis: [],
            material: {}
        };
    },
    watch: {
        material: function() {
            this.generateTags();
            this.renew();
        }
    },
    created: function() {
        Material.fetchFirebase(this.materialId, (snapshot, error) => {
            if (error !== undefined) {
                alert(error);
            }
            if (snapshot !== undefined) {
                this.material = snapshot.data();
            }
        });
    },
    methods: {
        collectIndicesWithFeature: function(feature) {
            return this.material.questions.reduce(
                (accumulator, question, index) => accumulator.concat('tags' in question && question.tags.includes(feature) ? [index] : []),
                []
            );
        },
        generateTags: function() {
            const allTags = this.material.questions.reduce(
                (accumulator, question) => accumulator.concat('tags' in question ? question.tags : []),
                []
            );
            if (allTags.length !== 0) {
                const uniqueTags = [...new Set(allTags)];
                this.tags = uniqueTags.map(tag => { return { feature: tag, indices: this.collectIndicesWithFeature(tag) }});
            }
        },
        renew: function() {
            if (this.filter.length === 0) {
                this.filteredIndecis = [...this.material.questions.keys()];
            } else {
                this.filteredIndecis = this.tags.reduce((cat, tag) => cat.concat(this.filter.includes(tag.feature) ? tag.indices : []), []);
            }

            this.correctIndex = Random.randomInteger(3);

            const optionIndexes = Random.sample(this.filteredIndecis.length, 3).map(i => this.filteredIndecis[i]);
            this.options = optionIndexes.map(n => {
                const m = this.material.questions[n];
                return {
                    question: m.question,
                    answer: m.answer,
                    disabled: false,
                    badgeClass: 'badge-secondary'
                }
            });

        },
        mark: function(index) {
            this.options[index].disabled = true;
            this.correct = index === this.correctIndex;
            this.options[index].badgeClass = this.correct ? 'badge-success' : 'badge-danger';
            this.total += 1;
        }

    }
}
</script>

<style scoped>
.question {
  font-size: 2rem;
}
.badge {
    font-size: 1.5rem;
}
</style>
