<template>
    <div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-light btn-lg mb-3" data-toggle="modal" data-target="#configModal">
        設定
        </button>
        <!-- Modal -->
        <div class="modal fade" id="configModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">設定</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                        <label for="max-number">当てる数字の個数</label>
                        <select class="form-control" name="max-number" id="max-number" v-model="maxNumber">
                            <option v-for="v in [1, 2, 3, 4, 5, 6, 7, 8, 9]" v-bind:key="v">{{v}}</option>
                        </select>
                        </div>
                        <div class="form-group">
                        <label for="time-to-hide-number">数字が消えるまでの秒数</label>
                        <select class="form-control" name="time-to-hide-number" id="time-to-hide-number" v-model="timeToHideNumber">
                            <option v-for="v in [1, 2, 3, 4, 5, 6, 7, 8, 9]" v-bind:key="v">{{v}}</option>
                        </select>
                        </div>
                        <div class="form-group">
                        <label for="time-to-hide-number">マス目の行数</label>
                        <select class="form-control" name="time-to-hide-number" id="time-to-hide-number" v-model="tableHeight">
                            <option v-for="v in [3, 4, 5]" v-bind:key="v">{{v}}</option>
                        </select>
                        </div>
                        <div class="form-group">
                        <label for="time-to-hide-number">マス目の列数</label>
                        <select class="form-control" name="time-to-hide-number" id="time-to-hide-number" v-model="tableWidth">
                            <option v-for="v in [3, 4, 5]" v-bind:key="v">{{v}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">とじる</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-outline-secondary btn-sm rounded-circle float-right" data-toggle="modal" data-target="#helpModal">
            ?
        </button>

        <!-- Modal -->
        <div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalTitle" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">使い方</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            1からある数（デフォルトで6）までの数字がランダムな位置に表示されます。
                            <ul>
                                <li>どの数字がどの位置にあるか覚えて下さい</li>
                                <li>一定の時間（デフォルト3秒）が経過すると数字が消えます</li>
                                <li>1のあった枠から順にタップして下さい</li>
                                <li>間違っているとタップした枠が赤くなります</li>
                                <li>正しくタップすると数字がもう一度表示され、枠が緑色になります</li>
                                <li>全て正しくタップすると、全体が緑色になり完了です</li>
                                <li>「もういちど」をタップすると、新たに数字がランダムな位置に表示されます</li>
                                <li>「設定」をタップすると、設定画面が表示されます</li>
                            </ul>
                        </p>

                        <h5>設定画面</h5>
                        <p>
                            以下の項目が設定出来ます。
                            <ul>
                                <li>当てる数字の個数</li>
                                <li>数字が消えるまでの秒数</li>
                                <li>マス目の行数</li>
                                <li>マス目の列数</li>
                            </ul>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div v-for="(row, ri) in table" v-bind:key="ri" class="row no-gutters">
                <div
                    v-for="(col, ci) in row" v-bind:key="ci"
                    v-bind:class="['col', 'border', col.hit && !complete ? 'border-success' : '', complete ? 'bg-success' : '']">
                    <div v-bind:class="['text-center', 'display-1', col.alert ? 'bg-danger' : '']" v-on:click="judge(ri, ci)">
                        <span
                            v-bind:class="col.hit ? [] : []"
                            v-bind:style="{visibility: col.visible ?  'visible' : 'hidden'}">{{col.number}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button type="button" class="btn btn-light my-3 float-right" v-on:click="renew">もういちど</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Random from "./Random.js";

export default {
    data: function() {
        return {
            debug: null,
            table: [],
            configDefault: {
                maxNumber: 6,
                timeToHideNumber: 3,
                tableHeight: 3,
                tableWidth: 3,
            },
            maxNumber: 6,
            timeToHideNumber: 3,
            tableHeight: 3,
            tableWidth: 3,
            guessingNumber: 0,
            complete: false,
        };
    },
    watch: {
            maxNumber: function() {
                this.saveConfig();
            },
            timeToHideNumber: function() {
                this.saveConfig();
            },
            tableHeight: function() {
                this.saveConfig();
            },
            tableWidth: function() {
                this.saveConfig();
            },
    },
    created: function() {
        this.restoreConfig();
        this.renew();
    },
    methods: {
        populateConfig: function(src) {
            this.maxNumber = src.maxNumber;
            this.timeToHideNumber = src.timeToHideNumber;
            this.tableHeight = src.tableHeight;
            this.tableWidth = src.tableWidth;
        },
        restoreConfig: function() {
            this.populateConfig(this.configDefault);
            const stored = localStorage.getItem('chimpanzee-config');
            if (stored) {
                const storedConfig = JSON.parse(stored);
                if (storedConfig) {
                    this.populateConfig(storedConfig);
                }
            }
        },
        saveConfig: function() {
            const config = {
                    maxNumber: this.maxNumber,
                    timeToHideNumber: this.timeToHideNumber,
                    tableHeight: this.tableHeight,
                    tableWidth: this.tableWidth,
            };
            localStorage.setItem('chimpanzee-config', JSON.stringify(config));
        },
        renew: function() {
            this.guessingNumber = 1;
            this.complete = false;
            const slots = Random.sample(this.tableHeight * this.tableWidth, this.maxNumber);
            this.debug = slots;
            const tableSeed = [];
            for (let ri = 0; ri < this.tableHeight; ri++) {
                const row = [];
                for (let ci = 0; ci < this.tableWidth; ci++) {
                    row.push({number: 0, visible: false, alert: false});
                }
                tableSeed.push(row);
            }
            const numbers = Random.shuffle([1, 2, 3, 4, 5, 6, 7, 8, 9].slice(0, this.maxNumber));
            let ni = 0;
                /* 
            for (const slot of slots) {
                const ri = slot / this.tableWidth;
                const ci = slot % this.tableWidth;
                tableSeed[ri][ci] = {number: numbers[ni], visible: true, alert: false};
            }
                */
            for (let ri = 0; ri < tableSeed.length; ri++) {
                const row = tableSeed[ri];
                for (let ci = 0; ci < row.length; ci++) {
                    if (slots.includes(ri * this.tableWidth + ci)) {
                        tableSeed[ri][ci] = {number: numbers[ni], visible: true, alert: false};
                        ni++;
                    }
                }
            }
        this.table = tableSeed;
            setTimeout(() => {
                this.hideNumbers();
            }, 1000 * this.timeToHideNumber);
        },
        hideNumbers: function() {
            for (const row of this.table) {
                for (const cell of row) {
                    cell.visible = false;
                }
            }
        },
        judge: function(ri, ci) {
            if (this.table[ri][ci].number === this.guessingNumber) {
                this.table[ri][ci].visible = true;
                this.table[ri][ci].hit = true;
                this.guessingNumber++;
            } else {
                this.table[ri][ci].alert = true;
                setTimeout(() => {
                    this.table[ri][ci].alert = false;
                }, 500);
            }
            if (this.maxNumber < this.guessingNumber) {
                this.complete = true;
            }
        }
    },
}
</script>
