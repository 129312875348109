<template>
    <div>
        <button
            type="button"
            v-for="(op, oi) in options" v-bind:key="'option' + oi"
            v-bind:class="['btn', {unused: 'btn-secondary', used: 'btn-light', wrong: 'btn-danger'}[op.status], 'mx-2', 'my-2']"
            v-on:click="judge(oi)"
            v-bind:disabled="op.status === 'used'"
        >
            {{op.value}}
        </button>
    </div>
</template>

<script>
import Random from "./Random.js";

export default {
    props: ['elements'],
    data: function() {
        return {
            gone: [],
            options: [],
        };
    },
    created : function() {
        this.renew();
    },
    watch: {
        elements: function() {
            this.renew();
        }
    },
    methods: {
        renew: function() {
            this.gone = [];
            let options = this.elements.slice();
            this.options = Random.shuffle(options).map(op =>{
                return {
                    value: op,
                    status: 'unused'
                }
            });
        },
        judge: function(optionIndex) {
            let elements = this.elements;
            if (elements[this.gone.length] === this.options[optionIndex].value) {
                this.$emit('hit', this.gone.length);
                this.options[optionIndex].status = 'used';
                this.gone.push(elements[this.gone.length]);
            } else {
                this.options[optionIndex].status = 'wrong';
                setTimeout(() => {
                    this.options[optionIndex].status = 'unused';
                }, 500);
            }
        }
    }    
}
</script>