<template>
    <RiverFrame v-bind:config="{correctSymbol: '🍒', configModal: true, extraLeftAction: true, specialRewardThreshold: 10}" v-on:renew="renew" v-on:correct="handleCorrect" v-on:miss="handleMiss">
        <template v-slot:config>
            <h5>上の数の選び方</h5>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="root-mode" id="root-mode-fix" value="fix" v-model="rootMode">
                選んだ1つに固定
                </label>
            </div>
            <div class="form-group ml-3" v-bind:style="{visibility: rootMode === 'fix' ? 'visible' : 'hidden'}">
                <div class="form-check form-check-inline" v-for="sum in rootRange" v-bind:key="sum">
                    <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="sum" id="sum" v-bind:value="sum" v-model="fixedSum">
                    {{ sum }}
                    </label>
                </div>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="root-mode" id="root-mode-random" value="random" v-model="rootMode">
                選んだものでランダムに切り替える
                </label>
            </div>
            <div class="form-group ml-3" v-bind:style="{visibility: rootMode === 'random' ? 'visible' : 'hidden'}">
                <div class="form-check form-check-inline" v-for="sum in rootRange" v-bind:key="sum">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="sum-random" id="sum-random" value="sum" v-model="sumRandom[sum]">
                        {{ sum }}
                    </label>
                </div>
            </div>
            <h5>ランダムで空白にする場所</h5>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="empty-node-target-right" id="empty-node-target-right" v-model="emptyNodeTargets['right']" disabled checked> 右
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="empty-node-target-left" id="empty-node-target-left" v-model="emptyNodeTargets['left']"> 左
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="empty-node-target-root" id="empty-node-target-root" v-model="emptyNodeTargets['root']"> 上
                </label>
            </div>
        </template>
        <div class="container text-center my-3" v-on:click="renew">
            <div class="row">
                <div class="col">
                </div>
                <div class="col">
                    <div class="cherry-root">
                        <span v-bind:style="nodeStyle('root')">{{ sum }}</span>
                    </div>
                </div>
                <div class="col">
                </div>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-2 zyx"></div>
                <div class="col-2 xyz"></div>
                <div class="col-4"></div>
            </div>
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-4">
                    <div class="cherry-fruit">
                        <span v-bind:style="nodeStyle('left')">{{ left }}</span>
                        </div>
                </div>
                <div class="col-4">
                    <div class="cherry-fruit">
                        <span v-bind:style="nodeStyle('right')">{{ right }}</span>
                    </div>
                </div>
                <div class="col-2">
                </div>
            </div>
        </div>
    </RiverFrame>
</template>

<script lang="ts">
import Vue from 'vue'
import Random from './Random';
import RiverFrame from "./RiverFrame.vue";

export default Vue.extend({
    components: {
        RiverFrame,
    },    
    data: function() {
        return {
            roots: [],
            rootIndex: 0,
            rootMode: 'fix',
            rootRange: [3, 4, 5, 6, 7, 8, 9, 10],
            fixedSum: 10,
            sumRandom: { '3': true, '4': true, '5': true, '6': true, '7': true, '8': true, '9': true, '10': true },
            emptyNodeTargets: {
                'right': true,
                'left': true,
                'root': false,
            },
            emptyNode: 'right',
            status: null,
            quizIndex: -1,
            correctCount: 0,
            missCount: 0,
        };
    },
    computed: {
        sum: function() {
            return this.roots[this.rootIndex].value;
        },
        left: function() {
            return this.roots[this.rootIndex].quizList[this.quizIndex];
        },
        right: function() {
            return this.sum - this.left;
        }
    },
    watch: {
        rootMode: function() {
            this.reflect();
        }
    },
    created: function() {
        this.reflect();
    },
    methods: {
        nodeStyle: function (position) {
            return {visibility: this.emptyNode === position && this.status !== 'correct' ? 'hidden' : 'visible'};
        },
        initRoot: function(sum) {
            const quizList = [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(n => n < sum);
            return { value: sum, quizList };
        },
        reflect: function() {
            const rootValues = this.rootMode === 'fix' ? [this.fixedSum] : Object.keys(this.sumRandom).filter(k => this.sumRandom[k]);
            this.roots = rootValues.map(k => this.initRoot(parseInt(k)));
            this.renew();
        },
        renew: function() {
            this.status = null;

            const singleRoot = this.roots.length === 1;
            this.rootIndex = singleRoot ? 0 : Random.randomIntegerExcept(this.roots.length, [this.rootIndex]);
            const exceptions = singleRoot ? [this.quizIndex] : [];
            this.quizIndex = Random.randomIntegerExcept(this.roots[this.rootIndex].quizList.length, exceptions);
            let ns = ['left', 'root'].reduce((a, p) => this.emptyNodeTargets[p] ? [...a, p] : a, ['right']);
            this.emptyNode = ns[Random.randomInteger(ns.length)];
        },
        handleCorrect: function() {
            this.status = 'correct';
        },
        handleMiss: function() {
            this.status = 'miss';
        },
    },
})
</script>

<style scoped>
.btn {
    font-size: 2rem;
}
.cherry-fruit {
    font-size: 4rem;
    border: solid 2px red;
    border-radius: 50%;
}
.cherry-root {
    font-size: 4rem;
    border: solid 2px green;
    border-radius: 50%;
}
.xyz {
    border-bottom: 2px solid black;
    height: 1.5rem;
    -webkit-transform: translateY(-10px) translateX(5px) rotate(24deg);
}
.zyx {
    border-bottom: 2px solid black;
    height: 1.5rem;
    -webkit-transform: translateY(-10px) translateX(-5px) rotate(-24deg);
}
</style>
