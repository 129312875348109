<template>
    <div>
        <h3>{{title}}</h3>
        <table class="table table-borderless">
            <tr>
                <td class="py-0">
                    <span class="complement">{{this.options[this.correctIndex].leading_complement}}</span>
                    <span class="question my-0 py-0 text-nowrap" v-html="this.options[this.correctIndex].question"></span>
                    
                </td>
                <td class="py-0">
                    <div class="deck">
                        <button type="button"
                            v-for="(option, index) in options"
                            v-bind:key="option.answer"
                            v-on:click="mark(index)"
                            v-bind:class="['badge', option.badgeClass, 'mx-1', 'mt-0 mb-1']"
                            :disabled="option.disabled"
                        >
                            {{ option.answer }}
                        </button>
                    </div>
                </td>
            </tr>
        </table>
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import TryAgainButton from "./TryAgainButton.vue"
import Achievement from "./Achievement.vue"
import Random from "./Random.js"
const levenshtein = require('js-levenshtein');

const title = "これを えいごで かくと？"
const name = 'KatakanaWordInEnglish'
export default {
    name: name,
    title: title,
    components: {
        TryAgainButton,
        Achievement
    },
    data: function() {
        return {
            title: title,
            correct: false,
            total: 0,
            correctIndex: 0,
            options: [],
            targetWordIndex: -1,
            nearestSpellings: [],
            katakanWords: [
                {kana: 'アイス', spelling: 'ice'},
                {kana: 'アイデア', spelling: 'idea'},
                {kana: 'アイドル', spelling: 'idol'},
                {kana: 'アイテム', spelling: 'item'},
                {kana: 'アクシデント', spelling: 'accident'},
                {kana: 'アクション', spelling: 'action'},
                {kana: 'アスファルト', spelling: 'asphalt'},
                {kana: 'アップ', spelling: 'up'},
                {kana: 'アドバイス', spelling: 'advice'},
                {kana: 'アドベンチャー', spelling: 'adventure'},
                {kana: 'アトラクション', spelling: 'attraction'},
                {kana: 'アナウンサー', spelling: 'announcer'},
                {kana: 'アナウンス', spelling: 'announce'},
                {kana: 'アラーム', spelling: 'alarm'},
                {kana: 'アルバム', spelling: 'album'},
                {kana: 'イベント', spelling: 'event'},
                {kana: 'イメージ', spelling: 'image'},
                {kana: 'イルミネーション', spelling: 'ilumination'},
                {kana: 'インク', spelling: 'ink'},
                {kana: 'インテリア', spelling: 'interior'},
                {kana: 'ウェア', spelling: 'wear'},
                {kana: 'ウェット', spelling: 'wet'},
                {kana: 'ウェディング', spelling: 'wedding'},
                {kana: 'エスカレーター', spelling: 'escalator'},
                {kana: 'エリア', spelling: 'area'},
                {kana: 'エレベーター', spelling: 'elevator'},
                {kana: 'エンド', spelling: 'end'},
                {kana: 'エントランス', spelling: 'entrance'},
                {kana: 'オーダー', spelling: 'order'},
                {kana: 'オーディオ', spelling: 'audio'},
                {kana: 'オープン', spelling: 'open'},
                {kana: 'オーブン', spelling: 'oven'},
                {kana: 'オイル', spelling: 'oil'},
                {kana: 'オフ', spelling: 'off'},
                {kana: 'オフィス', spelling: 'office'},
                {kana: 'オリエンタル', spelling: 'oriental'},
                {kana: 'オレンジ', spelling: 'orange'},
                {kana: 'オン', spelling: 'on'},
                {kana: 'カーテン', spelling: 'curtain'},
                {kana: 'カード', spelling: 'card'},
                {kana: 'カーブ', spelling: 'curve'},
                {kana: 'カーペット', spelling: 'carpet'},
                {kana: 'ガイド', spelling: 'guide'},
                {kana: 'カウンター', spelling: 'counter'},
                {kana: 'カウント', spelling: 'count'},
                {kana: 'カジュアル', spelling: 'casual'},
                {kana: 'ガス', spelling: 'gas'},
                {kana: 'ガソリン', spelling: 'gasoline'},
                {kana: 'カタログ', spelling: 'catalog'},
                {kana: 'カッター', spelling: 'cutter'},
                {kana: 'カット', spelling: 'cut'},
                {kana: 'カップ', spelling: 'cup'},
                {kana: 'カップル', spelling: 'couple'},
                {kana: 'カバー', spelling: 'cover'},
                {kana: 'ガム', spelling: 'gum'},
                {kana: 'カメラ', spelling: 'camera'},
                {kana: 'カラー', spelling: 'color'},
                {kana: 'ガラス', spelling: 'glass'},
                {kana: 'カルシウム', spelling: 'calcium'},
                {kana: 'カルチャー', spelling: 'culture'},
                {kana: 'カレンダー', spelling: 'calendar'},
                {kana: 'カロリー', spelling: 'calorie'},
                {kana: 'カンニング', spelling: 'cunning'},
                {kana: 'キー', spelling: 'key'},
                {kana: 'ギア', spelling: 'gear'},
                {kana: 'キス', spelling: 'kiss'},
                {kana: 'ギター', spelling: 'guitar'},
                {kana: 'キック', spelling: 'kick'},
                {kana: 'ギフト', spelling: 'gift'},
                {kana: 'ギャグ', spelling: 'gag'},
                {kana: 'キャスト', spelling: 'cast'},
                {kana: 'キャッチ', spelling: 'catch'},
                {kana: 'キャップ', spelling: 'cap'},
                {kana: 'ギャップ', spelling: 'gap'},
                {kana: 'キャプテン', spelling: 'captain'},
                {kana: 'キャラクター', spelling: 'character'},
                {kana: 'キャラメル', spelling: 'caramel'},
                {kana: 'ギャング', spelling: 'gang'},
                {kana: 'キャンディ', spelling: 'candy'},
                {kana: 'キャンプ', spelling: 'camp'},
                {kana: 'ギャンブル', spelling: 'gamble'},
                {kana: 'キャンペーン', spelling: 'campaign'},
                {kana: 'クーポン', spelling: 'coupon'},
                {kana: 'クーラー', spelling: 'cooler'},
                {kana: 'クイズ', spelling: 'quiz'},
                {kana: 'クオリティ', spelling: 'quality'},
                {kana: 'クッション', spelling: 'cushion'},
                {kana: 'グッズ', spelling: 'goods'},
                {kana: 'グラウンド', spelling: 'ground'},
                {kana: 'クラス', spelling: 'class'},
                {kana: 'クラブ', spelling: 'club'},
                {kana: 'クリーナー', spelling: 'cleaner'},
                {kana: 'クリーニング', spelling: 'cleaning'},
                {kana: 'クリーム', spelling: 'cream'},
                {kana: 'グリーン', spelling: 'green'},
                {kana: 'クリア', spelling: 'clear'},
                {kana: 'グリル', spelling: 'grill'},
                {kana: 'グループ', spelling: 'group'},
                {kana: 'クロス', spelling: 'cross'},
                {kana: 'ケア', spelling: 'care'},
                {kana: 'ケアレス', spelling: 'careless'},
                {kana: 'ケーキ', spelling: 'cake'},
                {kana: 'ケース', spelling: 'case'},
                {kana: 'ゲート', spelling: 'gate'},
                {kana: 'ケーブル', spelling: 'cable'},
                {kana: 'ゲーム', spelling: 'game'},
                {kana: 'ゲット', spelling: 'get'},
                {kana: 'ゴーグル', spelling: 'goggle'},
                {kana: 'コース', spelling: 'course'},
                {kana: 'コーチ', spelling: 'coach'},
                {kana: 'コーティング', spelling: 'coating'},
                {kana: 'コード', spelling: 'cord', leading_complement: '電気などの'},
                {kana: 'コーナー', spelling: 'corner'},
                {kana: 'コーヒー', spelling: 'coffee'},
                {kana: 'ゴール', spelling: 'goal'},
                {kana: 'コイン', spelling: 'coin'},
                {kana: 'コスト', spelling: 'cost'},
                {kana: 'コピー', spelling: 'copy'},
                {kana: 'コメディ', spelling: 'comedy'},
                {kana: 'コメント', spelling: 'comment'},
                {kana: 'コレステロール', spelling: 'cholesterol'},
                {kana: 'コンタクト', spelling: 'contact'},
                {kana: 'コンパクト', spelling: 'compact'},
                {kana: 'コンパス', spelling: 'compass'},
                {kana: 'サービス', spelling: 'service'},
                {kana: 'サイズ', spelling: 'size'},
                {kana: 'サイド', spelling: 'side'},
                {kana: 'サイレン', spelling: 'siren'},
                {kana: 'サイン', spelling: 'sign'},
                {kana: 'サウンド', spelling: 'sound'},
                {kana: 'サスペンス', spelling: 'suspense'},
                {kana: 'サドル', spelling: 'saddle'},
                {kana: 'サンダル', spelling: 'sandal'},
                {kana: 'シーズン', spelling: 'season'},
                {kana: 'シート', spelling: 'seat', leading_complement: 'すわる'},
                {kana: 'シート', spelling: 'sheet', leading_complement: 'ぺらぺらの'},
                {kana: 'ジーンズ', spelling: 'jeans'},
                {kana: 'ジェット', spelling: 'jet'},
                {kana: 'ジェル', spelling: 'gel'},
                {kana: 'シニア', spelling: 'senior'},
                {kana: 'ジム', spelling: 'gym'},
                {kana: 'シャープ', spelling: 'sharp'},
                {kana: 'シャッター', spelling: 'shutter'},
                {kana: 'ジャム', spelling: 'jam'},
                {kana: 'シャワー', spelling: 'shower'},
                {kana: 'ジャンクション', spelling: 'junction'},
                {kana: 'ジャングル', spelling: 'jungle'},
                {kana: 'ジャンプ', spelling: 'jump'},
                {kana: 'シャンプー', spelling: 'shampoo'},
                {kana: 'ジュース', spelling: 'juice'},
                {kana: 'シュート', spelling: 'shoot'},
                {kana: 'ジュニア', spelling: 'junior'},
                {kana: 'ジョーク', spelling: 'joke'},
                {kana: 'ショック', spelling: 'shock'},
                {kana: 'ショット', spelling: 'shot'},
                {kana: 'ショップ', spelling: 'shop'},
                {kana: 'シリーズ', spelling: 'series'},
                {kana: 'シンプル', spelling: 'simple'},
                {kana: 'スーツ', spelling: 'suit'},
                {kana: 'スーパー', spelling: 'super'},
                {kana: 'スープ', spelling: 'soup'},
                {kana: 'スイッチ', spelling: 'switch'},
                {kana: 'スカイ', spelling: 'sky'},
                {kana: 'スキー', spelling: 'ski'},
                {kana: 'スキップ', spelling: 'skip'},
                {kana: 'スキン', spelling: 'skin'},
                {kana: 'スクープ', spelling: 'scoop'},
                {kana: 'スクール', spelling: 'school'},
                {kana: 'スクランブル', spelling: 'scramble'},
                {kana: 'スター', spelling: 'star'},
                {kana: 'スタート', spelling: 'start'},
                {kana: 'スタイル', spelling: 'style'},
                {kana: 'スタジアム', spelling: 'stadium'},
                {kana: 'スタジオ', spelling: 'studio'},
                {kana: 'スタンド', spelling: 'stand'},
                {kana: 'スタンプ', spelling: 'stamp'},
                {kana: 'ステーキ', spelling: 'steak'},
                {kana: 'ステージ', spelling: 'stage'},
                {kana: 'スティック', spelling: 'stick'},
                {kana: 'ステッカー', spelling: 'sticker'},
                {kana: 'ステップ', spelling: 'step'},
                {kana: 'ストーブ', spelling: 'stove'},
                {kana: 'ストア', spelling: 'store'},
                {kana: 'ストック', spelling: 'stock'},
                {kana: 'ストップ', spelling: 'stop'},
                {kana: 'ストライク', spelling: 'strike'},
                {kana: 'ストレート', spelling: 'straight'},
                {kana: 'ストレス', spelling: 'stress'},
                {kana: 'ストレッチ', spelling: 'stretch'},
                {kana: 'ストロー', spelling: 'straw'},
                {kana: 'スナック', spelling: 'snack'},
                {kana: 'スニーカー', spelling: 'sneaker'},
                {kana: 'スパイ', spelling: 'spy'},
                {kana: 'スパイス', spelling: 'spice'},
                {kana: 'スパイク', spelling: 'spike'},
                {kana: 'スパイダー', spelling: 'spider'},
                {kana: 'スピード', spelling: 'speed'},
                {kana: 'スプーン', spelling: 'spoon'},
                {kana: 'スプリンクラー', spelling: 'sprinkler'},
                {kana: 'スプレー', spelling: 'spray'},
                {kana: 'スポーツ', spelling: 'sport'},
                {kana: 'スポット', spelling: 'spot'},
                {kana: 'スムーズ', spelling: 'smooth'},
                {kana: 'スモーク', spelling: 'smoke'},
                {kana: 'スライス', spelling: 'slice'},
                {kana: 'スライム', spelling: 'slime'},
                {kana: 'スリッパ', spelling: 'slipper'},
                {kana: 'スリップ', spelling: 'slip'},
                {kana: 'スリル', spelling: 'thrill'},
                {kana: 'スローガン', spelling: 'slogan'},
                {kana: 'スロープ', spelling: 'slope'},
                {kana: 'セーフ', spelling: 'safe'},
                {kana: 'セール', spelling: 'sale'},
                {kana: 'セット', spelling: 'set'},
                {kana: 'ゼリー', spelling: 'jelly'},
                {kana: 'セレクト', spelling: 'select'},
                {kana: 'ゼロ', spelling: 'zero'},
                {kana: 'センサー', spelling: 'sensor'},
                {kana: 'センス', spelling: 'sense'},
                {kana: 'センター', spelling: 'center'},
                {kana: 'ソース', spelling: 'sauce'},
                {kana: 'ソープ', spelling: 'soap'},
                {kana: 'ゾーン', spelling: 'zone'},
                {kana: 'ソフト', spelling: 'soft'},
                {kana: 'ターミナル', spelling: 'terminal'},
                {kana: 'ターン', spelling: 'turn'},
                {kana: 'タイプ', spelling: 'type'},
                {kana: 'タイミング', spelling: 'timing'},
                {kana: 'タイム', spelling: 'time'},
                {kana: 'タイヤ', spelling: 'tire'},
                {kana: 'ダイレクト', spelling: 'direct'},
                {kana: 'ダウン', spelling: 'down'},
                {kana: 'タクシー', spelling: 'taxi'},
                {kana: 'タッチ', spelling: 'touch'},
                {kana: 'ダブル', spelling: 'double'},
                {kana: 'タレント', spelling: 'talent'},
                {kana: 'タンク', spelling: 'tank'},
                {kana: 'ダンス', spelling: 'dance'},
                {kana: 'チーズ', spelling: 'cheese'},
                {kana: 'チーム', spelling: 'team'},
                {kana: 'チェーン', spelling: 'chain'},
                {kana: 'チェック', spelling: 'check'},
                {kana: 'チェンジ', spelling: 'change'},
                {kana: 'チキン', spelling: 'chicken'},
                {kana: 'チケット', spelling: 'ticket'},
                {kana: 'チップ', spelling: 'chip', leading_complement: 'うすくきった'},
                {kana: 'チャージ', spelling: 'charge'},
                {kana: 'チャイム', spelling: 'chime'},
                {kana: 'チャンス', spelling: 'chance'},
                {kana: 'チャンネル', spelling: 'channel'},
                {kana: 'チャンピオン', spelling: 'champion'},
                {kana: 'チューブ', spelling: 'tube'},
                {kana: 'チョイス', spelling: 'choice'},
                {kana: 'チョコレート', spelling: 'chocolate'},
                {kana: 'チョップ', spelling: 'chop'},
                {kana: 'デート', spelling: 'date'},
                {kana: 'テープ', spelling: 'tape'},
                {kana: 'テーブル', spelling: 'table'},
                {kana: 'ディーラー', spelling: 'dealer'},
                {kana: 'ディスク', spelling: 'disk'},
                {kana: 'ディナー', spelling: 'dinner'},
                {kana: 'デザート', spelling: 'dessert'},
                {kana: 'テスト', spelling: 'test'},
                {kana: 'デビュー', spelling: 'debut'},
                {kana: 'トーク', spelling: 'talk'},
                {kana: 'トースト', spelling: 'toast'},
                {kana: 'ドーム', spelling: 'dome'},
                {kana: 'ドア', spelling: 'door'},
                {kana: 'ドキュメンタリー', spelling: 'documentary'},
                {kana: 'ドクター', spelling: 'doctor'},
                {kana: 'トップ', spelling: 'top'},
                {kana: 'ドライ', spelling: 'dry'},
                {kana: 'ドライブ', spelling: 'drive'},
                {kana: 'ドラッグ', spelling: 'drug'},
                {kana: 'トラック', spelling: 'truck', leading_complement: 'にもつをはこぶ'},
                {kana: 'トラブル', spelling: 'trouble'},
                {kana: 'ドラマ', spelling: 'drama'},
                {kana: 'トランク', spelling: 'trunk'},
                {kana: 'ドリーム', spelling: 'dream'},
                {kana: 'ドリル', spelling: 'drill'},
                {kana: 'ドリンク', spelling: 'drink'},
                {kana: 'トレー', spelling: 'tray'},
                {kana: 'トレード', spelling: 'trade'},
                {kana: 'トレーニング', spelling: 'training'},
                {kana: 'ドレス', spelling: 'dress'},
                {kana: 'トンネル', spelling: 'tunnel'},
                {kana: 'ナイス', spelling: 'nice'},
                {kana: 'ナイフ', spelling: 'knife'},
                {kana: 'ナレーション', spelling: 'narration'},
                {kana: 'ナレーター', spelling: 'narrator'},
                {kana: 'ナンバー', spelling: 'number'},
                {kana: 'ニュー', spelling: 'new'},
                {kana: 'ニュース', spelling: 'news'},
                {kana: 'ヌードル', spelling: 'noodle'},
                {kana: 'ネット', spelling: 'net'},
                {kana: 'ノート', spelling: 'note'},
                {kana: 'ノック', spelling: 'knock'},
                {kana: 'バー', spelling: 'bar'},
                {kana: 'パーティー', spelling: 'party'},
                {kana: 'ハート', spelling: 'heart'},
                {kana: 'ハーフ', spelling: 'half'},
                {kana: 'パイ', spelling: 'pie'},
                {kana: 'ハイキング', spelling: 'hiking'},
                {kana: 'バイク', spelling: 'bike'},
                {kana: 'パイプ', spelling: 'pipe'},
                {kana: 'パイロット', spelling: 'pilot'},
                {kana: 'バインダー', spelling: 'binder'},
                {kana: 'パウダー', spelling: 'powder'},
                {kana: 'バケツ', spelling: 'bucket'},
                {kana: 'バス', spelling: 'bus'},
                {kana: 'パス', spelling: 'pass'},
                {kana: 'バスケット', spelling: 'basket'},
                {kana: 'パスポート', spelling: 'passport'},
                {kana: 'パズル', spelling: 'puzzle'},
                {kana: 'バター', spelling: 'butter'},
                {kana: 'バック', spelling: 'back'},
                {kana: 'バッグ', spelling: 'bag'},
                {kana: 'パック', spelling: 'pack'},
                {kana: 'バット', spelling: 'bat'},
                {kana: 'ハッピー', spelling: 'happy'},
                {kana: 'バトル', spelling: 'battle'},
                {kana: 'パトロール', spelling: 'patrol'},
                {kana: 'バニラ', spelling: 'vanilla'},
                {kana: 'パネル', spelling: 'panel'},
                {kana: 'バブル', spelling: 'bubble'},
                {kana: 'バラエティ', spelling: 'variety'},
                {kana: 'パラソル', spelling: 'parasol'},
                {kana: 'バランス', spelling: 'balance'},
                {kana: 'バリア', spelling: 'barrier'},
                {kana: 'バリュー', spelling: 'value'},
                {kana: 'バルブ', spelling: 'bulb'},
                {kana: 'パワー', spelling: 'power'},
                {kana: 'ハンガー', spelling: 'hanger'},
                {kana: 'パンチ', spelling: 'punch'},
                {kana: 'パンツ', spelling: 'pants'},
                {kana: 'バンド', spelling: 'band'},
                {kana: 'ハンド', spelling: 'hand'},
                {kana: 'ハンドル', spelling: 'handle'},
                {kana: 'バンパー', spelling: 'bumper'},
                {kana: 'パンフレット', spelling: 'pamphlet'},
                {kana: 'ピース', spelling: 'piece', leading_complement: 'パズルの'},
                {kana: 'ヒーター', spelling: 'heater'},
                {kana: 'ビーチ', spelling: 'beach'},
                {kana: 'ビーム', spelling: 'beam'},
                {kana: 'ヒーロー', spelling: 'hero'},
                {kana: 'ピアス', spelling: 'pierce'},
                {kana: 'ピアノ', spelling: 'piano'},
                {kana: 'ピクニック', spelling: 'picnic'},
                {kana: 'ビジネス', spelling: 'business'},
                {kana: 'ビタミン', spelling: 'vitamin'},
                {kana: 'ビデオ', spelling: 'video'},
                {kana: 'ビニール', spelling: 'vinyl'},
                {kana: 'ピン', spelling: 'pin'},
                {kana: 'ピンク', spelling: 'pink'},
                {kana: 'ピンチ', spelling: 'pinch'},
                {kana: 'フード', spelling: 'food'},
                {kana: 'プール', spelling: 'pool'},
                {kana: 'ファッション', spelling: 'fashion'},
                {kana: 'ファン', spelling: 'fan'},
                {kana: 'フィルム', spelling: 'film'},
                {kana: 'フェア', spelling: 'fair'},
                {kana: 'フォーク', spelling: 'fork'},
                {kana: 'フォーマル', spelling: 'formal'},
                {kana: 'ブザー', spelling: 'buzzer'},
                {kana: 'フライ', spelling: 'fry', leading_complement: 'あげもの'},
                {kana: 'プライス', spelling: 'price'},
                {kana: 'プライド', spelling: 'pride'},
                {kana: 'プライベート', spelling: 'private'},
                {kana: 'ブラシ', spelling: 'brush'},
                {kana: 'プラス', spelling: 'plus'},
                {kana: 'プラスチック', spelling: 'plastic'},
                {kana: 'フラッシュ', spelling: 'flash'},
                {kana: 'プラン', spelling: 'plan'},
                {kana: 'プリント', spelling: 'print'},
                {kana: 'ブルー', spelling: 'blue'},
                {kana: 'フルーツ', spelling: 'fruit'},
                {kana: 'ブレーキ', spelling: 'brake'},
                {kana: 'プレート', spelling: 'plate'},
                {kana: 'プレゼント', spelling: 'present'},
                {kana: 'フレッシュ', spelling: 'fresh'},
                {kana: 'プロ', spelling: 'pro'},
                {kana: 'フロート', spelling: 'float'},
                {kana: 'フロア', spelling: 'floor'},
                {kana: 'ブロック', spelling: 'block'},
                {kana: 'フロント', spelling: 'front'},
                {kana: 'ページ', spelling: 'page'},
                {kana: 'ペース', spelling: 'pace'},
                {kana: 'ペーパー', spelling: 'paper'},
                {kana: 'ヘア', spelling: 'hair'},
                {kana: 'ペア', spelling: 'pair'},
                {kana: 'ベスト', spelling: 'best'},
                {kana: 'ペダル', spelling: 'pedal'},
                {kana: 'ベッド', spelling: 'bed'},
                {kana: 'ペット', spelling: 'pet'},
                {kana: 'ベビー', spelling: 'baby'},
                {kana: 'ベル', spelling: 'bell'},
                {kana: 'ヘルシー', spelling: 'healthy'},
                {kana: 'ベルト', spelling: 'belt'},
                {kana: 'ヘルメット', spelling: 'helmet'},
                {kana: 'ペン', spelling: 'pen'},
                {kana: 'ベンチ', spelling: 'bench'},
                {kana: 'ホース', spelling: 'hose'},
                {kana: 'ポーズ', spelling: 'pose'},
                {kana: 'ホーム', spelling: 'home'},
                {kana: 'ボール', spelling: 'ball'},
                {kana: 'ホール', spelling: 'hall', leading_complement: 'イベントをする'},
                {kana: 'ホイップ', spelling: 'whip'},
                {kana: 'ポイント', spelling: 'point'},
                {kana: 'ポケット', spelling: 'pocket'},
                {kana: 'ポスター', spelling: 'poster'},
                {kana: 'ポスト', spelling: 'post'},
                {kana: 'ホット', spelling: 'hot'},
                {kana: 'ポット', spelling: 'pot'},
                {kana: 'ボディ', spelling: 'body'},
                {kana: 'ホテル', spelling: 'hotel'},
                {kana: 'ボトル', spelling: 'bottle'},
                {kana: 'ホラー', spelling: 'horror'},
                {kana: 'ボランティア', spelling: 'volunteer'},
                {kana: 'ボリューム', spelling: 'volume'},
                {kana: 'ポンプ', spelling: 'pump'},
                {kana: 'ボンド', spelling: 'bond'},
                {kana: 'マーカー', spelling: 'marker'},
                {kana: 'マーケット', spelling: 'market'},
                {kana: 'マーブル', spelling: 'marble'},
                {kana: 'マイク', spelling: 'mic'},
                {kana: 'マイナス', spelling: 'minus'},
                {kana: 'マイルド', spelling: 'mild'},
                {kana: 'マグネット', spelling: 'magnet'},
                {kana: 'マスク', spelling: 'mask'},
                {kana: 'マッサージ', spelling: 'massage'},
                {kana: 'マッチ', spelling: 'match'},
                {kana: 'マット', spelling: 'mat'},
                {kana: 'マップ', spelling: 'map'},
                {kana: 'マナー', spelling: 'manner'},
                {kana: 'マフラー', spelling: 'muffler'},
                {kana: 'マラソン', spelling: 'marathon'},
                {kana: 'マンション', spelling: 'mansion'},
                {kana: 'ミス', spelling: 'miss'},
                {kana: 'ミックス', spelling: 'mix'},
                {kana: 'ミュージアム', spelling: 'museum'},
                {kana: 'ミュージカル', spelling: 'musical'},
                {kana: 'ミラー', spelling: 'mirror'},
                {kana: 'ミルク', spelling: 'milk'},
                {kana: 'メーター', spelling: 'meter'},
                {kana: 'メール', spelling: 'mail'},
                {kana: 'メイク', spelling: 'make'},
                {kana: 'メジャー', spelling: 'measure', leading_complement: 'ながさをはかる'},
                {kana: 'メダル', spelling: 'medal'},
                {kana: 'メタル', spelling: 'metal'},
                {kana: 'メディア', spelling: 'media'},
                {kana: 'メニュー', spelling: 'menu'},
                {kana: 'メモ', spelling: 'memo'},
                {kana: 'メロディ', spelling: 'melody'},
                {kana: 'メロン', spelling: 'melon'},
                {kana: 'メンバー', spelling: 'member'},
                {kana: 'モーター', spelling: 'motor'},
                {kana: 'モード', spelling: 'mode'},
                {kana: 'モーニング', spelling: 'morning'},
                {kana: 'モップ', spelling: 'mop'},
                {kana: 'モデル', spelling: 'model'},
                {kana: 'モニター', spelling: 'monitor'},
                {kana: 'モンスター', spelling: 'monster'},
                {kana: 'ユニーク', spelling: 'unique'},
                {kana: 'ユニバーサル', spelling: 'universal'},
                {kana: 'ライオン', spelling: 'lion'},
                {kana: 'ライス', spelling: 'rice'},
                {kana: 'ライト', spelling: 'light', leading_complement: 'あかるくする'},
                {kana: 'ライフ', spelling: 'life'},
                {kana: 'ライブ', spelling: 'live'},
                {kana: 'ライン', spelling: 'line'},
                {kana: 'ラウンジ', spelling: 'lounge'},
                {kana: 'ラッキー', spelling: 'lucky'},
                {kana: 'ラッシュ', spelling: 'rush'},
                {kana: 'ラッピング', spelling: 'wrapping'},
                {kana: 'ラップ', spelling: 'wrap'},
                {kana: 'ラベル', spelling: 'label'},
                {kana: 'ランキング', spelling: 'ranking'},
                {kana: 'ランク', spelling: 'rank'},
                {kana: 'ランチ', spelling: 'lunch'},
                {kana: 'ランド', spelling: 'land'},
                {kana: 'ランドリー', spelling: 'laundry'},
                {kana: 'ランプ', spelling: 'lamp'},
                {kana: 'リーダー', spelling: 'leader'},
                {kana: 'リズム', spelling: 'rythm'},
                {kana: 'リゾート', spelling: 'resort'},
                {kana: 'リッチ', spelling: 'rich'},
                {kana: 'リップ', spelling: 'lip'},
                {kana: 'リニューアル', spelling: 'renewal'},
                {kana: 'リバーシブル', spelling: 'reversible'},
                {kana: 'リバース', spelling: 'reverse'},
                {kana: 'リピート', spelling: 'repeat'},
                {kana: 'リフト', spelling: 'lift'},
                {kana: 'リング', spelling: 'ring'},
                {kana: 'ルール', spelling: 'rule'},
                {kana: 'レイアウト', spelling: 'layout'},
                {kana: 'レース', spelling: 'race', leading_complement: 'くるまの'},
                {kana: 'レール', spelling: 'rail'},
                {kana: 'レター', spelling: 'letter'},
                {kana: 'レッスン', spelling: 'lesson'},
                {kana: 'レバー', spelling: 'lever', leading_complement: 'ものをうごかす'},
                {kana: 'レバー', spelling: 'liver', leading_complement: 'やきとりの'},
                {kana: 'レベル', spelling: 'level'},
                {kana: 'レンジ', spelling: 'range'},
                {kana: 'レンズ', spelling: 'lens'},
                {kana: 'レンタル', spelling: 'rental'},
                {kana: 'ロープ', spelling: 'rope'},
                {kana: 'ロール', spelling: 'roll'},
                {kana: 'ローン', spelling: 'loan'},
                {kana: 'ロケット', spelling: 'rocket'},
                {kana: 'ロック', spelling: 'lock'},
                {kana: 'ロビー', spelling: 'lobby'},
                {kana: 'ワイヤー', spelling: 'wire'},
                {kana: 'ワイルド', spelling: 'wild'},
            ]
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        pickNearestSpellings: function(word, count) {
            let s = word.spelling;
            let sorted = this.katakanWords.sort((w0, w1) => levenshtein(s, w0.spelling) - levenshtein(s, w1.spelling));
            return sorted.slice(0, count);
        },
        renew: function() {
            this.targetWordIndex = Random.randomIntegerExcept(this.katakanWords.length, [this.targetWordIndex]);
            this.nearestSpellings = this.pickNearestSpellings(this.katakanWords[this.targetWordIndex], 8);
            const optionIndexes = Random.sampleExcept(this.nearestSpellings.length, 2, [0]);
            this.correctIndex = Random.randomInteger(3);
            optionIndexes.splice(this.correctIndex, 0, 0);
            this.options = optionIndexes.map(n => {
                return {
                    question: this.nearestSpellings[n].kana,
                    answer: this.nearestSpellings[n].spelling,
                    leading_complement: this.nearestSpellings[n].leading_complement,
                    disabled: false,
                    badgeClass: 'badge-secondary'
                };
            });
        },
        mark: function(index) {
            this.options[index].disabled = true;
            this.correct = index === this.correctIndex;
            this.options[index].badgeClass = this.correct ? 'badge-success' : 'badge-danger';
            this.total += 1;
        }
    }
}
</script>

<style scoped>
.complement {
    font-size: 16px;
}
</style>