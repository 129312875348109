<template>
    <div>
        <div v-if="$route.path === '/duo' || $route.path === '/duo/'">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2>子供に色々教えるためのもの</h2>
                        <ul class="list-group">
                            <li class="list-group-item"><router-link to="/duo/bamboo" class="text-reset">🎍 かな・漢字の読み方を教えるためのバンブー</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/cherry" class="text-reset">🍒 さくらんぼ計算を教えるためのチェリー</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/kiwi" class="text-reset">🥝 四則演算を教えるためのキウイ</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/doughnut" class="text-reset">🍩 穴あき計算を練習するためのドーナツ</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/rabbit" class="text-reset">🐰 アナログ時計の読み方を教えるためのラビット</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/hamster" class="text-reset">🐹 時間の計算を教えるためのハムスター</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/bagle" class="text-reset">🥯 長さの計算を教えるためのベーグル</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/stone" class="text-reset">🍏 ものの数え方を教えるためのアップル</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/elephant" class="text-reset">🐘 長さの単位を教えるためのエレファント</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/peach" class="text-reset">🍑 助詞の使い方を教えるためのピーチ</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/koala" class="text-reset">🐨 コアラ</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/chimpanzee" class="text-reset">🙈 数字と場所の短期記憶を鍛えるためのチンパンジー</router-link></li>
                            <li class="list-group-item"><router-link to="/duo/random-digits" class="text-reset">🐬 聞いたことの短期記憶を鍛えるためのドルフィン</router-link></li>
                        </ul>            
                    </div>
                </div>
            </div>
        </div>
        <router-view v-else></router-view>
    </div>
</template>

<script>
import Apple from './Apple.vue'
import Bagle from './Bagle.vue'
import Bamboo from './Bamboo.vue'
import Cherry from './Cherry.vue'
import Chimpanzee from './Chimpanzee.vue'
import Doughnut from './Doughnut.vue'
import Elephant from './Elephant.vue'
import Koala from './Koala.vue'
import Peach from './Peach.vue'
import Pelmanism from './Pelmanism.vue'
import Rabbit from './Rabbit.vue'
import Hamster from './Hamster.vue'
import RandomDigits from './RandomDigits.vue'
import Kiwi from './Kiwi.vue'

export default {

}

const duoChildren = [
  { path: 'bamboo', component: Bamboo },
  { path: 'bagle', component: Bagle },
  { path: 'cherry', component: Cherry },
  { path: 'chimpanzee', component: Chimpanzee},
  { path: 'doughnut', component: Doughnut },
  { path: 'elephant', component: Elephant },
  { path: 'peach', component: Peach},
  { path: 'pelmanism', component: Pelmanism },
  { path: 'koala', component: Koala},
  { path: 'rabbit', component: Rabbit },
  { path: 'hamster', component: Hamster },
  { path: 'random-digits', component: RandomDigits },
  { path: 'stone', component: Apple },
  { path: 'kiwi', component: Kiwi },
];
export {duoChildren};
</script>
