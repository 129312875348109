<template>
    <RiverFrame v-bind:config="{correctSymbol: '🍎', helpModal: true, configModal: true, extraLeftAction: true, specialRewardThreshold: 10}" v-on:renew="renew">
        <template v-slot:config>
            <div class="form-group">
                <label for="">下限</label>
                <select class="form-control" name="" id="" v-model.number="objectCountMin">
                    <option v-for="n in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]" v-bind:key="n">{{n}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">上限</label>
                <select class="form-control" name="" id="" v-model.number="objectCountMax">
                    <option v-for="n in [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]" v-bind:key="n">{{n}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="customRange3">りんごを表示する大きさ</label>
                <input type="range" class="custom-range" min="1" max="5" step="0.1" id="customRange3" v-model="objectFontSizeRem">
            </div>
            <div class="platform text-center" v-bind:style="{fontSize: `${objectFontSizeRem}rem`}">
                <span
                    v-for="(col, ci) in [0, 1, 2, 3, 4]" v-bind:key="ci"
                >
                    {{object}}
                </span>
            </div>
        </template>
        <template v-slot:help>
            <p>
                ランダムな個数のリンゴが表示されます
                <ul>
                    <li>子供に何個か答えてもらいます</li>
                    <li>
                        当たっていたら「正解」ボタンを押します
                        <ul>
                            <li>正解数がカウントアップされます</li>
                        </ul>
                    </li>
                    <li>
                        間違っていたら「ミス」ボタンを押します
                        <ul>
                            <li>ミス数がカウントアップされます</li>
                        </ul>
                    </li>
                    <li>「つぎへ」ボタンを押すと、リンゴの個数がランダムに更新されます</li>
                    <li>「設定」ボタンを押すと設定画面が開きます</li>
                </ul>
            </p>
            <h5>設定画面</h5>
            <p>
                表示するりんごの個数の上限と下限を設定出来ます。
            </p>
            <h5>Tips</h5>
            <p>
                スマホを横向きにすると、10個単位で並ぶようになります。
            </p>
            <p>
                「+」ボタンを押すと、押した回数に応じて、赤りんごが追加で表示されます。例えば、13個表示された後に「+」を3回押して合計の数を答えてもらうことで、13+3の考え方に繋げることができます。
            </p>
            <p>
                「-」ボタンを押すと、押した回数に応じて、りんごが梨として表示されます。例えば、17個表示された後に「-」を3回押して減った後のりんごの数を答えてもらうことで、17-3の考え方に繋げることができます。
            </p>
        </template>
        <div class="platform text-center" v-bind:style="{fontSize: `${objectFontSizeRem}rem`}">
            <div v-for="(row, ri) in objects" v-bind:key="ri">
                <span
                    v-for="(col, ci) in row" v-bind:key="ci"
                    v-bind:style="{visibility: col.visibility}"
                >
                    {{col.object}}
                </span>
            </div>
        </div>
        <template v-slot:extra-left-action>
            <div>
                <button type="button" class="btn btn-light btn-lg mx-2" v-on:click="decrease">-</button>
                <button type="button" class="btn btn-light btn-lg mx-2" v-on:click="increase">+</button>
            </div>
        </template>
    </RiverFrame>
</template>

<script>
import Random from "./Random.js";
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },
    data: function() {
        return {
            object: '🍏',
            objectAdditional: '🍎',
            objectSubtractive: '🍐',
            objectCount: 0,
            variation: 0,
            objectCountMin: 1,
            objectCountMax: 20,
            colMax: 5,
            objectFontSizeRem: 3,
        };
    },
    computed: {
        objectCountVaried: function() {
            return this.objectCount + this.variation;
        },
        rowMax: function() {
            const objectCountMaxDisplayed = Math.max(this.objectCountMax, this.objectCountVaried);
            return objectCountMaxDisplayed / this.colMax;
        },
        rowCount: function() {
            return Math.floor(this.objectCount / this.colMax);
        },
        lastRowColCount: function() {
            return this.objectCount % this.colMax;
        },
        objects: function() {
            const objects = [];
            for (let ri = 0; ri < this.rowCount; ri++) {
                const row = this.initRow(this.colMax, this.object, 'visible');
                objects.push(row);
            }
            if (this.lastRowColCount !== 0) {
                const visibleCells = this.initRow(this.lastRowColCount, this.object, 'visible');
                const hiddenCells = this.initRow(this.colMax - this.lastRowColCount, this.object, 'hidden');
                const lastRow = visibleCells.concat(hiddenCells);
                objects.push(lastRow);
            }
            for (let ri = 0; ri < this.rowMax - this.rowCount - (this.lastRowColCount === 0 ? 0 : 1); ri++) {
                const row = this.initRow(this.colMax, this.object, 'hidden');
                objects.push(row);
            }

            for (let ri = 0; ri < objects.length; ri++) {
                const row = objects[ri];
                for (let ci = 0; ci < row.length; ci++) {
                    const index = ri * this.colMax + ci;
                    if (this.objectCount + this.variation <= index && index < this.objectCount) {
                        row[ci] = this.initObject(this.objectSubtractive, 'visible');
                    }
                    if (this.objectCount <= index && index <= (this.objectCount + this.variation - 1)) {
                        row[ci] = this.initObject(this.objectAdditional, 'visible');
                    }
                }
            }
            return objects;
        },
    },
    beforeCreate: function() {
        },
    created: function() {
        this.setUpOrientationDetection();
        this.renew();
    },
    methods: {
        initObject: function(object, visibility) {
            return {object, visibility};
        },
        initRow: function(cellCount, object, visibility) {
            const row = [];
            for (let n = 0; n < cellCount; n++) {
                const cell = this.initObject(object, visibility);
                row.push(cell);
            }
            return row;
        },
        handleOrientationChange: function(evt) {
            if (evt.matches) {
              this.colMax = 5;
            } else {
                this.colMax = 10;
            }
        },
        setUpOrientationDetection: function() {
            var mediaQueryList = window.matchMedia("(orientation: portrait)");
            mediaQueryList.addListener(this.handleOrientationChange);
            this.handleOrientationChange(mediaQueryList);
        },
        renew: function() {
            this.variation = 0;
            const rangeLength = this.objectCountMax - this.objectCountMin + 1;
            const offset = this.objectCountMin;
            const exception = [this.objectCount - offset];
            this.objectCount = Random.randomIntegerExcept(rangeLength, exception) + offset;
        },
        decrease: function() {
            if (1 < this.variation + this.objectCount) {
                 this.variation -= 1;
            }
        },
        increase: function() {
            this.variation += 1;
        }
    }
}
</script>
