<template>
    <div>
        <h3>{{ title }}</h3>
        <ArithmeticEquation v-bind:n0="n0" v-bind:n1="n1" v-bind:operation="operation" v-bind:input="input" v-bind:expected="expected"/>
        <NumberPad v-bind:clear="clearNumPad" v-on:press="judge"/>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal>
            <h6>ひとつめの かずは？</h6>
            <div
                class="form-check form-check-inline"
                v-for="(number, ni) in [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]"
                v-bind:key="'n0' + ni"
            >
                <label class="form-check-label">
                    <input
                        class="form-check-input" type="radio" name="n0config" id="n0config"
                        v-bind:value="number"
                        v-model="n0config"
                    >
                        {{number}}
                </label>
            </div>
            <h6>ふたつめの かずは？</h6>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="n1config" id="n1config" value="in-order" v-model="n1config.mode">
                じゅんばん
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="n1config" id="n1config" value="random" v-model="n1config.mode">
                ランダム
              </label>
            </div>
        </QuizConfigurationModal>

    </div>
</template>

<script>
import Arithmetic from "./Arithmetic.vue"
import ArithmeticEquation from "./ArithmeticEquation.vue"
import NumberPad from "./NumberPad.vue"
import TryAgainButton from "./TryAgainButton.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Random from "./Random.js"


const title = 'この かけざんの けっかは？';
export default {
    title: title,
    name: 'Multiplication',
    mixins: [Arithmetic],
    components: {
        ArithmeticEquation,
        NumberPad,
        QuizConfigurationModal,
        TryAgainButton
    },
    data: function() {
        return {
            title: title,
            operation: '&times;',
            n0config: 0,
            n1config: {
                mode: 'in-order'
            },
            keys: [
                ['<', 7, 8, 9],
                ['.', 4, 5, 6],
                [0, 1, 2, 3]
            ]
        };
    },
    created: function() {
        if (this.n0config !== 0) {
            this.n0 = this.n0config;
        } else {
            this.n0 = Random.randomIntegerExcept(9, [this.n0]) + 11;
        }
        this.renew();
    },
    computed: {
        expected: function() {
            return (this.n0 * this.n1).toString();
        }

    },
    methods: {
        press: function(rowIndex, columnIndex) {

            const symbol = this.keys[rowIndex][columnIndex];
            if (symbol === '<') {
                this.input = this.input.slice(0, -1);
            } else {
                this.input += symbol;
            }
            this.judge();
        },
        renew: function() {
            if (this.n0config !== 0) {
                this.n0 = this.n0config;
            }
            if (this.n1config.mode === 'in-order') {
                this.n1 = this.n1 === 9 ? 2 : this.n1 + 1;
            } else {
                this.n1 = Random.randomIntegerExcept(7, [this.n1]) + 2;
            }
            
            this.background = '';
            this.clearNumPad += 1;
            this.input = '';
        }
    }
}
</script>
