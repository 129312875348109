<template>
    <div class="container">
        <div class="row">
            <h3>ごほうびを せっていする</h3>                
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-6 col-lg-4">
                <div class="card">
                    <img class="card-img-top" v-bind:src="`${publicPath}images/twinkle-stars.png`" alt="twinkle-starts">
                    <div class="card-body">
                        <h4 class="card-title">カラースター</h4>
                        <p class="card-text">
                            <ul>
                                <li>くるくる回る星</li>
                                <li>連続正解数を伸ばすとカラフルな星をゲット</li>
                                <li>144連続正解でカラースターの最高のレッドスター</li>
                            </ul>
                            <button type="button" class="btn btn-primary">えらぶ</button>
                        </p>
                        9,999 download
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-4">
                <div class="card">
                    <img class="card-img-top" src="holder.js/100x180/" alt="">
                    <div class="card-body">
                        <h4 class="card-title">カラースター</h4>
                        <p class="card-text">
                            <ul>
                                <li>くるくる回る星</li>
                                <li>連続正解数を伸ばすとカラフルな星をゲット</li>
                                <li>144連続正解でカラースターの最高のレッドスター</li>
                            </ul>
                            <button type="button" class="btn btn-primary">えらぶ</button>
                        </p>
                        9,999 download
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-4">
                <div class="card">
                    <img class="card-img-top" src="holder.js/100x180/" alt="">
                    <div class="card-body">
                        <h4 class="card-title">ポップハート</h4>
                        <p class="card-text">Text</p>
                        <button type="button" class="btn btn-primary">えらぶ</button>
                        9,999 download
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            publicPath: process.env.BASE_URL,

        };
    }
}
</script>