export default {
    parseWord: function(raw) {
        let [head, body_raw, tail] = raw.split('/');
        let body = body_raw.split('|').map(k => {
            let [kanji, reading_raw] = k.split(',');
            let reading = reading_raw.split(':').map(r => { return { symbol: r}});
            return {
                kanji: kanji,
                reading: reading
            }
        });
        return {
            head: head,
            body: body,
            tail: tail
        };
    }
}