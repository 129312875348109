<template>
    <div>
        <table class="table-borderless">
            <tr v-for="(row, ri) in keys" v-bind:key="'row' + ri">
                <td>
                    <button type="button"
                        class="kbd-key badge badge-pill badge-secondary"
                        v-for="(symbol, ni) in row" v-bind:key="'symbol' + ni"
                        v-on:click="press(symbol)"
                    >
                        {{symbol}}
                    </button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'NumberPad',
    props: ['clear'],
    data: function() {
        return {
            input: '',
            keys: [
                ['<', 7, 8, 9],
                ['.', 4, 5, 6],
                [0, 1, 2, 3]
            ]
        }
    },
    watch: {
        clear: function() {
            this.input = '';
            this.$emit('press', this.input);
        }
    },
    methods: {
        press: function(symbol) {
            if (symbol === '<') {
                this.input = this.input.slice(0, -1);
            } else {
                this.input += symbol;
            }
            this.$emit('press', this.input);
        }
    }
}
</script>

<style scoped>
.kbd-key {
    font-size: 30px;
    width: 60px;
}
</style>