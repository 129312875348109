<template>
    <div>
        得意{{ good }} 苦手{{ bad }} / 全{{ total }}
        <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" v-bind:style="{width: `${percent(good)}%`}" v-bind:aria-valuenow="percent(good)" aria-valuemin="0" aria-valuemax="100">得意</div>
            <div class="progress-bar bg-secondary" role="progressbar" v-bind:style="{width: `${percent(neither)}%`}" v-bind:aria-valuenow="percent(neither)" aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar bg-danger" role="progressbar" v-bind:style="{width: `${percent(bad)}%`}" v-bind:aria-valuenow="percent(bad)" aria-valuemin="0" aria-valuemax="100">苦手</div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props: ['good', 'bad', 'total'],
    computed: {
        neither: function() {
            return this.total - this.good - this.bad;
        }
    },
    methods: {
        percent: function(count) {
            return Math.floor(count / this.total * 100);
        }
    },
})
</script>