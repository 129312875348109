<template>
    <div>
        <div>
            <span
                class="badge badge-success mx-2 my-2"
                v-for="(g, gi) in gone" v-bind:key="'gone' + gi"
            >
            {{g}}
            </span>
        </div>
        <div>
            <button
                type="button"
                v-for="(op, oi) in options" v-bind:key="'option' + oi"
                v-bind:class="['btn', {unused: 'btn-secondary', used: 'btn-light', wrong: 'btn-danger'}[op.status], 'mx-2', 'my-2']"
                v-on:click="judge(oi)"
                v-bind:disabled="op.status === 'used'"
            >
                {{op.value}}
            </button>
        </div>
    </div>
</template>

<script>
import Random from "./Random.js";

export default {
    name: 'SequenceQuiz',
    props: ["optionValues", "correctSequence"],
    data: function() {
        return {
            debug: null,
            options: [],
            gone: [],
        }
    },
    created: function() {
        this.debug = 'created';
        this.renew();
    },
    watch: {
        optionValues: function() {
            this.debug = `optionValues changed: ${this.optionValues}`;
            this.renew();
        }
    },
    methods: {
        renew: function() {
            this.debug = 'renewed';
            this.gone = [];
            this.options = Random.shuffle(this.optionValues).map(op => {
                return {
                    value: op,
                    status: 'unused'
                }
            });
        },
        judge: function(optionIndex) {
            if (this.correctSequence[this.gone.length] === this.options[optionIndex].value) {
                this.options[optionIndex].status = 'used';
                this.gone.push(this.correctSequence[this.gone.length]);
            } else {
                this.options[optionIndex].status = 'wrong';
                setTimeout(() => {
                    this.options[optionIndex].status = 'unused';
                }, 500);
            }
        }
    }
}
</script>