<template>
    <RiverFrame v-bind:config="{correctSymbol: '🐹', configModal: true, extraLeftAction: true, specialRewardThreshold: 10}" v-on:renew="renew" v-on:correct="show_answer()">
        <template v-slot:config>
            <h5>時間の方向</h5>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="deviation_direction" id="deviation_direction" v-model="deviation_direction_config" value="random"> ランダム
                    <input class="form-check-input" type="radio" name="deviation_direction" id="deviation_direction" v-model="deviation_direction_config" value="past"> まえ
                    <input class="form-check-input" type="radio" name="deviation_direction" id="deviation_direction" v-model="deviation_direction_config" value="future"> あと
                </label>
            </div>
        </template>
        <div class="x">
            <div>
                
                <span class="noon">{{noon_origin}}</span><span class="two-digits hour-digit">{{hour12_origin}}</span>時<span class="two-digits">{{minute_origin}}</span>分の
            </div>
            <div>
                <span class="two-digits">{{deviation_minute}}</span>分
                <span v-if="deviation_direction === 'past'">まえ</span>
                <span v-else>あと</span>
                は
            </div>
            <div>
                <span class="noon" v-bind:style="{visibility: answer_visible ? 'visible' : 'hidden'}">{{noon_destination}}</span><span class="two-digits hour-digit" v-bind:style="{visibility: answer_visible ? 'visible' : 'hidden'}">{{hour12_destination}}</span>時<span class="two-digits" v-bind:style="{visibility: answer_visible ? 'visible' : 'hidden'}">{{minute_destination}}</span>分
            </div>
        </div>
        <template v-slot:extra-left-action>
            <button type="button" class="btn btn-light" v-on:click="show_answer">こたえをみる</button>
        </template>
    </RiverFrame>
</template>

<style scoped>
.x {
    font-size: 2.4rem;
}
.noon {
    display: inline-block;
    width: 3em;
}
.two-digits {
    display: inline-block;
    width: 1.5em;
    text-align: right;
}
.hour-digit {
    width: 1.2em;
}
</style>

<script>
import Random from "./Random.js";
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },    
    data: function() {
        return {
            hour_origin: undefined,
            minute_origin: undefined,
            hour12_origin: undefined,
            noon_origin: undefined,
            origin: undefined,

            deviation_minute: undefined,
            deviation_direction_config: "future",
            deviation_direction: undefined,
            deviation: undefined,

            destination: undefined,
            hour_destination: undefined,
            minute_destination: undefined,
            hour12_destination: undefined,
            noon_destination: undefined,

            answer_visible: undefined,
        };
    },
    mounted: function() {
        this.renew();
    },
    methods : {
        renew: function () {
            this.hour_origin = Random.randomInteger(12);
            this.minute_origin = Random.randomInteger(60);
            this.hour12_origin = this.hour12(this.hour_origin);
            this.noon_origin = this.noon12(this.hour_origin);
            this.origin = new Date(1995, 1, 1, this.hour_origin, this.minute_origin, 0);

            this.deviation_minute = Random.randomInteger(59) + 1;
            this.deviation_direction = this.deviation_direction_config === "random" ? ["past", "future"][Random.randomInteger(2)] : this.deviation_direction_config;
            this.deviation = 1000 * 60 * this.deviation_minute * (this.deviation_direction === "future" ? 1 : -1);

            this.destination = new Date(this.origin.getTime() + this.deviation);
            this.hour_destination = this.destination.getHours();
            this.minute_destination = this.destination.getMinutes();
            this.hour12_destination = this.hour12(this.hour_destination);
            this.noon_destination = this.noon12(this.hour_destination);

            this.answer_visible = false;
        },
        hour12: function (h) {
            return h % 12;
        },
        noon12: function (h) {
            return h / 12 < 1 ? "ごぜん" : "ごご";
        },
        show_answer: function() {
            this.answer_visible = true;
        }
    }
}
</script>