<template>
    <div>
        <h6>{{title}}</h6>
        <div class="original">{{decrarative.join(" ")}}</div>
        <SequenceQuiz v-bind:correctSequence="correctPhrase" v-bind:optionValues="options" />
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import EnglishSentencePattern from "./EnglishSentencePattern.js";
import SequenceQuiz from "./SequenceQuiz.vue";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";

const title = 'じょどうし';
const name = 'AuxiliaryVerb';
export default {
    name: name,
    title: title,
    data: function() {
        return {
            decrarative: [],
            auxiliaryVerbal: [],
            correctPhrase: [],
            options: [],
            title: title,
            correct: false,
            total: 0,
            patterns: [
                {s: ["You", "He", "She", "They"], a: ['can', 'should', 'must', 'may'], v: ["speak", "speaks"], o: ["English", "French", "Japanese"]},
                {s: ["I", "He", "She", "We", "They"], a: ['will', 'may'], v: ["need", "needs"], o: ["this", "your help"]},
                // {s: ["You", "He", "She"], a: ['can', 'will', 'should', 'must', 'may'], v: ["listen", "listens"], o: ["to jazz", "to music everyday"]},
                {s: ["You", "He", "She"], a: ['can', 'should', 'must', 'may'], v: ["study", "studies"], o: ["English", "history"]},
                // {s: ["I", "You", "He", "She"], a: ['can', 'will', 'should', 'must', 'may'], v: ["have", "has"], o: ["to go there", "to do this", "a real friend"]},
                {s: ["You", "He", "She"], a: ['will', 'should', 'must', 'may'], v: ["like", "likes"], o: ["Italian food", "dogs", "cats", "playing basketball", "to travel by air"]},
                {s: ["You", "He", "She"], a: ['can', 'will', 'should', 'must', 'may'], v: ["drink", "drinks"], o: ["coffee", "milk"]},
                {s: ["You", "He", "She"], a: ['can', 'will', 'should', 'must', 'may'], v: ["have", "has"], o: ["a sister", "a brother", "a car", "some money"]},
                {s: ["You", "He", "She"], a: ['can', 'will', 'should', 'must', 'may'], v: ["believe", "believes"], o: ["me"]},
                {s: ["You"], a: ['can', 'will', 'should', 'must', 'may'], v: ["hear"], o: ["me"]},
            ]
        };
    },
    components: {
        SequenceQuiz,
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            const pi = Random.randomInteger(this.patterns.length);
            const pattern = new EnglishSentencePattern(this.patterns[pi]);
            this.decrarative = pattern.randomDecrarative();
            this.auxiliaryVerbal = pattern.randomAuxiliaryVerbal();
            this.correctPhrase = this.auxiliaryVerbal;
            var options = this.auxiliaryVerbal.slice();
            Random.shuffle(options);
            this.options = options;

        }
    }
}
</script>

<style scoped>
.original {
    font-size: 2rem;
}
</style>