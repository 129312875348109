<template>
    <div>
        <h6>{{title}}</h6>
        <div class="deck">
            <span v-for="(c, ci) in count" v-bind:key="ci">
                {{object.symbol}}
            </span>
        </div>
        <SequenceQuiz v-bind:correctSequence="correctPhrase" v-bind:optionValues="options" />
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";
import SequenceQuiz from "./SequenceQuiz.vue";

const title = 'いくつの なに？';
const name = 'EnglishNoun';
export default {
    name: name,
    title: title,
    components: {
        SequenceQuiz,
        TryAgainButton,
        Achievement
    },
    data: function() {
        return {
            title: title,
            objectIndex: -1,
            object: {name:'orange', symbol: '🍊'},
            count: 2,
            seeds: {
                count: [1, 2, 3, 4],
                objects: [
                    {name: 'airplane', plural: 'airplanes', symbol: '🛩', vowl: true},
                    {name: 'apple', plural: 'apples', symbol: '🍅', vowl: true},
                    {name: 'eagle', plural: 'eagles', symbol: '🦅', vowl: true},
                    {name: 'ant', plural: 'ants', symbol: '🐜', vowl: true},
                    {name: 'elephant', plural: 'elephants', symbol: '🐘', vowl: true},
                    {name: 'egg', plural: 'eggs', symbol: '🥚', vowl: true},
                    {name: 'anchor', plural: 'anchors', symbol: '⚓️', vowl: true},
                    {name: 'ambulance', plural: 'ambulances', symbol: '🚑', vowl: true},
                    {name: 'milk', plural: '', symbol: '🥛', counter: 'glass', counter_plural: 'glasses'},
                    {name: 'coffee', plural: '', symbol: '☕️', counter: 'cup', counter_plural: 'cups'},
                    {name: 'scissors', plural: '', symbol: '✂️', counter: 'pair', counter_plural: 'pairs'},
                    {name: 'glasses', plural: '', symbol: '👓', counter: 'pair', counter_plural: 'pairs'},
                    {name: 'pants', plural: '', symbol: '👖', counter: 'pair', counter_plural: 'pairs'},
                    {name: 'socks', plural: '', symbol: '🧦', counter: 'pair', counter_plural: 'pairs'},
                    {name: 'gloves', plural: '', symbol: '🧤', counter: 'pair', counter_plural: 'pairs'},
                    {name: 'chipsticks', plural: '', symbol: '🥢', counter: 'pair', counter_plural: 'pairs'},
                    {name: 'bread', plural: '', symbol: '🍞', counter: 'loaf', counter_plural: 'loaves'},
                    {name: 'paper', plural: '', symbol: '📃', counter: 'sheet', counter_plural: 'sheets'},
                    {name: 'cake', plural: '', symbol: '🍰', counter: 'piece', counter_plural: 'pieces'},
                    {name: 'pizza', plural: '', symbol: '🍕', counter: 'slice', counter_plural: 'slices'},
                    {name: 'car', plural: 'cars', symbol: '🚗'},
                    {name: 'snake', plural: 'snakes', symbol: '🐍'},
                    {name: 'zebra', plural: 'zebras', symbol: '🦓'},
                    {name: 'duck', plural: 'ducks', symbol: '🦆'},
                    {name: 'box', plural: 'boxes', symbol: '🗃️'},
                    {name: 'baby', plural: 'babies', symbol: '👶'},
                    {name: 'man', plural: 'men', symbol: '👨', irregular: true, if_regular: 'mans'},
                    {name: 'woman', plural: 'women', symbol: '👩', irregular: true, if_regular: 'womans'},
                    {name: 'child', plural: 'children', symbol: '🧒🏻', irregular: true, if_regular: 'childs'},
                    {name: 'wolf', plural: 'wolves', symbol: '🐺', irregular: true, if_regular: 'wolfs'},
                    {name: 'mouse', plural: 'mice', symbol: '🐁', irregular: true, if_regular: 'mouses'},
                    {name: 'tooth', plural: 'teeth', symbol: '🦷', irregular: true, if_regular: 'tooths'},
                    {name: 'leaf', plural: 'leaves', symbol: '🍁', irregular: true, if_regular: 'leafs'},
                    {name: 'ox', plural: 'oxen', symbol: '🐂', irregular: true, if_regular: 'oxes', vowl: true},
                    {name: 'knife', plural: 'knives', symbol: '🔪', irregular: true, if_regular: 'knifes'},
                    {name: 'sheep', plural: 'sheep', symbol: '🐑', unchanged_plural: true, if_regular: 'sheeps'},
                    {name: 'deer', plural: 'deer', symbol: '🦌', unchanged_plural: true, if_regular: 'deers'},
                    {name: 'fish', plural: 'fish', symbol: '🐟', unchanged_plural: true, if_regular: 'fishes'},
                ]
            },
            options: [],
            correctPhrase: [],
        };
    },
    created: function() {
        this.renew();
    },
    methods: {
        numericalWord: function(number) {
            return ['', '', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'][number];
        },
        generateCorrectPhrase: function() {
            let phrase = [];
            if (this.count === 1) {
                phrase.push(this.object.vowl ? 'an' : 'a');
            } else {
                phrase.push(this.numericalWord(this.count));
            }
            const object = this.object;
            if (object.plural === '') {
                const counter = this.count === 1 ? object.counter : object.counter_plural;
                phrase.push(counter);
                phrase.push('of');
                phrase.push(object.name);
            } else {
                phrase.push(this.count === 1 ? object.name : object.plural);
            }
            return phrase;
        },
        renew: function() {
            this.count = Random.randomInteger(3) + 1;
            this.objectIndex = Random.randomIntegerExcept(this.seeds.objects.length, [this.objectIndex]);
            let options = [];
            // const object = {name: 'apple', plural: 'apples', symbol: '🍅', vowl: true};
            // const object = {name: 'fish', plural: 'fish', symbol: '🐟', unchanged_plural: true, if_regular: 'fishes'};
            // const object = {name: 'child', plural: 'children', symbol: '🧒🏻', irregular: true, if_regular: 'childs'};
            // const object = {name: 'bread', plural: '', symbol: '🍞', counter: 'loaf', counter_plural: 'loaves'};
            // const object = {name: 'box', plural: 'boxes', symbol: '🗃️'};
            const object = this.seeds.objects[this.objectIndex];
            options.push(object.name);

            if (object.unchanged_plural) {
                options.push(object.if_regular);
            } else if (object.irregular) {
                options.push(object.if_regular);
                options.push(object.plural);
            } else if (object.plural === '') {
                options.push(object.counter);
                options.push(object.counter_plural);
                options.push('of');                
            } else {
                options.push(object.plural);
            }

            if (this.count === 1) {
                options.push('an');
                options.push('a');
            } else {
                const number = this.numericalWord(this.count);
                options.push(number);
            }
            this.object = object;
            this.options = options;
            this.correctPhrase = this.generateCorrectPhrase();
        }
    }
}
</script>

<style scoped>
.deck {
    font-size: 3em;
}
</style>