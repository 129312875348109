import Random from "./Random.js"

export default class RandomIndexPicker {
    currentIndex = -1;
    list = null;
    constructor(list) {
        this.list = list;
    }
    next() {
        this.currentIndex = Random.randomIntegerExcept(this.list.length, [this.currentIndex]);
        return this.currentIndex;
    }
}