<template>
    <div class="container">
        <div class="row">
            <div
                class="col-xs-12 col-md-6 col-lg-4"
                v-for="category in quizzes" v-bind:key="category"
            >
                <div
                    class="card m-2"
                >
                    <div class="card-body">
                        <h4 class="card-title">{{category.category}}</h4>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li
                            class="list-group-item"
                            v-for="quiz in category.quizzes" v-bind:key="quiz.name"
                        >
                            <router-link v-bind:to="`/solo/${camelToKebab(quiz.name)}`" class="text-reset">{{quiz.title}}</router-link>
                        </li>
                    </ul>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['quizzes']
}
</script>