<template>
    <div>
        <div class="pelmanism-card-deck">
            <span
                v-for="card in deck" v-bind:key="card_id(card)"
                v-bind:class="['pelmanism-card', 'badge', card.collected ? 'badge-light' : 'badge-secondary', 'mx-1']"
                v-on:click="open(card)"
            >
                <div v-bind:style="{visibility: card.visible ? 'visible' : 'hidden'}">
                    {{ card.word }}
                </div>
            </span>
            <span class="badge badge-light"></span>
        </div>
        <button type="button" class="btn btn-warning mx-3" v-on:click="close">NG => 戻す</button>
        <button type="button" class="btn btn-success mx-3" v-on:click="collect">OK => 回収</button>
        <div
            v-for="(collected, ci) in collectedCards" v-bind:key="ci"
            class="card my-3">
            <div class="card-body">
                <h4 class="card-title">プレーヤー{{ci + 1}}</h4>
                <p class="card-text">
                    回収したカード: <span
                        class="badge badge-secondary mx-1"
                        v-for="card in collected" v-bind:key="card_id(card)"
                    >
                        {{ card.word }}
                    </span>
                </p>
            </div>
        </div>
        <button type="button" class="btn btn-primary mx-3" v-on:click="renewDeck">つぎのゲーム</button>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Random from "./Random.js";

export default Vue.extend({
    data: function() {
        return {
            sentences: [
                'つくえ/を/並べる',
                'つくえ/の/上/に/皿/を/並べる',
                '引き出し/から/皿/を/出す',
                '引き出し/に/皿/を/しまう',
                'お父さん/に/皿/を/渡す',
                '台所/に/皿/を/持って行く',
                '学校/に/行く',
                '学校/から/公園/に/行く'
            ],
            cards: [],
            deck: [],
            openCards: [],
            collectedCards: [[], []],
            turn: 0,
        };
    },
    created: function() {
        this.renewDeck();
    },
    methods: {
        card_id: function(card) {
            return `${card.sentence_id}-${card.word_id}`;
        },
        x: function() {
            this.cards = [];
            for (let si = 0; si < this.sentences.length; si++) {
                const sentence = this.sentences[si];
                const words = sentence.split('/').map((word, wi) => {
                    return {
                        word: word,
                        sentence_id: si,
                        word_id: wi,
                        visible: false,
                        collected: false,
                    };
                })
                this.cards.push(words);
            }
        },
        pickSentences: function() {
            const amount = 3;
            return Random.shuffle(this.cards).slice(0, amount);
        },
        renewDeck: function() {
            this.x();
            const sentences = this.pickSentences();
            const cards = sentences.flat();
            Random.shuffle(cards);
            this.deck = cards;
            this.openCards = [];
            this.collectedCards = [[], []];
        },
        open: function(card) {
            if (! card.collected) {
                card.visible = true;
                this.openCards.push(card);
            }
        },
        close: function() {
            this.openCards.forEach(card => {
                card.visible = false;
            });
            this.openCards = [];
            this.turn = this.turn === 0 ? 1 : 0;
        },
        collect: function() {
            this.openCards.forEach(card => {
                card.collected = true;
                card.visible = false;
            });
            this.collectedCards[this.turn] = this.collectedCards[this.turn].concat(this.openCards);
            this.openCards = [];
            this.turn = this.turn === 0 ? 1 : 0;
        },
    },
})
</script>

<style scoped>
.pelmanism-card-deck {
    font-size: 3rem;
}
</style>