<template>
    <Sequence v-bind:sequences="sequences" />
</template>

<script>
import Sequence from "./Sequence.vue"

export default {
    name: 'EnglishSequence',
    title: "この えいごを じゅんばんに ならべると？",
    components: {
        Sequence
    },
    data: function() {
        return {
            sequences: [
                {
                    elements: ['Sunday',  'Monday',  'Tuesday',  'Wednesday',  'Thursday',  'Friday',  'Saturday']
                },
                {
                    elements: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                },
                {
                    elements: ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'seventeen', 'eighteen', 'nineteen', 'twenty']
                },
                {
                    elements: ['ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
                },
                {
                    elements: ['one', 'ten', 'hundred', 'one thousand', 'ten thousand', 'hundred thousand', 'one million', 'ten million', 'hundred million', 'one billion']
                },
                {
                    elements: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth']
                },
                {
                    elements: ['Mercury', 'Venus', 'Earth', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune', 'Pluto']
                },
                {
                    elements: ['second', 'minute', 'hour', 'day', 'month', 'year']
                },
            ]
        };
    }
}
</script>