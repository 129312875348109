import Random from "./Random.js";

export default class {
    constructor(pattern) {
        this.pattern = pattern;
        this.s = this.pattern.s[Random.randomInteger(this.pattern.s.length)];
        this.thirdSingle = ["She", "He"].includes(this.s);
        this.v = this.pattern.v[this.thirdSingle ? 1 : 0];
        const oi = Random.randomInteger(this.pattern.o.length);
        this.o = this.pattern.o[oi].split(" ");
    }

    randomDecrarative () {
        const decrarative = [this.s, this.v].concat(this.o).concat(".");
        return decrarative;
    }

    randomInterrogative() {
        const interrogative = [this.thirdSingle ? "Does" : "Do", this.s === 'I' ? 'I' : this.s.toLowerCase(), this.pattern.v[0]].concat(this.o).concat(["?"]);
        return interrogative;
    }

    randomAuxiliaryVerbal() {
        const ai = Random.randomInteger(this.pattern.a.length);
        const auxiliaryVerbal = [this.s, this.pattern.a[ai], this.pattern.v[0]].concat(this.o).concat(".");
        return auxiliaryVerbal;
    }
}
