<template>
    <div>
        <h2 class="pt-3">はこは いくつ あるかな？</h2>
        <h3 class="mt-0">からの はこは {{ target }}こ あるよ</h3>
        <NumberBoxes v-bind:numbers="numbers"/>
        <div class="deck mt-3">
            <span
                v-for="(option, index) in options"
                v-bind:key="option.value"
                v-on:click="mark(index)"
                v-bind:class="['badge', option.badgeClass, 'mx-1', 'mt-0 mb-1']"
            >
                {{ option.value }}
            </span>
        </div>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal>
            <h6>からの はこの かず</h6>
            <div
                v-for="n in targetOptions"
                v-bind:key="n"
                class="form-check form-check-inline">
                <label class="form-check-label">
                    <input
                        v-model="target"
                        class="form-check-input" type="radio" name="target" id="target" v-bind:value="n">{{ n }}
                </label>
            </div>
        </QuizConfigurationModal>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import NumberBoxes from "./NumberBoxes.vue"
import TryAgainButton from "./TryAgainButton.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Achievement from "./Achievement.vue"

const title = "はこは いくつ あるかな？"
const name = 'CountRest'
export default {
    name: name,
    title: title,
    components: {
        NumberBoxes,
        TryAgainButton,
        QuizConfigurationModal,
        Achievement
    },
    data: function() {
        return {
            numbers: [],
            options: [],
            target: 3,
            targetOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            correctIndex: 0,
            correct: false,
            total: 0
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        pickOffset: function() {
            return this.box_count - this.target;
        },
        pickBoxCount: function() {
            return Random.randomInteger(20 - this.target) + this.target + 1;
        },
        renew: function() {
            this.box_count = this.pickBoxCount();
            this.offset = this.pickOffset();
            this.numbers = [...Array(this.box_count).keys()].map(n => {
                let value = n + 1;
                return {
                    value: value,
                    class: {odd: value % 2 === 1, even: value % 2 === 0},
                    visibility: value <= this.offset
                };
            });
            this.correctIndex = this.randomInteger(3);
            this.options = [this.box_count, this.box_count + 1, this.box_count + 2].map(n => {
                const value = n - this.correctIndex;
                return {
                    value: '' + value + 'こ',
                    badgeClass: 'badge-secondary'
                }
            });
        },
        mark: function(index) {
            this.correct = index === this.correctIndex;
            this.options[index].badgeClass = this.correct ? 'badge-success' : 'badge-danger';
            this.total += 1;
        }
    }
}
</script>

<style scoped>
.badge {
    font-size: 24px;
}
img {
    height: 2rem;
    width: 2rem;
}
</style>