<template>
    <div>
        <h2 class="py-3">{{ title }}</h2>
        <table>
            <tr>
                <td
                    v-for="(number, index) in numbers"
                    v-bind:key="index"
                    v-bind:class="number.class"
                    v-bind:style="number.style"
                    v-on:click="toggleVisibility(index)"
                >
                    <span v-show="number.visibility">{{ number.value }}</span>
                    
                </td>
            </tr>
        </table>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal>
            <h6>しゅるい</h6>
            <div class="form-check">
            <input class="form-check-input" type="radio" name="type-radio" id="type-radio-full-random" value="full-random" v-model="type">
            <label class="form-check-label" for="type-radio-full-random">
                かんぜん ランダム
            </label>
            </div>
            <div class="form-check">
            <input class="form-check-input" type="radio" name="type-radio" id="type-radio-carry-up" value="carry-up" v-model="type">
            <label class="form-check-label" for="type-radio-carry-up">
                くりあがり じゅうし
            </label>
            </div>
            <h6>れんしゅうしたい くりあがりを えらぶ</h6>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="10"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    10
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="20"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    20
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="30"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    30
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="40"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    40
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="50"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    50
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="60"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    60
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="70"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    70
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox"
                    value="80"
                    v-model.number="targets"
                    id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    80
                </label>
            </div>
        </QuizConfigurationModal>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"

const title = "あいだの すうじを ぜんぶ いってみよう！"
const name = 'CountAllNumbersInBetween'
export default {
    name: name,
    title: title,
    components: {
        TryAgainButton,
        QuizConfigurationModal,
    },
    data: function() {
        return {
            title: title,
            name: name,
            type: "full-random",
            targets: [10, 20, 30, 40],
            offset: 1,
            box_count: 20,
            numbers: [],
            configVisible: false
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        toggleVisibility: function (index) {
            let number = {
                value: this.numbers[index].value,
                class: this.numbers[index].class,
                visibility: true
            }
            this.numbers.splice(index, 1, number);
        },
        pickOffset: function() {
            if (this.type === 'full-random') {
                return Random.randomInteger(16) + 1;
            } else if (this.type === 'carry-up') {
                const index = Random.randomInteger(this.targets.length);
                return this.targets[index] - 3;
            }
        },
        pickBoxCount: function() {
            if (this.type === 'full-random') {
                const box_max = 16 - this.offset;
                return Random.randomInteger(box_max) + 4;
            } else if (this.type === 'carry-up') {
                return 6;
            }
        },
        renew: function() {
            this.offset = this.pickOffset();
            this.box_count = this.pickBoxCount();
            this.numbers = [...Array(this.box_count).keys()].map(n => {
                let value = n + this.offset;
                return {
                    value: value,
                    class: {odd: value % 2 === 1, even: value % 2 === 0},
                    visibility: value === this.offset || value === this.offset + this.box_count - 1
                };
            });
        },
        configure: function() {
            this.configVisible = true;
        }
    }    
}
</script>

<style scoped>
img {
    height: 2rem;
    width: 2rem;
}
</style>