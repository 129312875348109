<template>
    <div>
        <div class="row">
            <div class="col-6 score">
                <span class="badge badge-light w-100"><span id="reward-correct" class="reward">{{correctSymbol}}</span><span style="visibility: hidden">🍒</span> {{ correctCount }}</span>
            </div>
            <div class="col-6 score">
                <span class="badge badge-light w-100"><span id="reward-miss" class="reward">😥</span><span style="visibility: hidden">😥</span> {{missCount }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button type="button" class="btn btn-outline-secondary w-100" v-on:click="reflectJudge('correct')">正解</button>
            </div>
            <div class="col-6">
                <button type="button" class="btn btn-outline-secondary w-100" v-on:click="reflectJudge('miss')">ミス</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['correctSymbol', 'correctCount', 'missCount'],
    methods: {
        reflectJudge: function (judge) {
            this.$emit(judge);
            this.status = judge;
        },
        handleCorrect: function() {
            this.bounceReward('correct');
            this.status = 'correct';
        },
        handleMiss: function() {
            this.bounceReward('miss');
            this.status = 'miss';
        },
        animateCSS: function(element, animationName, callback) {
            const node = document.querySelector(element)
            node.classList.add('animated', animationName)

            function handleAnimationEnd() {
                node.classList.remove('animated', animationName)
                node.removeEventListener('animationend', handleAnimationEnd)

                if (typeof callback === 'function') callback()
            }

            node.addEventListener('animationend', handleAnimationEnd)
        },
        bounceReward: function(judge) {
            this.animateCSS(`#reward-${judge}`, 'bounceInDown', () => {
                this.$emit(judge);
            });
        },
    },
}
</script>

<style scoped>
.score {
    font-size: 3rem;
}
.reward {
    position: absolute;
}
</style>
