<template>
    <RiverFrame v-bind:config="{correctSymbol: '🐘', configModal: true, extraLeftAction: true, specialRewardThreshold: 10}" v-on:renew="renew" v-on:correct="show_answer()">
        <template v-slot:config>
            <h5>出現する単位</h5>
            <div v-for="part in parts" v-bind:key="part.unit" class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" name="config-unit" v-bind:id="`config-unit-${part.unit}`" v-model="part.use">
                {{part.unit}}
              </label>
            </div>
            <h5>モード</h5>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="mode" id="mode-single" v-model="mode" value="single"> 選んだ単位のどれか一つ
                </label>
            </div>
            <div class="form-check form-check-inline">
                <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="mode" id="mode-combination" v-model="mode" value="combination"> 選んだ単位の組み合わせ
                </label>
            </div>
        </template>
        <span v-for="part in parts" v-bind:key="part.unit" >
            <span v-if="part.display">
                <span class="x">{{part.value}}</span>
                <span class="unit">{{part.unit}}</span>
            </span>
        </span>
    </RiverFrame>
</template>

<style scoped>
.x {
    font-size: 3rem;
    margin-right: 0.2rem;
}
.unit {
    font-size: 2.5rem;
    margin-right: 0.3rem;
}
</style>

<script>
import Random from "./Random.js";
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },    
    data: function() {
        return {
            mode: "single",
            parts: [
                {
                    unit: 'm',
                    value: 34,
                    use: true,
                    display: true
                },
                {
                    unit: 'cm',
                    value: 34,
                    use: true,
                    display: true
                },
                {
                    unit: 'mm',
                    value: 34,
                    use: true,
                    display: true
                },
            ]
        };
    },
    computed: {
        selectedUnitIndexes: function () {
            return this.length.map((x, i) => { i }).filter((i) => this.length[i].use);
        },
    },
    mounted: function() {
        this.renew();
    },
    methods : {
        renew: function () {
            let selectedUnitIndexes = this.parts.reduce((a, x, i) => x.use ? [...a, i] : a, []);
            let n = this.mode === 'single' ? 1 : Random.randomInteger(2) + 2;
            let unitIndexesToDisplay = Random.sample(selectedUnitIndexes.length, n).map(x => selectedUnitIndexes[x]);
            this.parts.forEach((part, i) => {
                let max = 1 < n && part.unit === 'mm' ? 10 : 100
                part.value = Random.randomInteger(max - 1) + 1;
                part.display = unitIndexesToDisplay.indexOf(i) !== -1;
            });
        },
    }
}
</script>