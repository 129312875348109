<template>
    <form>
        <div class="form-group">
          <label for="title">もんだいのタイトル</label>
          <input type="text"
            class="form-control" name="title" id="title" aria-describedby="titleTextDescription"
            placeholder="これの 〇〇は なにかな？"
            v-model="title"
          >
          <small id="titleTextDescription" class="form-text text-muted">もんだいの ぶんとして ひょうじされるよ</small>
        </div>
        <div class="form-group">
          <label for="name">なまえ</label>
          <input
            type="text" class="form-control" name="name" id="name" aria-describedby="nameHelp" placeholder="CountryLocation, NationalFlag"
            v-model="name"
          >
          <small id="nameHelp" class="form-text text-muted">半角英数のみ、一文字目は半角英字のみ</small>
        </div>
        カテゴリー
        <div
            v-for="cat in categories" v-bind:key="cat.id"
            class="form-check form-check-inline"
        >
            <label class="form-check-label">
                <input
                    class="form-check-input" type="radio" name="cat" v-bind:id="`cat-${cat.id}`" v-bind:value="cat.id"
                    v-model="category"
                >{{cat.description}}
            </label>
        </div>
        <div class="form-group">
          <label for="questions">もんだいと こたえ</label>
          <textarea class="form-control" name="questions" id="questions" rows="10"
            placeholder="もんだいと こたえと タグを タブで くぎって、 もんだいごとに かいぎょうしてね。タグは なくてもよいし ふたついじょうでも OKだよ"
            v-model="questions_raw"
          ></textarea>
        </div>
        <button
            type="button"
            class="btn btn-secondary mx-2"
            v-on:click="validate"
        >もんだいの けいしきを かくにんする</button>
        <button type="button" class="btn btn-primary mx-2" v-on:click="send">おくる</button>
        {{ status }}
        <table class="table table-bordered">
            <tr>
                <th>もんだい</th>
                <th>こたえ</th>
                <th>タグ</th>
            </tr>
            <tr
                v-for="q in questions"
                v-bind:key="q.question"
            >
                <td>{{ q.question }}</td>
                <td>{{ q.answer }}</td>
                <td>
                    <span v-for="tag in q.tags" v-bind:key="tag"
                     class="badge badge-pill badge-primary">{{ tag }}</span>
                </td>
            </tr>
        </table>
    </form>
</template>

<script>
import Material from "./Material.js"

export default {
    data: function() {
        return {
            status: "waiting...",
            categories: [
                { id: 'math', description: 'すうじ' },
                { id: 'charactor', description: 'もじ' },
                { id: 'world', description: 'せかい' },
                { id: 'science', description: 'ものの しくみ' },
                { id: 'english', description: 'えいご' },
                { id: 'japanese', description: 'こくご' },
            ],
            title: "",
            name: "",
            category: "",
            questions_raw: "",
            questions: []
        }
    },
    methods: {
        parse: function(questions_raw) {
            return questions_raw.split(/\r?\n/).map(line => {
                let elements = line.split(/\t/);
                return {
                    question: elements[0],
                    answer: elements[1],
                    tags: elements.slice(2)
                };            
            })
        },
        validate: function() {
            this.questions = this.parse(this.questions_raw);
        },
        send: function() {
            this.status = "sending...";
            Material.pushFirebase({
                title: this.title,
                name: this.name,
                category: this.category,
                questions: this.questions
            })
        }
    }
}
</script>