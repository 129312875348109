<template>
    <RiverFrame v-bind:config="{correctSymbol: '🥯', configModal: true, helpModal: false, extraLeftAction: false, specialRewardThreshold: 20}" v-on:renew="renew" v-on:correct="reflectResult('correct')" v-on:miss="reflectResult('miss')">
        <template v-slot:config>
            <h5>モード</h5>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="mode-m-cm" id="mode-m-cm" value="m+cm" v-model="mode">
                X m X cm + X m X cm
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="mode-cm-cm" id="mode-cm-cm" value="cm+cm" v-model="mode">
                X cm + X cm
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="mode-cm-mm" id="mode-cm-mm" value="cm+-mm" v-model="mode">
                X cm X mm {+,-} X cm X mm
              </label>
            </div>
        </template>
        <div v-if="mode === 'cm+cm'" class="x">
            <span>
                <RandomAmount min='35' max='75' unit='cm' v-bind:renewal="renewal" v-on:reflect="mm1 = $event"/>
                +
                <RandomAmount v-bind:min='35' max='75' unit='cm' v-bind:renewal="renewal" v-on:reflect="mm2 = $event"/>
            </span>
            = ?
        </div>
        <div v-else-if="mode === 'm+cm'" class="x">
            <span>
                <RandomAmount min='1' max='9' unit='m' v-bind:renewal="renewal" v-on:reflect="cm1 = $event"/>
                <RandomAmount min='1' max='99' unit='cm' v-bind:renewal="renewal" v-on:reflect="mm1 = $event"/>
                +
                <RandomAmount min='1' max='9' unit='m' v-bind:renewal="renewal" v-on:reflect="cm2 = $event"/>
                <RandomAmount v-bind:min='0' max='9' unit='cm' v-bind:renewal="renewal" v-on:reflect="mm2 = $event"/>
            </span>
            = ?
        </div>
        <div v-else class="x">
            <RandomAmount min='1' max='19' v-bind:renewal="renewal" v-on:reflect="cm1 = $event"/>cm
            <RandomAmount min='1' max='9' v-bind:renewal="renewal" v-on:reflect="mm1 = $event"/>mm
            {{sign}}
            <span v-if="sign === '+'">
                <RandomAmount min='0' max='19' unit='cm' v-bind:renewal="renewal" v-on:reflect="cm2 = $event"/>
                <RandomAmount v-bind:min='cm1 === 0 ? 1 : 0' max='9' unit='mm' v-bind:renewal="renewal" v-on:reflect="mm2 = $event"/>
            </span>
            <span v-else>
                <RandomAmount min='0' v-bind:max='cm1 - 1' unit='cm' v-bind:renewal="renewal" v-on:reflect="cm2 = $event"/>
                <RandomAmount v-bind:min='cm2 === 0 ? 1 : 0' max='9' unit='mm' v-bind:renewal="renewal" v-on:reflect="mm2 = $event"/>
            </span>
            = ?
        </div>
    </RiverFrame>
</template>

<script>
import Random from "./Random.js";
import RandomAmount from './RandomAmount.vue';
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {RandomAmount, RiverFrame},
    data: function () {
        return {
            mode: 'm+cm',
            cm1: 0,
            mm1: 0,
            cm2: 0,
            mm2: 0,
            renewal: 0,
            sign: '+'
        }
    },
    created: function () {
        this.renew();
    },
    methods: {
        renew: function () {
            this.renewal++;
            this.sign = Random.randomInteger(2) % 2 === 0 ? '+' : '-'
        },
    }
}
</script>

<style scoped>
.x {
    font-size: 1.7rem;
}
</style>