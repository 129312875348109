<template>
    <div>
    <table>
        <tr>
            <td
                v-for="(number, index) in numbers"
                v-bind:key="index"
                v-bind:class="number.class"
                v-bind:style="number.style"
                v-on:click="toggleVisibility(index)"
            >
                <span v-show="visibilities[index]">{{ number.value }}</span>
                
            </td>
        </tr>
    </table>
</div>
</template>

<script>
export default {
    props: ['numbers'],
    data: function() {
        return {
            visibilities: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
        };
    },
    created: function() {
            this.visibilities = this.numbers.map(n => n.visibility);        
    },
    watch: {
        numbers: function(numbers) {
            this.visibilities = numbers.map(n => n.visibility);
        }
    },
    methods: {
        toggleVisibility: function (index) {
            this.visibilities.splice(index, 1, true);
        },

    }
}
</script>

<style scoped>
td {
    font-size: 36px;
    min-height: 40px;
    min-width: 40px;
    text-align: center;
    border: solid black;
}
</style>