<template>
    <div class="container">
        <div class="row">
            <h6>どこの こっき かな？</h6>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-4">
                <img v-bind:src="options[correctIndex].img_url" alt="">
            </div>
            <div class="col-xs-12 col-sm-8">
                <div
                    v-for="(option, index) in options"
                    v-bind:key="option.name"
                    class="mx-1 my-1"
                >
                    <button
                        type="button"
                        v-bind:class="['btn', option.badgeClass]"
                        v-on:click="mark(index)"
                    >
                        {{ option.name }}
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <TryAgainButton v-bind:renew="renew"/>
            <QuizConfigurationModal>
                <h6>もんだいに ふくめる はたの とくちょうは？</h6>
                <div
                    v-for="(tag, ti) in tags"
                    v-bind:key="'tag' + ti"
                    class="form-check form-check-inline"
                    >
                    <label class="form-check-label">
                        <input
                            class="form-check-input" type="checkbox" name="flag-features" v-bind:id="`flag-features-${ti}`"
                            v-model="filter" v-bind:value="tag.feature"> {{ tag.feature }}
                    </label>
                </div>
            </QuizConfigurationModal>
        </div>
        <div class="row">
            <div class="col-12">
                <Achievement v-bind:rightness="correct" v-bind:total="total"/>
            </div>
        </div>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Achievement from "./Achievement.vue"

const title = "どこの こっき かな？"
const name = 'NationalFlag'
export default {
    name: name,
    title: title,
    components: {
        TryAgainButton,
        QuizConfigurationModal,
        Achievement
    },
    data: function() {
        return {
            correct: false,
            total: 0,
            correctIndex: 0,
            optionIndices: [0],
            options: [],
            tags: [
                {feature: 'ボーダー', indices: []},
                {feature: 'カントン', indices: []},
                {feature: 'ひし形', indices: []},
                {feature: '円形', indices: []},
                {feature: 'ギリシア十字', indices: []},
                {feature: '十字', indices: []},
                {feature: 'スカンジナビア十字', indices: [74, 114, 172, 202]},
                {feature: 'X型十字', indices: []},
                {feature: '山型', indices: []},
                {feature: 'ポール', indices: []},
                {feature: '縦三分割', indices: [1, 18, 20, 38, 44, 47, 50, 79, 90, 95, 105, 107, 115, 144, 146, 150, 152, 162, 168, 169, 179, 194, 210, 221, 244]},
                {feature: '横三分割', indices: [7, 10, 12, 22, 29, 35, 49, 58, 65, 66, 67, 68, 71, 72, 80, 86, 89, 101, 102, 104, 110, 112, 113, 120, 131, 132, 137, 139, 140, 151, 161, 166, 167, 170, 171, 180, 191, 195, 196, 197, 205, 207, 208, 215, 217, 225, 242, 245, 253]},
                {feature: '斜め二分割', indices: []},
                {feature: '斜めの縞', indices: []},
                {feature: '月と星', indices: [16, 64, 69, 124, 141, 156, 183, 203, 228, 229, 231, 242]},
                {feature: '南十字星', indices: [13, 55, 100, 176, 181, 226, 251]},
                {feature: '赤黄緑', indices: [21, 25, 29, 41, 42, 47, 70, 72, 82, 84, 86, 90, 97, 98, 124, 125, 138, 150, 151, 156, 159, 164, 165, 166, 200, 210, 212, 213, 214, 223, 225, 249, 255, 256, 257]},
                {feature: '黒白赤緑', indices: [2, 3, 69, 98, 112, 118, 120, 125, 128, 141, 188, 201, 213, 217, 255, 257]},
                {feature: 'イギリス領', indices: [0, 5, 13, 45, 59, 75, 76, 81, 94, 100, 111, 129, 157, 175, 176, 186, 204, 219, 220, 233, 246]},
            ],
            filter: [/* 'ボーダー', 'カントン', 'ひし形', '円形', 'ギリシア十字', '十字', 'スカンジナビア十字', 'X型十字', '山型', 'ポール', '縦三分割', '横三分割', '斜め二分割', '斜めの縞', '月と星', '南十字星', '赤黄緑', '黒白赤緑', */ 'イギリス領'],
            filtered: [],
            flags: [
                {name: 'アセンション島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1e8.png", flag: '&#x1f1e6;&#x1f1e8;', tags: ['イギリス領']}, // "Ascension Island", "アセンション島", 
                {name: 'アンドラ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1e9.png", flag: '&#x1f1e6;&#x1f1e9;', tags: ['縦三分割']}, // "Andorra", "アンドラ", 
                {name: 'アラブ首長国連邦', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ea.png", flag: '&#x1f1e6;&#x1f1ea;', tags: ['黒白赤緑']}, // "United Arab Emirates", "アラブ首長国連邦", 
                {name: 'アフガニスタン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1eb.png", flag: '&#x1f1e6;&#x1f1eb;', tags: ['黒白赤緑']}, // "Afghanistan", "アフガニスタン", 
                {name: 'アンティグア・バーブーダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ec.png", flag: '&#x1f1e6;&#x1f1ec;', tags: []}, // "Antigua and Barbuda", "アンティグア・バーブーダ", 
                {name: 'アンギラ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ee.png", flag: '&#x1f1e6;&#x1f1ee;', tags: ['イギリス領']}, // "Anguilla", "アンギラ", 
                {name: 'アルバニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f1.png", flag: '&#x1f1e6;&#x1f1f1;', tags: []}, // "Albania", "アルバニア", 
                {name: 'アルメニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f2.png", flag: '&#x1f1e6;&#x1f1f2;', tags: ['横三分割']}, // "Armenia", "アルメニア", 
                {name: 'アンゴラ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f4.png", flag: '&#x1f1e6;&#x1f1f4;', tags: []}, // "Angola", "アンゴラ", 
                {name: '南極', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f6.png", flag: '&#x1f1e6;&#x1f1f6;', tags: []}, // "Antarctica", "南極", 
                {name: 'アルゼンチン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f7.png", flag: '&#x1f1e6;&#x1f1f7;', tags: ['横三分割']}, // "Argentine", "アルゼンチン", 
                {name: 'アメリカ領サモア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f8.png", flag: '&#x1f1e6;&#x1f1f8;', tags: []}, // "American Samoa", "アメリカ領サモア", 
                {name: 'オーストリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f9.png", flag: '&#x1f1e6;&#x1f1f9;', tags: ['横三分割']}, // "Austria", "オーストリア", 
                {name: 'オーストラリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fa.png", flag: '&#x1f1e6;&#x1f1fa;', tags: ['イギリス領', '南十字星']}, // "Australia", "オーストラリア", 
                {name: 'アルバ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fc.png", flag: '&#x1f1e6;&#x1f1fc;', tags: []}, // "Aruba", "アルバ", 
                {name: 'オーランド諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fd.png", flag: '&#x1f1e6;&#x1f1fd;', tags: []}, // "Åland Islands", "オーランド諸島", 
                {name: 'アゼルバイジャン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ff.png", flag: '&#x1f1e6;&#x1f1ff;', tags: ['月と星']}, // "Azerbaijan", "アゼルバイジャン", 
                {name: 'ボスニア・ヘルツェゴビナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e6.png", flag: '&#x1f1e7;&#x1f1e6;', tags: []}, // "Bosnia and Herzegovina", "ボスニア・ヘルツェゴビナ", 
                {name: 'バルバドス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e7.png", flag: '&#x1f1e7;&#x1f1e7;', tags: ['縦三分割']}, // "Barbados", "バルバドス", 
                {name: 'バングラデシュ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e9.png", flag: '&#x1f1e7;&#x1f1e9;', tags: []}, // "Bangladesh", "バングラデシュ", 
                {name: 'ベルギー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ea.png", flag: '&#x1f1e7;&#x1f1ea;', tags: ['縦三分割']}, // "Belgium", "ベルギー", 
                {name: 'ブルキナファソ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1eb.png", flag: '&#x1f1e7;&#x1f1eb;', tags: ['赤黄緑']}, // "Burkina Faso", "ブルキナファソ", 
                {name: 'ブルガリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ec.png", flag: '&#x1f1e7;&#x1f1ec;', tags: ['横三分割']}, // "Bulgaria", "ブルガリア", 
                {name: 'バーレーン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ed.png", flag: '&#x1f1e7;&#x1f1ed;', tags: []}, // "Bahrain", "バーレーン", 
                {name: 'ブルンジ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ee.png", flag: '&#x1f1e7;&#x1f1ee;', tags: []}, // "Burundi", "ブルンジ", 
                {name: 'ベナン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ef.png", flag: '&#x1f1e7;&#x1f1ef;', tags: ['赤黄緑']}, // "Benin", "ベナン", 
                {name: 'サン・バルテルミー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f1.png", flag: '&#x1f1e7;&#x1f1f1;', tags: []}, // "Saint Barthélemy", "サン・バルテルミー", 
                {name: 'バミューダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f2.png", flag: '&#x1f1e7;&#x1f1f2;', tags: []}, // "Bermuda", "バミューダ", 
                {name: 'ブルネイ・ダルサラーム', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f3.png", flag: '&#x1f1e7;&#x1f1f3;', tags: []}, // "Brunei Darussalam", "ブルネイ・ダルサラーム", 
                {name: 'ボリビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f4.png", flag: '&#x1f1e7;&#x1f1f4;', tags: ['赤黄緑', '横三分割']}, // "Bolivia", "ボリビア", 
                {name: 'ボネール、シント・ユースタティウスおよびサバ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f6.png", flag: '&#x1f1e7;&#x1f1f6;', tags: []}, // "Bonaire, Saint Eustatius and Saba", "ボネール、シント・ユースタティウスおよびサバ", 
                {name: 'ブラジル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f7.png", flag: '&#x1f1e7;&#x1f1f7;', tags: []}, // "Brazil", "ブラジル", 
                {name: 'バハマ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f8.png", flag: '&#x1f1e7;&#x1f1f8;', tags: []}, // "Bahamas", "バハマ", 
                {name: 'ブータン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f9.png", flag: '&#x1f1e7;&#x1f1f9;', tags: []}, // "Bhutan", "ブータン", 
                {name: 'ブーベ島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fb.png", flag: '&#x1f1e7;&#x1f1fb;', tags: []}, // "Bouvet Island", "ブーベ島", 
                {name: 'ボツワナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fc.png", flag: '&#x1f1e7;&#x1f1fc;', tags: ['横三分割']}, // "Botswana", "ボツワナ", 
                {name: 'ベラルーシ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fe.png", flag: '&#x1f1e7;&#x1f1fe;', tags: []}, // "Belarus", "ベラルーシ", 
                {name: 'ベリーズ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ff.png", flag: '&#x1f1e7;&#x1f1ff;', tags: []}, // "Belize", "ベリーズ", 
                {name: 'カナダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e6.png", flag: '&#x1f1e8;&#x1f1e6;', tags: ['縦三分割']}, // "Canada", "カナダ", 
                {name: 'ココス（キーリング）諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e8.png", flag: '&#x1f1e8;&#x1f1e8;', tags: []}, // "Cocos (Keeling) Islands", "ココス（キーリング）諸島", 
                {name: 'コンゴ民主共和国', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e9.png", flag: '&#x1f1e8;&#x1f1e9;', tags: []}, // "Democratic Republic of the Congo", "コンゴ民主共和国", 
                {name: '中央アフリカ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1eb.png", flag: '&#x1f1e8;&#x1f1eb;', tags: ['赤黄緑']}, // "Central African", "中央アフリカ", 
                {name: 'コンゴ共和国', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ec.png", flag: '&#x1f1e8;&#x1f1ec;', tags: ['赤黄緑']}, // "Republic of the Congo", "コンゴ共和国", 
                {name: 'スイス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ed.png", flag: '&#x1f1e8;&#x1f1ed;', tags: []}, // "Switzerland", "スイス", 
                {name: 'コートジボワール', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ee.png", flag: '&#x1f1e8;&#x1f1ee;', tags: ['縦三分割']}, // "Cote d'Ivoire", "コートジボワール", 
                {name: 'クック諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f0.png", flag: '&#x1f1e8;&#x1f1f0;', tags: ['イギリス領']}, // "Cook Islands", "クック諸島", 
                {name: 'チリ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f1.png", flag: '&#x1f1e8;&#x1f1f1;', tags: []}, // "Chile", "チリ", 
                {name: 'カメルーン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f2.png", flag: '&#x1f1e8;&#x1f1f2;', tags: ['赤黄緑', '縦三分割']}, // "Cameroon", "カメルーン", 
                {name: '中国', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f3.png", flag: '&#x1f1e8;&#x1f1f3;', tags: []}, // "China", "中国", 
                {name: 'コロンビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f4.png", flag: '&#x1f1e8;&#x1f1f4;', tags: ['横三分割']}, // "Colombia", "コロンビア", 
                {name: 'クリッパートン島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f5.png", flag: '&#x1f1e8;&#x1f1f5;', tags: ['縦三分割']}, // "Clipperton Island", "クリッパートン島", 
                {name: 'コスタリカ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f7.png", flag: '&#x1f1e8;&#x1f1f7;', tags: []}, // "Costa Rica", "コスタリカ", 
                {name: 'キューバ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fa.png", flag: '&#x1f1e8;&#x1f1fa;', tags: []}, // "Cuba", "キューバ", 
                {name: 'カーボヴェルデ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fb.png", flag: '&#x1f1e8;&#x1f1fb;', tags: []}, // "Cape Verde", "カーボヴェルデ", 
                {name: 'キュラソー島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fc.png", flag: '&#x1f1e8;&#x1f1fc;', tags: []}, // "Curacao", "キュラソー島", 
                {name: 'クリスマス島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fd.png", flag: '&#x1f1e8;&#x1f1fd;', tags: ['南十字星']}, // "Christmas Island", "クリスマス島", 
                {name: 'キプロス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fe.png", flag: '&#x1f1e8;&#x1f1fe;', tags: []}, // "Cyprus", "キプロス", 
                {name: 'チェコ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ff.png", flag: '&#x1f1e8;&#x1f1ff;', tags: []}, // "Czech", "チェコ", 
                {name: 'ドイツ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ea.png", flag: '&#x1f1e9;&#x1f1ea;', tags: ['横三分割']}, // "Germany", "ドイツ", 
                {name: 'ディエゴガルシア島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ec.png", flag: '&#x1f1e9;&#x1f1ec;', tags: ['イギリス領']}, // "Diego Garcia", "ディエゴガルシア島", 
                {name: 'ジブチ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ef.png", flag: '&#x1f1e9;&#x1f1ef;', tags: []}, // "Djibouti", "ジブチ", 
                {name: 'デンマーク', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f0.png", flag: '&#x1f1e9;&#x1f1f0;', tags: []}, // "Denmark", "デンマーク", 
                {name: 'ドミニカ国', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f2.png", flag: '&#x1f1e9;&#x1f1f2;', tags: []}, // "Dominica", "ドミニカ国", 
                {name: 'ドミニカ共和国', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f4.png", flag: '&#x1f1e9;&#x1f1f4;', tags: []}, // "Dominican", "ドミニカ共和国", 
                {name: 'アルジェリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ff.png", flag: '&#x1f1e9;&#x1f1ff;', tags: ['月と星']}, // "Algeria", "アルジェリア", 
                {name: 'セウタとメリリャ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1e6.png", flag: '&#x1f1ea;&#x1f1e6;', tags: ['横三分割']}, // "Ceuta and Melilla", "セウタとメリリャ", 
                {name: 'エクアドル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1e8.png", flag: '&#x1f1ea;&#x1f1e8;', tags: ['横三分割']}, // "Ecuador", "エクアドル", 
                {name: 'エストニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ea.png", flag: '&#x1f1ea;&#x1f1ea;', tags: ['横三分割']}, // "Estonia", "エストニア", 
                {name: 'エジプト', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ec.png", flag: '&#x1f1ea;&#x1f1ec;', tags: ['横三分割']}, // "Egypt", "エジプト", 
                {name: '西サハラ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ed.png", flag: '&#x1f1ea;&#x1f1ed;', tags: ['月と星', '黒白赤緑']}, // "Western Sahara", "西サハラ", 
                {name: 'エリトリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f7.png", flag: '&#x1f1ea;&#x1f1f7;', tags: ['赤黄緑']}, // "Eritrea", "エリトリア", 
                {name: 'スペイン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f8.png", flag: '&#x1f1ea;&#x1f1f8;', tags: ['横三分割']}, // "Spain", "スペイン", 
                {name: 'エチオピア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f9.png", flag: '&#x1f1ea;&#x1f1f9;', tags: ['赤黄緑', '横三分割']}, // "Ethiopia", "エチオピア", 
                {name: '欧州連合', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1fa.png", flag: '&#x1f1ea;&#x1f1fa;', tags: []}, // "European Union", "欧州連合", 
                {name: 'フィンランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1ee.png", flag: '&#x1f1eb;&#x1f1ee;', tags: ['スカンジナビア十字']}, // "Finland", "フィンランド", 
                {name: 'フィジー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1ef.png", flag: '&#x1f1eb;&#x1f1ef;', tags: ['イギリス領']}, // "Fiji ", "フィジー", 
                {name: 'フォークランド諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f0.png", flag: '&#x1f1eb;&#x1f1f0;', tags: ['イギリス領']}, // "Falkland Islands", "フォークランド諸島", 
                {name: 'ミクロネシア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f2.png", flag: '&#x1f1eb;&#x1f1f2;', tags: []}, // "Micronesia", "ミクロネシア", 
                {name: 'フェロー諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f4.png", flag: '&#x1f1eb;&#x1f1f4;', tags: []}, // "Faroe Islands", "フェロー諸島", 
                {name: 'フランス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f7.png", flag: '&#x1f1eb;&#x1f1f7;', tags: ['縦三分割']}, // "French ", "フランス", 
                {name: 'ガボン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e6.png", flag: '&#x1f1ec;&#x1f1e6;', tags: ['横三分割']}, // "Gabonese ", "ガボン", 
                {name: 'イギリス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e7.png", flag: '&#x1f1ec;&#x1f1e7;', tags: ['イギリス領']}, // "United Kingdom", "イギリス", 
                {name: 'グレナダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e9.png", flag: '&#x1f1ec;&#x1f1e9;', tags: ['赤黄緑']}, // "Grenada", "グレナダ", 
                {name: 'ジョージア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ea.png", flag: '&#x1f1ec;&#x1f1ea;', tags: []}, // "Georgia", "ジョージア", 
                {name: 'フランス領ギアナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1eb.png", flag: '&#x1f1ec;&#x1f1eb;', tags: ['赤黄緑']}, // "French Guiana", "フランス領ギアナ", 
                {name: 'ガーンジー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ec.png", flag: '&#x1f1ec;&#x1f1ec;', tags: []}, // "Guernsey", "ガーンジー", 
                {name: 'ガーナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ed.png", flag: '&#x1f1ec;&#x1f1ed;', tags: ['赤黄緑', '横三分割']}, // "Ghana", "ガーナ", 
                {name: 'ジブラルタル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ee.png", flag: '&#x1f1ec;&#x1f1ee;', tags: []}, // "Gibraltar", "ジブラルタル", 
                {name: 'グリーンランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f1.png", flag: '&#x1f1ec;&#x1f1f1;', tags: []}, // "Greenland", "グリーンランド", 
                {name: 'ガンビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f2.png", flag: '&#x1f1ec;&#x1f1f2;', tags: ['横三分割']}, // "Gambia", "ガンビア", 
                {name: 'ギニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f3.png", flag: '&#x1f1ec;&#x1f1f3;', tags: ['赤黄緑', '縦三分割']}, // "Guinea", "ギニア", 
                {name: 'グアドループ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f5.png", flag: '&#x1f1ec;&#x1f1f5;', tags: []}, // "Guadeloupe", "グアドループ", 
                {name: '赤道ギニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f6.png", flag: '&#x1f1ec;&#x1f1f6;', tags: []}, // "Equatorial Guinea", "赤道ギニア", 
                {name: 'ギリシャ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f7.png", flag: '&#x1f1ec;&#x1f1f7;', tags: []}, // "Greece", "ギリシャ", 
                {name: '南ジョージア島・南サンドイッチ諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f8.png", flag: '&#x1f1ec;&#x1f1f8;', tags: ['イギリス領']}, // "South Georgia and the South Sandwich Islands", "南ジョージア島・南サンドイッチ諸島", 
                {name: 'グアテマラ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f9.png", flag: '&#x1f1ec;&#x1f1f9;', tags: ['縦三分割']}, // "Guatemala", "グアテマラ", 
                {name: 'グアム', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fa.png", flag: '&#x1f1ec;&#x1f1fa;', tags: []}, // "Guam", "グアム", 
                {name: 'ギニアビサウ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fc.png", flag: '&#x1f1ec;&#x1f1fc;', tags: ['赤黄緑']}, // "Guinea-Bissau", "ギニアビサウ", 
                {name: 'ガイアナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fe.png", flag: '&#x1f1ec;&#x1f1fe;', tags: ['黒白赤緑', '赤黄緑']}, // "Guyana", "ガイアナ", 
                {name: '香港', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f0.png", flag: '&#x1f1ed;&#x1f1f0;', tags: []}, // "Hong Kong ", "香港", 
                {name: 'ヘアド島・マクドナルド諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f2.png", flag: '&#x1f1ed;&#x1f1f2;', tags: ['イギリス領', '南十字星']}, // "Heard Island and McDonald Islands", "ヘアド島・マクドナルド諸島", 
                {name: 'ホンジュラス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f3.png", flag: '&#x1f1ed;&#x1f1f3;', tags: ['横三分割']}, // "Honduras", "ホンジュラス", 
                {name: 'クロアチア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f7.png", flag: '&#x1f1ed;&#x1f1f7;', tags: ['横三分割']}, // "Croatia", "クロアチア", 
                {name: 'ハイチ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f9.png", flag: '&#x1f1ed;&#x1f1f9;', tags: []}, // "Haiti", "ハイチ", 
                {name: 'ハンガリー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1fa.png", flag: '&#x1f1ed;&#x1f1fa;', tags: ['横三分割']}, // "Hungary", "ハンガリー", 
                {name: 'カナリア諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1e8.png", flag: '&#x1f1ee;&#x1f1e8;', tags: ['縦三分割']}, // "Canary Islands", "カナリア諸島", 
                {name: 'インドネシア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1e9.png", flag: '&#x1f1ee;&#x1f1e9;', tags: []}, // "Indonesia", "インドネシア", 
                {name: 'アイルランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1ea.png", flag: '&#x1f1ee;&#x1f1ea;', tags: ['縦三分割']}, // "Ireland", "アイルランド", 
                {name: 'イスラエル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f1.png", flag: '&#x1f1ee;&#x1f1f1;', tags: []}, // "Israel", "イスラエル", 
                {name: 'マン島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f2.png", flag: '&#x1f1ee;&#x1f1f2;', tags: []}, // "Isle of Man", "マン島", 
                {name: 'インド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f3.png", flag: '&#x1f1ee;&#x1f1f3;', tags: ['横三分割']}, // "India", "インド", 
                {name: 'イギリス領インド洋地域', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f4.png", flag: '&#x1f1ee;&#x1f1f4;', tags: ['イギリス領']}, // "British Indian Ocean Territory", "イギリス領インド洋地域", 
                {name: 'イラク', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f6.png", flag: '&#x1f1ee;&#x1f1f6;', tags: ['黒白赤緑', '横三分割']}, // "Iraq", "イラク", 
                {name: 'イラン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f7.png", flag: '&#x1f1ee;&#x1f1f7;', tags: ['横三分割']}, // "Iran", "イラン", 
                {name: 'アイスランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f8.png", flag: '&#x1f1ee;&#x1f1f8;', tags: ['スカンジナビア十字']}, // "Iceland", "アイスランド", 
                {name: 'イタリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f9.png", flag: '&#x1f1ee;&#x1f1f9;', tags: ['縦三分割']}, // "Italy", "イタリア", 
                {name: 'ジャージー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1ea.png", flag: '&#x1f1ef;&#x1f1ea;', tags: []}, // "Jersey", "ジャージー", 
                {name: 'ジャマイカ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f2.png", flag: '&#x1f1ef;&#x1f1f2;', tags: []}, // "Jamaica", "ジャマイカ", 
                {name: 'ヨルダン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f4.png", flag: '&#x1f1ef;&#x1f1f4;', tags: ['黒白赤緑']}, // "Jordan", "ヨルダン", 
                {name: '日本', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f5.png", flag: '&#x1f1ef;&#x1f1f5;', tags: []}, // "Japan", "日本", 
                {name: 'ケニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ea.png", flag: '&#x1f1f0;&#x1f1ea;', tags: ['黒白赤緑', '横三分割']}, // "Kenya", "ケニア", 
                {name: 'キルギス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ec.png", flag: '&#x1f1f0;&#x1f1ec;', tags: []}, // "Kyrgyz", "キルギス", 
                {name: 'カンボジア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ed.png", flag: '&#x1f1f0;&#x1f1ed;', tags: []}, // "Cambodia", "カンボジア", 
                {name: 'キリバス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ee.png", flag: '&#x1f1f0;&#x1f1ee;', tags: []}, // "Kiribati", "キリバス", 
                {name: 'コモロ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f2.png", flag: '&#x1f1f0;&#x1f1f2;', tags: ['月と星', '赤黄緑']}, // "Comoros", "コモロ", 
                {name: 'セントクリストファー・ネイビス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f3.png", flag: '&#x1f1f0;&#x1f1f3;', tags: ['黒白赤緑', '赤黄緑']}, // "Saint Christopher and Nevis", "セントクリストファー・ネイビス", 
                {name: '北朝鮮', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f5.png", flag: '&#x1f1f0;&#x1f1f5;', tags: []}, // "North Korea", "北朝鮮", 
                {name: '韓国', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f7.png", flag: '&#x1f1f0;&#x1f1f7;', tags: []}, // "South Korea", "韓国", 
                {name: 'クウェート', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1fc.png", flag: '&#x1f1f0;&#x1f1fc;', tags: ['黒白赤緑']}, // "Kuwait", "クウェート", 
                {name: 'ケイマン諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1fe.png", flag: '&#x1f1f0;&#x1f1fe;', tags: ['イギリス領']}, // "Cayman Islands", "ケイマン諸島", 
                {name: 'カザフスタン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ff.png", flag: '&#x1f1f0;&#x1f1ff;', tags: []}, // "Kazakhstan", "カザフスタン", 
                {name: 'ラオス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e6.png", flag: '&#x1f1f1;&#x1f1e6;', tags: ['横三分割']}, // "Laos", "ラオス", 
                {name: 'レバノン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e7.png", flag: '&#x1f1f1;&#x1f1e7;', tags: ['横三分割']}, // "Lebanon", "レバノン", 
                {name: 'セントルシア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e8.png", flag: '&#x1f1f1;&#x1f1e8;', tags: []}, // "Saint Lucia", "セントルシア", 
                {name: 'リヒテンシュタイン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1ee.png", flag: '&#x1f1f1;&#x1f1ee;', tags: []}, // "Liechtenstein", "リヒテンシュタイン", 
                {name: 'スリランカ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f0.png", flag: '&#x1f1f1;&#x1f1f0;', tags: []}, // "Sri Lanka", "スリランカ", 
                {name: 'リベリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f7.png", flag: '&#x1f1f1;&#x1f1f7;', tags: []}, // "Liberia", "リベリア", 
                {name: 'レソト', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f8.png", flag: '&#x1f1f1;&#x1f1f8;', tags: ['横三分割']}, // "Lesotho", "レソト", 
                {name: 'リトアニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f9.png", flag: '&#x1f1f1;&#x1f1f9;', tags: ['赤黄緑']}, // "Lithuania", "リトアニア", 
                {name: 'ルクセンブルク', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fa.png", flag: '&#x1f1f1;&#x1f1fa;', tags: ['横三分割']}, // "Luxembourg", "ルクセンブルク", 
                {name: 'ラトビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fb.png", flag: '&#x1f1f1;&#x1f1fb;', tags: ['横三分割']}, // "Latvia", "ラトビア", 
                {name: 'リビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fe.png", flag: '&#x1f1f1;&#x1f1fe;', tags: ['月と星', '黒白赤緑']}, // "Libya", "リビア", 
                {name: 'モロッコ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e6.png", flag: '&#x1f1f2;&#x1f1e6;', tags: []}, // "Morocco", "モロッコ", 
                {name: 'モナコ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e8.png", flag: '&#x1f1f2;&#x1f1e8;', tags: []}, // "Monaco", "モナコ", 
                {name: 'モルドバ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e9.png", flag: '&#x1f1f2;&#x1f1e9;', tags: ['縦三分割']}, // "Moldova", "モルドバ", 
                {name: 'モンテネグロ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ea.png", flag: '&#x1f1f2;&#x1f1ea;', tags: []}, // "Montenegro", "モンテネグロ", 
                {name: 'サン・マルタン島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1eb.png", flag: '&#x1f1f2;&#x1f1eb;', tags: ['縦三分割']}, // "Saint Martin", "サン・マルタン島", 
                {name: 'マダガスカル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ec.png", flag: '&#x1f1f2;&#x1f1ec;', tags: []}, // "Madagascar", "マダガスカル", 
                {name: 'マーシャル諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ed.png", flag: '&#x1f1f2;&#x1f1ed;', tags: []}, // "Marshall", "マーシャル諸島", 
                {name: 'マケドニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f0.png", flag: '&#x1f1f2;&#x1f1f0;', tags: []}, // "Macedonia", "マケドニア", 
                {name: 'マリ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f1.png", flag: '&#x1f1f2;&#x1f1f1;', tags: ['赤黄緑', '縦三分割']}, // "Mali", "マリ", 
                {name: 'ミャンマー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f2.png", flag: '&#x1f1f2;&#x1f1f2;', tags: ['赤黄緑', '横三分割']}, // "Myanmar", "ミャンマー", 
                {name: 'モンゴル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f3.png", flag: '&#x1f1f2;&#x1f1f3;', tags: ['縦三分割']}, // "Mongolia", "モンゴル", 
                {name: 'マカオ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f4.png", flag: '&#x1f1f2;&#x1f1f4;', tags: []}, // "Macao", "マカオ", 
                {name: '北マリアナ諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f5.png", flag: '&#x1f1f2;&#x1f1f5;', tags: []}, // "Northern Mariana", "北マリアナ諸島", 
                {name: 'マルチニーク島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f6.png", flag: '&#x1f1f2;&#x1f1f6;', tags: []}, // "Martinique", "マルチニーク島", 
                {name: 'モーリタニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f7.png", flag: '&#x1f1f2;&#x1f1f7;', tags: ['月と星', '赤黄緑']}, // "Mauritania", "モーリタニア", 
                {name: 'モントセラト', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f8.png", flag: '&#x1f1f2;&#x1f1f8;', tags: ['イギリス領']}, // "Montserrat", "モントセラト", 
                {name: 'マルタ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f9.png", flag: '&#x1f1f2;&#x1f1f9;', tags: []}, // "Malta", "マルタ", 
                {name: 'モーリシャス', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fa.png", flag: '&#x1f1f2;&#x1f1fa;', tags: ['赤黄緑']}, // "Mauritius", "モーリシャス", 
                {name: 'モルディブ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fb.png", flag: '&#x1f1f2;&#x1f1fb;', tags: []}, // "Maldives", "モルディブ", 
                {name: 'マラウイ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fc.png", flag: '&#x1f1f2;&#x1f1fc;', tags: ['横三分割']}, // "Malawi", "マラウイ", 
                {name: 'メキシコ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fd.png", flag: '&#x1f1f2;&#x1f1fd;', tags: ['縦三分割']}, // "Mexico", "メキシコ", 
                {name: 'マレーシア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fe.png", flag: '&#x1f1f2;&#x1f1fe;', tags: []}, // "Malaysia", "マレーシア", 
                {name: 'モザンビーク', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ff.png", flag: '&#x1f1f2;&#x1f1ff;', tags: ['黒白赤緑', '赤黄緑']}, // "Mozambique", "モザンビーク", 
                {name: 'ナミビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1e6.png", flag: '&#x1f1f3;&#x1f1e6;', tags: ['赤黄緑']}, // "Namibia", "ナミビア", 
                {name: 'ニューカレドニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1e8.png", flag: '&#x1f1f3;&#x1f1e8;', tags: ['赤黄緑', '横三分割']}, // "New Caledonia", "ニューカレドニア", 
                {name: 'ニジェール', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ea.png", flag: '&#x1f1f3;&#x1f1ea;', tags: ['横三分割']}, // "Niger", "ニジェール", 
                {name: 'ノーフォーク島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1eb.png", flag: '&#x1f1f3;&#x1f1eb;', tags: ['縦三分割']}, // "Norfolk Island", "ノーフォーク島", 
                {name: 'ナイジェリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ec.png", flag: '&#x1f1f3;&#x1f1ec;', tags: ['縦三分割']}, // "Nigeria", "ナイジェリア", 
                {name: 'ニカラグア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ee.png", flag: '&#x1f1f3;&#x1f1ee;', tags: ['横三分割']}, // "Nicaragua", "ニカラグア", 
                {name: 'オランダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f1.png", flag: '&#x1f1f3;&#x1f1f1;', tags: ['横三分割']}, // "Netherlands", "オランダ", 
                {name: 'ノルウェー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f4.png", flag: '&#x1f1f3;&#x1f1f4;', tags: ['スカンジナビア十字']}, // "Norway", "ノルウェー", 
                {name: 'ネパール', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f5.png", flag: '&#x1f1f3;&#x1f1f5;', tags: []}, // "Nepal", "ネパール", 
                {name: 'ナウル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f7.png", flag: '&#x1f1f3;&#x1f1f7;', tags: []}, // "Nauru", "ナウル", 
                {name: 'ニウエ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1fa.png", flag: '&#x1f1f3;&#x1f1fa;', tags: ['イギリス領']}, // "Niue", "ニウエ", 
                {name: 'ニュージーランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ff.png", flag: '&#x1f1f3;&#x1f1ff;', tags: ['イギリス領', '南十字星']}, // "New Zealand", "ニュージーランド", 
                {name: 'オマーン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f4-1f1f2.png", flag: '&#x1f1f4;&#x1f1f2;', tags: []}, // "Oman", "オマーン", 
                {name: 'パナマ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1e6.png", flag: '&#x1f1f5;&#x1f1e6;', tags: []}, // "Panama", "パナマ", 
                {name: 'ペルー', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ea.png", flag: '&#x1f1f5;&#x1f1ea;', tags: ['縦三分割']}, // "Peru", "ペルー", 
                {name: 'フランス領ポリネシア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1eb.png", flag: '&#x1f1f5;&#x1f1eb;', tags: ['横三分割']}, // "French Polynesia", "フランス領ポリネシア", 
                {name: 'パプアニューギニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ec.png", flag: '&#x1f1f5;&#x1f1ec;', tags: ['南十字星']}, // "Papua New Guinea", "パプアニューギニア", 
                {name: 'フィリピン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ed.png", flag: '&#x1f1f5;&#x1f1ed;', tags: []}, // "Philippines", "フィリピン", 
                {name: 'パキスタン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f0.png", flag: '&#x1f1f5;&#x1f1f0;', tags: ['月と星']}, // "Pakistan", "パキスタン", 
                {name: 'ポーランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f1.png", flag: '&#x1f1f5;&#x1f1f1;', tags: []}, // "Poland", "ポーランド", 
                {name: 'サンピエール島・ミクロン島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f2.png", flag: '&#x1f1f5;&#x1f1f2;', tags: []}, // "St. Pierre and Miquelon", "サンピエール島・ミクロン島", 
                {name: 'ピトケアン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f3.png", flag: '&#x1f1f5;&#x1f1f3;', tags: ['イギリス領']}, // "Pitcairn", "ピトケアン", 
                {name: 'プエルトリコ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f7.png", flag: '&#x1f1f5;&#x1f1f7;', tags: []}, // "Puerto Rico", "プエルトリコ", 
                {name: 'パレスチナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f8.png", flag: '&#x1f1f5;&#x1f1f8;', tags: ['黒白赤緑']}, // "Palestine", "パレスチナ", 
                {name: 'ポルトガル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f9.png", flag: '&#x1f1f5;&#x1f1f9;', tags: []}, // "Portuguese", "ポルトガル", 
                {name: 'パラオ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1fc.png", flag: '&#x1f1f5;&#x1f1fc;', tags: []}, // "Palau", "パラオ", 
                {name: 'パラグアイ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1fe.png", flag: '&#x1f1f5;&#x1f1fe;', tags: ['横三分割']}, // "Paraguay", "パラグアイ", 
                {name: 'カタール', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f6-1f1e6.png", flag: '&#x1f1f6;&#x1f1e6;', tags: []}, // "Qatar", "カタール", 
                {name: 'レユニオン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1ea.png", flag: '&#x1f1f7;&#x1f1ea;', tags: []}, // "Reunion", "レユニオン", 
                {name: 'ルーマニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1f4.png", flag: '&#x1f1f7;&#x1f1f4;', tags: ['縦三分割']}, // "Romania", "ルーマニア", 
                {name: 'セルビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1f8.png", flag: '&#x1f1f7;&#x1f1f8;', tags: ['横三分割']}, // "Serbia", "セルビア", 
                {name: 'ロシア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1fa.png", flag: '&#x1f1f7;&#x1f1fa;', tags: ['横三分割']}, // "Russian", "ロシア", 
                {name: 'ルワンダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1fc.png", flag: '&#x1f1f7;&#x1f1fc;', tags: ['横三分割']}, // "Rwanda", "ルワンダ", 
                {name: 'サウジアラビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e6.png", flag: '&#x1f1f8;&#x1f1e6;', tags: []}, // "Saudi Arabia", "サウジアラビア", 
                {name: 'ソロモン諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e7.png", flag: '&#x1f1f8;&#x1f1e7;', tags: []}, // "Solomon Islands", "ソロモン諸島", 
                {name: 'セーシェル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e8.png", flag: '&#x1f1f8;&#x1f1e8;', tags: ['赤黄緑']}, // "Seychelles", "セーシェル", 
                {name: 'スーダン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e9.png", flag: '&#x1f1f8;&#x1f1e9;', tags: ['黒白赤緑']}, // "Sudan", "スーダン", 
                {name: 'スウェーデン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ea.png", flag: '&#x1f1f8;&#x1f1ea;', tags: ['スカンジナビア十字']}, // "Sweden", "スウェーデン", 
                {name: 'シンガポール', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ec.png", flag: '&#x1f1f8;&#x1f1ec;', tags: ['月と星']}, // "Singapore", "シンガポール", 
                {name: 'セントヘレナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ed.png", flag: '&#x1f1f8;&#x1f1ed;', tags: ['イギリス領']}, // "Saint Helena", "セントヘレナ", 
                {name: 'スロベニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ee.png", flag: '&#x1f1f8;&#x1f1ee;', tags: ['横三分割']}, // "Slovenia", "スロベニア", 
                {name: 'スヴァールバル諸島およびヤンマイエン島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ef.png", flag: '&#x1f1f8;&#x1f1ef;', tags: ['スカンジナビア十字']}, // "Svalbard and Jan Mayen", "スヴァールバル諸島およびヤンマイエン島", 
                {name: 'スロバキア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f0.png", flag: '&#x1f1f8;&#x1f1f0;', tags: ['横三分割']}, // "Slovakia", "スロバキア", 
                {name: 'シエラレオネ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f1.png", flag: '&#x1f1f8;&#x1f1f1;', tags: ['横三分割']}, // "Sierra Leone", "シエラレオネ", 
                {name: 'サンマリノ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f2.png", flag: '&#x1f1f8;&#x1f1f2;', tags: []}, // "San Marino", "サンマリノ", 
                {name: 'セネガル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f3.png", flag: '&#x1f1f8;&#x1f1f3;', tags: ['赤黄緑', '縦三分割']}, // "Senegal", "セネガル", 
                {name: 'ソマリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f4.png", flag: '&#x1f1f8;&#x1f1f4;', tags: []}, // "Somalia", "ソマリア", 
                {name: 'スリナム', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f7.png", flag: '&#x1f1f8;&#x1f1f7;', tags: ['赤黄緑']}, // "Suriname", "スリナム", 
                {name: '南スーダン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f8.png", flag: '&#x1f1f8;&#x1f1f8;', tags: ['黒白赤緑', '赤黄緑']}, // "South Sudan", "南スーダン", 
                {name: 'サントメ・プリンシペ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f9.png", flag: '&#x1f1f8;&#x1f1f9;', tags: ['赤黄緑']}, // "Sao Tome and Principe", "サントメ・プリンシペ", 
                {name: 'エルサルバドル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fb.png", flag: '&#x1f1f8;&#x1f1fb;', tags: ['横三分割']}, // "El Salvador", "エルサルバドル", 
                {name: 'シント・マールテン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fd.png", flag: '&#x1f1f8;&#x1f1fd;', tags: []}, // "Sint Maarten", "シント・マールテン", 
                {name: 'シリア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fe.png", flag: '&#x1f1f8;&#x1f1fe;', tags: ['黒白赤緑', '横三分割']}, // "Syrian", "シリア", 
                {name: 'スワジランド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ff.png", flag: '&#x1f1f8;&#x1f1ff;', tags: []}, // "Swaziland", "スワジランド", 
                {name: 'トリスタンダクーニャ島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e6.png", flag: '&#x1f1f9;&#x1f1e6;', tags: ['イギリス領']}, // "Tristan da Cunha", "トリスタンダクーニャ島", 
                {name: 'タークス・カイコス諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e8.png", flag: '&#x1f1f9;&#x1f1e8;', tags: ['イギリス領']}, // "Turks And Caicos", "タークス・カイコス諸島", 
                {name: 'チャド', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e9.png", flag: '&#x1f1f9;&#x1f1e9;', tags: ['縦三分割']}, // "Chad", "チャド", 
                {name: 'フランス領南方・南極地域', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1eb.png", flag: '&#x1f1f9;&#x1f1eb;', tags: []}, // "Terres australes et antarctiques françaises", "フランス領南方・南極地域", 
                {name: 'トーゴ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ec.png", flag: '&#x1f1f9;&#x1f1ec;', tags: ['赤黄緑']}, // "Togo", "トーゴ", 
                {name: 'タイ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ed.png", flag: '&#x1f1f9;&#x1f1ed;', tags: []}, // "Thailand", "タイ", 
                {name: 'タジキスタン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ef.png", flag: '&#x1f1f9;&#x1f1ef;', tags: ['赤黄緑', '横三分割']}, // "Tajikistan", "タジキスタン", 
                {name: 'トケラウ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f0.png", flag: '&#x1f1f9;&#x1f1f0;', tags: ['南十字星']}, // "Tokelau", "トケラウ", 
                {name: '東ティモール', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f1.png", flag: '&#x1f1f9;&#x1f1f1;', tags: []}, // "Timor-Leste", "東ティモール", 
                {name: 'トルクメニスタン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f2.png", flag: '&#x1f1f9;&#x1f1f2;', tags: ['月と星']}, // "Turkmenistan", "トルクメニスタン", 
                {name: 'チュニジア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f3.png", flag: '&#x1f1f9;&#x1f1f3;', tags: ['月と星']}, // "Tunisia", "チュニジア", 
                {name: 'トンガ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f4.png", flag: '&#x1f1f9;&#x1f1f4;', tags: []}, // "Tonga", "トンガ", 
                {name: 'トルコ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f7.png", flag: '&#x1f1f9;&#x1f1f7;', tags: ['月と星']}, // "Turkey", "トルコ", 
                {name: 'トリニダード・トバゴ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f9.png", flag: '&#x1f1f9;&#x1f1f9;', tags: []}, // "Trinidad and Tobago", "トリニダード・トバゴ", 
                {name: 'ツバル', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1fb.png", flag: '&#x1f1f9;&#x1f1fb;', tags: ['イギリス領']}, // "Tuvalu", "ツバル", 
                {name: '台湾', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1fc.png", flag: '&#x1f1f9;&#x1f1fc;', tags: []}, // "Taiwan", "台湾", 
                {name: 'タンザニア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ff.png", flag: '&#x1f1f9;&#x1f1ff;', tags: []}, // "Tanzania", "タンザニア", 
                {name: 'ウクライナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1e6.png", flag: '&#x1f1fa;&#x1f1e6;', tags: []}, // "Ukraine", "ウクライナ", 
                {name: 'ウガンダ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1ec.png", flag: '&#x1f1fa;&#x1f1ec;', tags: []}, // "Uganda", "ウガンダ", 
                {name: '合衆国小離島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f2.png", flag: '&#x1f1fa;&#x1f1f2;', tags: []}, // "US Minor Outlying Is.", "合衆国小離島", 
                {name: '国際連合', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f3.png", flag: '&#x1f1fa;&#x1f1f3;', tags: []}, // "United Nations", "国際連合", 
                {name: 'アメリカ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f8.png", flag: '&#x1f1fa;&#x1f1f8;', tags: []}, // "United States of America", "アメリカ", 
                {name: 'ウルグアイ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1fe.png", flag: '&#x1f1fa;&#x1f1fe;', tags: []}, // "Uruguay", "ウルグアイ", 
                {name: 'ウズベキスタン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1ff.png", flag: '&#x1f1fa;&#x1f1ff;', tags: ['月と星', '横三分割']}, // "Uzbekistan", "ウズベキスタン", 
                {name: 'バチカン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1e6.png", flag: '&#x1f1fb;&#x1f1e6;', tags: []}, // "Vatican", "バチカン", 
                {name: 'セントビンセント・グレナディーン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1e8.png", flag: '&#x1f1fb;&#x1f1e8;', tags: ['縦三分割']}, // "Saint Vincent and the Grenadines", "セントビンセント・グレナディーン", 
                {name: 'ベネズエラ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ea.png", flag: '&#x1f1fb;&#x1f1ea;', tags: ['横三分割']}, // "Venezuela", "ベネズエラ", 
                {name: 'イギリス領ヴァージン諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ec.png", flag: '&#x1f1fb;&#x1f1ec;', tags: ['イギリス領']}, // "Virgin Is., British", "イギリス領ヴァージン諸島", 
                {name: 'アメリカ領ヴァージン諸島', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ee.png", flag: '&#x1f1fb;&#x1f1ee;', tags: []}, // "Virgin Is., U.S.", "アメリカ領ヴァージン諸島", 
                {name: 'ベトナム', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1f3.png", flag: '&#x1f1fb;&#x1f1f3;', tags: []}, // "Viet Nam", "ベトナム", 
                {name: 'バヌアツ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1fa.png", flag: '&#x1f1fb;&#x1f1fa;', tags: ['赤黄緑']}, // "Vanuatu", "バヌアツ", 
                {name: 'ウォリス・フツナ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fc-1f1eb.png", flag: '&#x1f1fc;&#x1f1eb;', tags: []}, // "Wallis And Futuna", "ウォリス・フツナ", 
                {name: 'サモア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fc-1f1f8.png", flag: '&#x1f1fc;&#x1f1f8;', tags: ['南十字星']}, // "Samoa", "サモア", 
                {name: 'コソボ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fd-1f1f0.png", flag: '&#x1f1fd;&#x1f1f0;', tags: []}, // "Kosovo", "コソボ", 
                {name: 'イエメン', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fe-1f1ea.png", flag: '&#x1f1fe;&#x1f1ea;', tags: ['横三分割']}, // "Yemen", "イエメン", 
                {name: 'マヨット', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1fe-1f1f9.png", flag: '&#x1f1fe;&#x1f1f9;', tags: []}, // "Mayotte", "マヨット", 
                {name: '南アフリカ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1e6.png", flag: '&#x1f1ff;&#x1f1e6;', tags: ['黒白赤緑', '赤黄緑']}, // "South Africa", "南アフリカ", 
                {name: 'ザンビア', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1f2.png", flag: '&#x1f1ff;&#x1f1f2;', tags: ['赤黄緑']}, // "Zambia", "ザンビア", 
                {name: 'ジンバブエ', img_url: "https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1fc.png", flag: '&#x1f1ff;&#x1f1fc;', tags: ['黒白赤緑', '赤黄緑']}, // "Zimbabwe", "ジンバブエ", 
                {name: 'イングランド', img_url: "72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png", flag: '&#x1f1ff;&#x1f1fc', tags: ['']}, // "England", "イングランド", https://twemoji.maxcdn.com/2/
                {name: 'スコットランド', img_url: "72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png", flag: '&#x1f1ff;&#x1f1fc', tags: ['']}, // "Scotland", "スコットランド", https://twemoji.maxcdn.com/2/
                {name: 'ウェールズ', img_url: "72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png", flag: '&#x1f1ff;&#x1f1fc', tags: ['']}, // "Wales", "ウェールズ", https://twemoji.maxcdn.com/2/
            ]
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            this.filtered = this.tags.reduce((cat, tag) => cat.concat(this.filter.includes(tag.feature) ? tag.indices : []), []);
            this.correctIndex = Random.randomInteger(3);

            this.optionIndices = Random.sample(this.filtered.length, 3);
            // this.optionIndices = Random.sampleExcept(this.flags.length, 3, this.optionIndices);
            this.options = this.optionIndices.map(n => this.filtered[n]).map(n => {
                const f = this.flags[n];
                return {
                    name: f.name,
                    flag: f.flag,
                    img_url: f.img_url,
                    badgeClass: 'badge-secondary'
                }
            });
            
        },
        mark: function(index) {
            this.correct = index === this.correctIndex;
            this.options[index].badgeClass = this.correct ? 'btn-success' : 'btn-danger';
            this.total += 1;
        }
    }
}
</script>

<style scoped>
.national-flag {
  font-size: 10rem;
}
.badge {
    font-size: 24px;
}
</style>
