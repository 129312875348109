<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="display" v-on:click="renew">
                        <slot></slot>
                    </div>
                    <div v-if="status === 'correct'">
                        <img v-if="correctCount % config.specialRewardThreshold !== 0" class="judge" src="/images/maru.png" alt="">
                        <img v-else class="judge" v-bind:src="`/images/${specialRewardImage}.png`" alt="">
                    </div>
                    <img v-if="status === 'miss'" class="judge" src="/images/batsu.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col" v-if="config.extraLeftAction">
                    <slot name="extra-left-action"></slot>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-light float-right" v-on:click="renew">つぎへ</button>
                </div>
            </div>
            <RewardConsole v-bind:correctSymbol="config.correctSymbol"
                v-on:correct="handleCorrect" v-on:miss="handleMiss"
                v-bind:correctCount="correctCount" v-bind:missCount="missCount"
            />
        </div>
        <div class="local-nav">
            <!-- Button trigger modal -->
            <button v-if="config.helpModal" type="button" class="btn btn-outline-secondary btn-sm rounded-circle" data-toggle="modal" data-target="#helpModal">
            ?
            </button>

            <!-- Modal -->
            <div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">使い方</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div class="modal-body">
                            <slot name="help"></slot>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Button trigger modal -->
            <button v-if="config.configModal" type="button" class="btn btn-outline-secondary ml-2" data-toggle="modal" data-target="#configModal">
            設定
            </button>

            <!-- Modal -->
            <div class="modal fade" id="configModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">設定</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                        </div>
                        <div class="modal-body">
                            <slot name="config"></slot>
                        </div>
                        <div class="modal-footer">
                            <span v-if="config.configSaveFeature">
                                <span v-if="saved" class="badge badge-pill badge-success float-right1">保存済み</span>
                                <span v-else class="badge badge-pill badge-warning float-right">編集中</span>
                            </span>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="$emit('renew')">閉じる</button>
                            <span v-if="config.configSaveFeature">
                                <button type="button" class="btn btn-primary" v-on:click="$emit('save')">保存する</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SpecialRewardImage from "./SpecialRewardImage.js";
import RewardConsole from "./RewardConsole.vue";

export default {
    props: ['config', 'saved'],
    components: {
        RewardConsole,
    },
    data: function() {
        return {
            correctCount: 0,
            missCount: 0,
            status: null,
            specialRewardImage: null
        };
    },
    watch: {
        correctCount : function () {
            if (this.correctCount % this.config.specialRewardThreshold === 1) {
                this.specialRewardImage = SpecialRewardImage.random();
            }
        }
    },
    methods: {
        renew: function () {
            this.status = null;
            this.$emit('renew');
        },
        handleCorrect: function() {
            this.status = 'correct';
            this.correctCount++;
            this.$emit('correct');
        },
        handleMiss: function() {
            this.status = 'miss';
            this.missCount++;
            this.$emit('miss');
        },
    }
}
</script>

<style scoped>
.display {
    display: table-cell;
    height: 90vw;
    width: 90vw;
    vertical-align: middle;
}
.theme-dark {
    background-color: grey;
}
.theme-pop {
    background-color: pink;
}
.judge {
    position: absolute;
    top: 0;
    left: 0;
    width: 90vw;
    height: 90vw;
    z-index: -1;
}
.local-nav {
    position: absolute;
    bottom: 0;
    right: 0;
}
</style>