<template>
    <div>
        <h3>{{ title }}</h3>
        <ArithmeticEquation v-bind:n0="n0" v-bind:n1="n1" v-bind:operation="operation" v-bind:input="input" v-bind:expected="expected"/>
        <NumberPad v-bind:clear="clearNumPad" v-on:press="judge"/>
        <TryAgainButton v-bind:renew="renew"/>
        <QuizConfigurationModal>
            <div class="form-check" v-for="(cat, ci) in categories" v-bind:key="'cat' + ci">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" name="category" id="category" v-bind:value="cat.id" v-model="category">
                {{cat.description}}
              </label>
            </div>
        </QuizConfigurationModal>
    </div>
</template>

<script>
import Arithmetic from "./Arithmetic.vue"
import ArithmeticEquation from "./ArithmeticEquation.vue"
import NumberPad from "./NumberPad.vue"
import TryAgainButton from "./TryAgainButton.vue"
import QuizConfigurationModal from "./QuizConfigurationModal.vue"
import Random from "./Random.js"

const title = 'この たしざんの けっかは？';
export default {
    title: title,
    name: 'Addition',
    mixins: [Arithmetic],
    components: {
        ArithmeticEquation,
        NumberPad,
        TryAgainButton,
        QuizConfigurationModal,
    },
    data: function() {
        return {
            title: title,
            operation: '+',
            category: 'Random',
            categories: [
                {
                    description: '1を足すということは次の数', id: 'AddOne',
                    pickNumbers: function() {
                        this.pickNumbersAddN(1);
                    }
                },
                {
                    description: '偶数に2を足すと次の偶数', id: 'AddTwoToEven',
                    pickNumbers: function() {
                        this.pickNumbersAddEvenTo(2);
                    }
                },
                {
                    description: '奇数に2を足すと次の奇数', id: 'AddTwoToOdd',
                    pickNumbers: function() {
                        this.pickNumbersAddEOddTo(2);
                    }
                },
                {
                    description: 'X+Xは偶数になる', id: 'AddSameNumbers',
                    pickNumbers: function() {
                        this.n0 = Random.randomIntegerExcept(10, [this.n0 - 1]) + 1;
                        this.n1 = this.n0;
                    }
                },
                {
                    description: '足すと5になる組み合わせ、足すと6になる組み合わせ', id: 'FiveOrSix',
                    pickNumbers: function() {
                        this.pickNumbersMorN(5);
                    }
                },
                {
                    description: '足すと7になる組み合わせ、足すと8になる組み合わせ', id: 'SevenOrEight',
                    pickNumbers: function() {
                        this.pickNumbersMorN(7);
                    }
                },
                {
                    description: '足すと9になる組み合わせ、足すと10になる組み合わせ', id: 'NineOrTen',
                    pickNumbers: function() {
                        this.pickNumbersMorN(9);
                    }
                },
                {
                    description: 'X+YとY+Xは同じ', id: 'Reverse',
                    pickNumbers: function() {}
                },
                {
                    description: '9を足すと1桁目は前の数字になる', id: 'AddNine',
                    pickNumbers: function() {
                        this.pickNumbersAddN(9);
                    }
                },
                {
                    description: '偶数に8を足すと1桁目は一つ前の偶数になる', id: 'AddEightToEven',
                    pickNumbers: function() {
                        this.pickNumbersAddEvenTo(8);
                    }
                },
                {
                    description: '奇数に8を足すと1桁目は一つ前の奇数になる', id: 'AddEightToOdd',
                    pickNumbers: function() {
                        this.pickNumbersAddEOddTo(8);
                    }
                },
                {
                    description: 'ランダム', id: 'Random',
                    pickNumbers: function() {
                        this.n0 = Random.randomInteger(9) + 1;
                        this.n1 = Random.randomInteger(9) + 1;
                    }
                },
            ],
            pickNumbers: function() {
                this.n0 = Random.randomInteger(9) + 1;
                this.n1 = Random.randomInteger(9) + 1;
            },
        };
    },
    watch: {
        category: function() {
            const category = this.categories.find(c => c.id === this.category);
            this.pickNumbers = category.pickNumbers;
        }
    },
    computed: {
        expected: function() {
            return this.n0 + this.n1;
        }

    },
    methods: {
        pickNumbersMorN: function(m) {
            const sum = m +  Random.randomInteger(2);
            this.n0 = Random.randomInteger(sum - 1) + 1;
            this.n1 = sum - this.n0;
        },
        pickNumbersAddN: function(n) {
            if (Random.randomInteger(2) === 0) {
                this.n0 = n;
                this.n1 = Random.randomIntegerExcept(9, [1]) + 1;
            } else {
                this.n0 = Random.randomIntegerExcept(9, [1]) + 1;
                this.n1 = n;
            }
        },
        pickNumbersAddEvenTo(n) {
            if (Random.randomInteger(2) === 0) {
                this.n0 = n;
                this.n1 = (Random.randomIntegerExcept(5, [1]) + 1) * 2;
            } else {
                this.n0 = (Random.randomIntegerExcept(5, [1]) + 1) * 2;
                this.n1 = n;
            }
        },
        pickNumbersAddEOddTo(n) {
            if (Random.randomInteger(2) === 0) {
                this.n0 = n;
                this.n1 = Random.randomIntegerExcept(5, [1]) * 2 + 1;
            } else {
                this.n0 = Random.randomIntegerExcept(5, [1]) * 2 + 1;
                this.n1 = n;
            }
        },
        renew: function() {
            this.pickNumbers();
            this.background = '';
            this.clearNumPad += 1;
        }
    }
}
</script>
