<template>
    <div>
        <h6>{{title}}</h6>
        <span v-for="(part, pi) in parts" v-bind:key="part" class="text">
            <span v-if="pi === 1" class="border">
                <span
                    v-for="b in blank" v-bind:key="b.ch"
                     v-bind:style="{visibility: b.hit ? 'visible' : 'hidden'}"
                     v-bind:class="{'bg-success': b.hit}"
                >{{b.ch}}</span>
            </span>
            <span v-else>{{part}}</span>
        </span>
        <ul>
            
        </ul>
        <ShuffledSequenceOptions v-bind:elements="answerElements" v-on:hit="onHit($event)" />
        <TryAgainButton v-bind:renew="renew"/>
        <Achievement v-bind:rightness="correct" v-bind:total="total"/>
    </div>
</template>

<script>
import Random from "./Random.js";
import TryAgainButton from "./TryAgainButton.vue";
import Achievement from "./Achievement.vue";
import ShuffledSequenceOptions from "./ShuffledSequenceOptions.vue";

const title = 'くうらんを うめると？';
const name = 'CharactersInBlankShuffled';
export default {
    name: name,
    title: title,
    data: function() {
        return {
            title: title,
            correct: false,
            total: 0,
            sentence: "",
            currentSentenceIndex: -1,
            sentences: [
                "hyperscript-helpers offers a *terser* syntax",
                "We’ve *consolidated* the declarations and assignment into one location.",
                "Learning how to use this in JavaScript is something of a *rite* *of* *passage*.",
                "This architecture might seem like an *overkill* for a counter app",
            ],
            parts: [],
            answer: "",
            answerElements: [],
            blank: [],
            shuffled: [],
        };
    },
    components: {
        ShuffledSequenceOptions,
        TryAgainButton,
        Achievement
    },
    created: function() {
        this.renew();
    },
    methods: {
        renew: function() {
            this.currentSentenceIndex = Random.randomIntegerExcept(this.sentences.length, [this.currentSentenceIndex]);
            this.sentence = this.sentences[this.currentSentenceIndex];
            this.parts = this.sentence.split('*');
            this.answer = this.parts[1];
            this.answerElements = this.answer.split('');
            this.blank = this.answerElements.map(ch => {
                return {
                    ch: ch,
                    hit: false,
                };
            });
        },
        onHit: function(index) {
            this.blank[index].hit = true;
            if (index === this.answer.length - 1) {
                this.total = this.total + 1;
                this.correct = true;
            }
        }
    }
}
</script>

<style scoped>
.text {
    font-size: 2rem;
}
</style>