<template>
    <div>
        <RiverFrame v-bind:config="{correctSymbol: '🥝', configModal: true, helpModal: true, extraLeftAction: false, specialRewardThreshold: 20}" v-on:renew="renew" v-on:correct="reflectResult('correct')" v-on:miss="reflectResult('miss')">
            <template v-slot:config>
            <h5>計算の種類</h5>
                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="operation" id="operation-plus" value="+" v-model="configOperation['+']"> 足し算
                        <input class="form-check-input" type="checkbox" name="operation" id="operation-minus" value="-" v-model="configOperation['-']"> 引き算
                        <input class="form-check-input" type="checkbox" name="operation" id="operation-multiply" value="×" v-model="configOperation['×']"> かけ算
                    </label>
                </div>
                <form>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label for="n0min">左の数の下限</label>
                                <input type="number" min="1" max="99" step="1"
                                    class="form-control" name="n0min" id="n0min" aria-describedby="n0min-help" placeholder="1~99" v-model="n0min">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="n0max">左の数の上限</label>
                                <input type="number" min="1" max="99" step="1"
                                    class="form-control" name="n0max" id="n0max" aria-describedby="n0max-help" placeholder="1~99" v-model="n0max">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                            <label for="n1min">右の数の下限</label>
                            <input type="number" min="1" max="99" step="1"
                                class="form-control" name="n1min" id="n1min" aria-describedby="n1min-help" placeholder="1~99" v-model="n1min">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                            <label for="n1max">右の数の上限</label>
                            <input type="number" min="1" max="99" step="1"
                                class="form-control" name="n1max" id="n1max" aria-describedby="n1max-help" placeholder="1~99" v-model="n1max">
                            </div>
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:help>
                <p>
                    足し算と引き算の問題がランダムに表示されます。
                    <ul>
                        <li>子供に計算の答えを言ってもらいます</li>
                        <li>
                            当たっていたら「正解」ボタンを押します
                            <ul>
                                <li>正解数がカウントアップされます</li>
                            </ul>
                        </li>
                        <li>
                            間違っていたら「ミス」ボタンを押します
                        <ul>
                            <li>ミス数がカウントアップされます</li>
                        </ul>
                        </li>
                        <li>「つぎへ」ボタンを押すと、例文がランダムに更新されます</li>
                    </ul>
                </p>
                <p>
                    設定で、演算子の種類、数字の範囲を設定できます。
                </p>
                <p>
                    引き算の場合、答えがマイナスになる数字の組み合わせは出てきません。
                </p>
                <h5>Tips</h5>
                <p>
                    回答履歴は「全て」「正解のみ」「ミスのみ」を選ぶとその条件に当てはまる結果を表示するようになります。
                </p>
            </template>
            <div class="equation mx-n1">
                {{n0}}{{operation}}{{n1}} = <span v-show="status === 'correct'">{{expected}}</span>
            </div>
        </RiverFrame>
        <hr/>
        回答履歴
        <span v-bind:class="['badge', 'badge-pill', historyFilter == '' ? 'badge-primary' : 'badge-secondary']" v-on:click="historyFilter = ''">全て</span>
        <span v-bind:class="['badge', 'badge-pill', historyFilter == 'correct' ? 'badge-primary' : 'badge-secondary']" v-on:click="historyFilter = 'correct'">正解のみ</span>
        <span v-bind:class="['badge', 'badge-pill', historyFilter == 'miss' ? 'badge-primary' : 'badge-secondary']" v-on:click="historyFilter = 'miss'">ミスのみ</span>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>問題</th>
                    <th>判定</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="h in historyFiltered" v-bind:key="h.number">
                    <td scope="row">{{h.number}}</td>
                    <td>{{h.n0}}{{h.operation}}{{h.n1}}</td>
                    <td><span>{{h.status === 'correct' ? '🥝' : '😥'}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Random from "./Random.js";
import RiverFrame from "./RiverFrame.vue";

export default {
    components: {
        RiverFrame,
    },
    data: function() {
        return {
            number: 0,
            n0: 0,
            n0min: "1",
            n0max: "9",
            n1: 0,
            n1min: "1",
            n1max: "9",
            configOperation: {
                '+': true,
                '-': true,
                '×': true
            },
            operation: '+',
            status: null,
            history: [],
            historyFilter: '',
            correct: 0,
            specialRewardImage: "",
        };
    },
    computed: {
        operationCandiates: function() {
            const candidates = [];
            for (const operation of Object.keys(this.configOperation)) {
                if (this.configOperation[operation]) {
                    candidates.push(operation);
                }
            }
            return candidates;
        },
        expected: function() {
            if (this.operation === '+') {
                return this.n0 + this.n1;
            } else if (this.operation === '-') {
                return this.n0 - this.n1;
            } else {
                return this.n0 * this.n1;
            }
        },
        historyFiltered: function() {
            if (this.historyFilter === '') {
                return this.history;
            } else {
                return this.history.filter(h => h.status === this.historyFilter);
            }
        }
    },
    created: function() {
        this.renew();
    },
    methods: {
        randomIntegerBetween: function (minStr, maxStr) {
            const max = parseInt(maxStr);
            const min = parseInt(minStr);
            const length = max - min + 1;
            return Random.randomInteger(length) + min;
        },
        renew: function() {
            this.number += 1;
            this.status = null;
            this.operation = Random.shuffle(this.operationCandiates)[0];
            this.n0 = this.randomIntegerBetween(this.n0min, this.n0max);
            let n1max = parseInt(this.n1max);
            if (this.operation === '-') {
                n1max = n1max > this.n0 ? this.n0 : n1max;
            }
            this.n1 = this.randomIntegerBetween(n1max, this.n1min);
        },
        reflectResult: function(result) {
            this.status = result;
            if (result === 'correct') {
                this.correct++;
            }
            this.history.push({number: this.number, n0: this.n0,n1: this.n1, operation: this.operation, status: this.status});
        },
    }
}
</script>

<style scoped>
.equation {
    font-size: 5rem;
}
</style>
