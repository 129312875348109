<template>
    <Sequence v-bind:sequences="sequences" />
</template>

<script>
import Sequence from "./Sequence.vue"

export default {
    name: 'ScientificSequence',
    title: "これを じゅんばんに ならべると？",
    components: {
        Sequence
    },
    data: function() {
        return {
            sequences: [
                {
                    subject: '太陽系の星',
                    elements: ['水星', '金星', '地球', '火星', '木星', '土星', '天王星', '海王星', '冥王星']
                },
                {
                    subject: '人間の消化器官',
                    elements: ['口', '食道', '胃', '十二指腸', '小腸', '大腸', '肛門']
                },
                {
                    subject: '',
                    elements: ['鉄鉱紀', '熔岩紀', '造山紀', '剛塊紀', '覆層紀', '伸展紀', '狭帯紀', '拡層紀', '氷成紀', 'エディアカラ紀', 'カンブリア紀', 'オルドビス紀', 'シルル紀', 'デボン紀', 'ミシシッピ亜紀', '石炭紀', 'ペルム紀', '三畳紀', 'ジュラ紀', '白亜紀', '旧成紀（古第三紀）', '新成紀（新第三紀）', '第四紀']
                },
            ],
        }
    }
}
</script>