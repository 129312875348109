import Vue from 'vue'
import OneToOne from "./OneToOne.vue"

function createLocalComponent(title, materialId, name, category) {
    const template = `<OneToOne v-bind:materialId="'${materialId}'"/>`;
    const compiledTemplate = Vue.compile(template);

    return {
        title,
        props: [title],
        name,
        category,
        components: {
            OneToOne
        },
        render: function(createElement) {
            return compiledTemplate.render.call(this, createElement);
        }
    };       
}
const componentData = [
    {
        name: 'CapitalCity',
        title: "この くにの しゅとは どこかな？",
        materialId: 'f072zcAzTRCN7Kxn9sN2',
        category: 'world',
    },
    {
        name: 'CapitalCityOfStatesInAmerica',
        title: "この アメリカの しゅうの しゅうとは？",
        materialId: '4y8irn2twgKGj6rN8kQB',
        category: 'world',
    },
    {
        name: 'Element',
        title: "この げんそきごうの げんそは？",
        materialId: 'zr94TkB86yItysURCMaW',
        category: 'science',
    },
    {
        name: 'FlameReaction',
        title: "もやすと ほのおは なにいろ?",
        materialId: 'KTw2OvK1lVQxztcO0Cr7',
        category: 'science',
    },
    {
        name: 'GreekCharacterReading',
        title: "この ギリシャもじの よみかたは？",
        materialId: 'jQAS1pu2PnObK0CsiAl3',
        category: 'charactor',
    },
    {
        name: 'GreekCharacterSymbol',
        title: "この よみかたの ギリシャもじは？",
        materialId: 'tO0HwvvALWrqyKilxCsq',
        category: 'charactor',
    },
    {
        name: 'HepburnWriting',
        title: "この ひらがなを ローマじで かくと？",
        materialId: 'v8fgiwkCNLX8iTuCeIOq',
        category: 'charactor',
    },
    {
        name: 'HiraganaToKatakana',
        title: "この ひらがなと おなじ よみかたの カタカナは？",
        materialId: '5ryF3J4aDtXsvtxjAuSi',
        category: 'charactor',
    },
    {
        name: 'KatakanaToHiragana',
        title: "この カタカナと おなじ よみかたの ひらがなは？",
        materialId: 'sCiB5jjyd56Mdo15n7N3',
        category: 'charactor',
    },
    {
        name: 'RomanCharacterLower',
        title: "この ローマじの こもじは？",
        materialId: 'ACZM4xavKzvu7bCZvSl5',
        category: 'charactor',
    },
    {
        name: 'RomanCharacterUpper',
        title: "この ローマじの おおもじは？",
        materialId: 'bxaAO5eQS0wDO84XZB0H',
        category: 'charactor',
    },
    {
        name: 'SymbolOfElement',
        title: "この げんその げんそきごうは？",
        materialId: 'gPcaGPNQjSjQrGFUJP34',
        category: 'science',
    },
    {
        name: 'TimeAdverbalPhrase',
        title: "この えいごは いつのこと？",
        materialId: 'sHMJc8isxDZHYFhZGOGT',
        category: 'english',
    },
    {
        name: 'BooksOfTheWorld',
        title: "Who is the auther of this book?",
        materialId: 'dRCbxx3VT2Ddfde3v7YC',
        category: 'world',
    },
    {
        name: 'MonthInJapaneseLunarCalendar',
        title: "この月の旧暦の呼び方は？",
        materialId: 'ko1Mz7Rz7ytNMGWy9PR1',
        category: 'japanese',
    },
    
    {
        name: 'HyakuninIsshu',
        title: "この ひゃくにんいっしゅの しものくは？",
        materialId: '823llyfb2oXqAJOxurXh',
        category: 'japanese',
    },
]

const components = {};

for (const cd of componentData) {
    components[cd.name] = createLocalComponent(cd.title, cd.materialId, cd.name, cd.category);
    
}

export default components;